import { Circle, FiberManualRecordOutlined, FiberManualRecordRounded, FiberManualRecordTwoTone, Home, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, ButtonBase, Collapse, Icon, styled, Typography } from "@mui/material"
import { Fragment, memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation, useRoutes } from "react-router-dom"
import defineRoutes from "../../../routes"

const ParentNavItem = styled(Box)(({ theme, active }) => ({
    width: "100%",
    display: 'flex',
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    position: "relative",
    "::before": {
        content: '" "',
        position: "absolute",
        right: "0px",
        top: "0px",
        height: "100%",
        width: theme.spacing(1),
        background: active ? "#296D8F" : 'transparent',
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
    }

}))

const ParentNavInner = styled(Box)(({ theme, active, nopadding }) => ({
    width: "100%",
    display: 'flex',
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: nopadding ? 0 : theme.spacing(3),
    paddingRight: nopadding ? 0 : theme.spacing(3),
    paddingTop: nopadding ? 0 : theme.spacing(2),
    paddingBottom: nopadding ? 0 : theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    background: active ? "#296D8F" : "transparent",
    color: active ? "#fff" : theme.palette.text.secondary,
    cursor: "pointer",
    // transition: "all 0.5s linear",
    ":hover": {
        background: active ? "#296D8F" : "transparent",
        color: active ? "#fff" : theme.palette.text.secondary,
    }
}))

const CollpasableContainer = styled(Collapse)(({ theme, active }) => ({
    width: "100%",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
}))

const NavItemContainer = styled(ButtonBase)(({ theme, active, nomargin, main }) => ({
    width: "100%",
    display: 'flex',

    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: nomargin ? 0 : theme.spacing(2),
    "*": {
        // background: active ? "rgba(245, 148, 35, 0.7)" : "transparent",
        // color: active ? "#fff" : theme.palette.text.secondary,
    },

}))

const NavItem = memo(({ active, title, path, Icon, nomargin, main }) => {

    return (
        <NavItemContainer active={active} to={path} LinkComponent={Link} nomargin={nomargin} main={main}>
            {Icon && <Icon color="#11723E" />}

            {!Icon && (active ? <FiberManualRecordRounded fontSize="inherit" /> : <FiberManualRecordOutlined fontSize="inherit" />)}
            <Typography sx={(theme) => ({ color: active ? "#fff" : theme.palette.text.secondary })} ml={main ? 2 : 3} fontWeight={'400'} lineHeight={'100%'} variant={main ? "body1" : "body2"} >{title}</Typography>
        </NavItemContainer>
    )
})

const ChildrenNavItem = memo(({ active, title, path, Icon, nomargin, main, value }) => {

    return (
        <NavItemContainer active={active} to={path} LinkComponent={Link} nomargin={nomargin} main={main}>
            {Icon && <Icon color="dark.main" />}

            {!Icon && (active ? <FiberManualRecordRounded fontSize="inherit" sx={{color:"#296D8F"}} /> : <FiberManualRecordOutlined color="#296D8F" fontSize="inherit" />)}
            <Typography sx={(theme) => ({ color: active ? "#296D8F" : theme.palette.text.secondary })} ml={main ? 2 : 3} fontWeight={'400'} lineHeight={'100%'} variant={main ? "body1" : "body2"} >{title}</Typography>
        </NavItemContainer>
    )
})
const NavDropDown = memo(({ activeParent, title, Icon, path, children }) => {

    const [open, setOpen] = useState(activeParent)

    const location = useLocation()
    const splittedPath = location.pathname.split('/')

    useEffect(() => {
        setOpen(activeParent)
    }, [activeParent])

    if (children && children.length > 0)
        return (

            <>

                <ParentNavItem LinkComponent={"button"} active={open} onClick={() => { setOpen(!open) }}>
                    <ParentNavInner active={open}>
                        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }} mt={1} mb={1} >
                            {Icon ?? <Circle sx={{ fontSize: "inherit" }} />}
                            <Typography ml={2} variant="body1" fontWeight="400" >{title}</Typography>
                        </Box>
                        {
                            !open ? <KeyboardArrowDown /> : <KeyboardArrowUp />
                        }
                    </ParentNavInner>
                </ParentNavItem>
                <CollpasableContainer in={open} component={Box}>

                    {
                        children && Array.isArray(children) && children.map((route) => {
                            if (route.hideInPannel)
                                return <Fragment key={route.title} />
                            // console.log(route)
                            return <ChildrenNavItem value={1} Icon={undefined} key={route.title} active={((splittedPath[2] == route.path && splittedPath[1] == path) || (splittedPath[1] == '' && splittedPath[1] == path))} title={route.title} path={`/${path}/${route.path}`} />
                        })
                    }


                </CollpasableContainer>

            </>
        )

    else
        return (
            <ParentNavItem LinkComponent={"button"} active={open} onClick={() => { setOpen(!open) }}>
                <ParentNavInner active={open} nopadding>
                    <NavItem main={true} nomargin Icon={() => Icon} key={title} title={title} path={"/" + path} active={open} />
                </ParentNavInner>
            </ParentNavItem>
        )
})

const Navbar = () => {
    const { user } = useSelector((state) => state)
    const routes = defineRoutes(user)
    const location = useLocation()

    const splittedPath = location.pathname.split('/')



    return (
        <>

            {
                routes && Array.isArray(routes) && routes.map((route) => {
                    // const path = 
                    if (route.hideInPannel)
                        return <Fragment key={route.title} />
                    return <NavDropDown key={route.title} activeParent={splittedPath && splittedPath[1] == route.path} Icon={route.icon} children={route.children} title={route.title} path={route.path} />
                })
            }
        </>
    )
}
export default Navbar