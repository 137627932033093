import { Outlet } from "react-router";
import EmployeeTaskButtons from "./EmployeeTaskButtons";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { createContext, useCallback, useState } from "react";
import UserSearchBar, {
  StyledSearchBar,
} from "../../components/inputs/SearchBar";
import { BasicProfileDetailsComponent } from "../profile/BasicProfileDetailsComponent";
import PerformanceButtons from "./Performance/PerformanceButtons";
import GeneralTaskController from "./TaskTimeline/GeneralTaskController";
import { selectEmployee } from "../../store/actions/selectedEmployeeAction";
import { useDispatch, useSelector } from "react-redux";
import EmployeeTaskReviewButtons from "./EmployeeTaskReviewButtons";

export const EmployeeTaskUserContext = createContext();

const EmployeeMain = ({ forPerformance }) => {
  const dispatch = useDispatch();
  const { selectedEmployee } = useSelector((state) => state);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const onUserChange = useCallback(
    (user) => {
      dispatch(selectEmployee(user));
    },
    [dispatch]
  );

  return (
    <>
      <Box mt={3}>
        <UserSearchBar
          defaultVal={selectedEmployee?.user}
          onUserChange={onUserChange}
        />
      </Box>

      {selectedEmployee?.user ? (
        <EmployeeTaskUserContext.Provider value={selectedEmployee.user}>
          <BasicProfileDetailsComponent data={selectedEmployee.user} />
          {!forPerformance && (
            <EmployeeTaskReviewButtons userId={selectedEmployee.user.id} />
          )}
          {forPerformance && <PerformanceButtons />}
          <Paper
            component={Box}
            mb={4}
            p={isMobile ? 0 : 3}
            sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}
          >
            <Outlet />
          </Paper>
        </EmployeeTaskUserContext.Provider>
      ) : (
        <>
          <EmployeeTaskReviewButtons userId={null} />
          <Paper
            component={Box}
            mb={4}
          
            p={isMobile ? 0 : 3}
            sx={(theme) => ({ border: "1px solid " + theme.palette.grey[500] })}
          >
            <Outlet />
          </Paper>
        </>
      )}
    </>
  );
};

export default EmployeeMain;
