import { BarChart, CalendarMonth, CalendarViewDay, CampaignOutlined, EventAvailable, EventAvailableOutlined, History, HourglassEmpty } from '@mui/icons-material'
import { Box, Button, ButtonBase, styled } from '@mui/material'

import { Link, useLocation } from 'react-router-dom'

import {
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Slide,
    Typography,
} from '@mui/material'

const TaskButton = styled(ButtonBase)(({ theme, active, isFirst }) => ({
    display: 'flex',
    flex: 1,
    justifyContent: "space-between",
    zIndex: 0,
    marginLeft: isFirst ? 0 : theme.spacing(3),
    textTransform: 'capitalize',
    background: active ? "linear-gradient(93.41deg, #666666 -117.39%, #000000 136.1%)" : theme.palette.light.main,
    color: !active ? theme.palette.primary.main : theme.palette.light.main,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),


    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
    border: '1px solid ' + theme.palette.grey[500],
    "*": {
        lineHeight: "150%",
    }

}))

const LeaveBtnBox = {
    width: '100%',

    display: 'flex',



}

const PerformanceButtons = ({ userId }) => {

    const location = useLocation()
    const pathArr = location.pathname.split('/')
    const slug = pathArr[pathArr.length - 1]
    return (
        <>
            <Box sx={LeaveBtnBox} mt={5} mb={5}>



                <TaskButton
                    isFirst={true}
                    active={!slug ||slug == 'stats' || slug == ''}
                    color="light"
                    variant="contained"
                    sx={{ borderRadius: '4px' }}
                    LinkComponent={Link}
                    to="stats"
                >
                    <Typography variant='h4'>Performance Stats </Typography>  &nbsp;<BarChart />
                </TaskButton>
                <TaskButton
                    active={slug == 'calendar'}
                    color="light"
                    variant="contained"
                    sx={{ borderRadius: '4px' }}
                    LinkComponent={Link}
                    to="calendar"
                >
                    <Typography variant='h4'>Performance Calendar</Typography> &nbsp;<CalendarMonth />
                </TaskButton>

            </Box>
        </>
    )
}
export default PerformanceButtons
