import axios from "axios"
import { getHeaders } from "../utils/helper"
import endpoints from "./endpoints"


export const getBirthdaysApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.birthdayFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));
  
    return callResponse;
  };
  