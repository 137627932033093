import { useEffect,  useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { getAdvancePaymentsByIdApi } from "../../apis/reimbersement.api"
import { callApiAction } from "../../store/actions/commonAction"
import AdvancePaymentDetailsPageUi from "./AdvancePaymentDetailsPageUi"


//id props will be passed when it will be opened inside modal in user View module

const AdvancePaymentDetailsController = ({ payment_id }) => {


    const [id, setId] = useState(payment_id)

    const dispatch = useDispatch()
    const params = useParams()
    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const onUpdateValue = () => {

    }

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getAdvancePaymentsByIdApi({ id }),
            (response) => {
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }








    useEffect(() => {
        if (id) {
            fetchList()
        } else {
            setId(params.id)
        }
    }, [params.id])

    useEffect(() => {
        if (id && id == params.id) {
            fetchList()
        }
    }, [id])



    return <AdvancePaymentDetailsPageUi setState={setState} loading={loading} state={state} isFromModal={false} onUpdateValue={onUpdateValue} />
}
export default AdvancePaymentDetailsController