import { Delete, Edit } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useState } from "react"

import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import UpdateDepartmentController from "./UpdateDepartmentController"



const UpdateDepartmentButton = ({ params, setParams }) => {
    const dispatch = useDispatch()



    const onClick = () => {
        dispatch(openModal({
            title: "Update Department",
            component: <UpdateDepartmentController
                callBack={(res) => { setParams({ ...params, ...res }) }}
                id={params._id}
                initialName={params.name}
                parents={params.parents}

            />
        }))
    }

    return <IconButton onClick={onClick}>
        <Edit color="info" />
    </IconButton>
}
export default UpdateDepartmentButton