import { useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import ResetTokenUi from "./ResetPasswordUi"
import { useDispatch, useSelector } from 'react-redux'

import { closeModal, openModal } from "../../store/actions/modalAction"

import { useNavigate } from "react-router-dom"

import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { callApiAction } from "../../store/actions/commonAction"
import { resetPasswordApi } from "../../apis/auth.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { useSnackbar } from "notistack"

const ResetPasswordController = () => {

    

    const dispatch = useDispatch()
    const validate = useValidate()

    const [loading, setLoading] = useState(false)



    const [state, setState] = useState({
        current_pass: "",
        err: "",
        new_pass: ""
    })

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: state.new_pass,
            field: 'New Password',
            
        },
        {
            required: true,
            value: state.current_pass,
            field: 'Old Password',
        }
    ]), [state])

    const navigate = useNavigate()
    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await resetPasswordApi(state),
                    async (response) => {

                        setLoading(false)
                        dispatch(callSnackBar("Password Reseted successfully",SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal())

                    },
                    (err) => {
                        dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                        setState({ ...state, err })
                        setLoading(false)

                    }
                )
            )



        } else {
            setState({ ...state, 'err': validationResponse })
            dispatch(callSnackBar(validationResponse,SNACK_BAR_VARIETNS.error))

        }
    }



    return <ResetTokenUi onClose={()=>dispatch(closeModal())} state={state} setState={setState} onSubmit={onSubmit} loading={loading} />
}
export default ResetPasswordController