
import { Avatar, Box, Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from "@mui/material"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "../../../components/button/SubmitButton"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import { LEAVE_RESPONSE, USER_ROLES } from "../../../utils/constants"
import { leaveStatusColored } from "../../../utils/helper"
import { findObjectKeyByValue } from "../../../utils/helper"
import { LEAVE_STATUS } from "../../../utils/leave.constants"
import { HALF_TYPE_OBJ } from "../../../utils/constants"
import { CancelRounded, CheckCircle } from "@mui/icons-material"
import CustomInput from "../../../components/inputs/CustomInput"

const LeaveActionComponent = ({ state,
    setState,
    fieldLoading,
    onApprove,
    onReject,
    loading,
    isFromModal }) => {
    const [isBtnClicked, setButtonClicked] = useState('')
    const buttonClick = (title) => {
        setButtonClicked(title)
    }
    const handleClose = () => {
        setButtonClicked('')
    }
    const handleSubmit = () => {
        // if (isBtnClicked == 'approve') {
        //     onApprove()
        // }
        // else 


        onReject()
    }
    return (

        <>
            <Dialog
                open={isBtnClicked != ''}
                TransitionComponent={Fade}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add remarks for " + (isBtnClicked == "approve" ? "approval" : "rejection")}</DialogTitle>
                <DialogContent>
                    <CustomInput
                        multiline
                        rows={4}
                        disabled={fieldLoading.loading}
                        value={state.action_remarks}
                        onChange={(e) => setState({ ...state, action_remarks: e.target.value })}
                        type="text"
                        placeholder="Write remarks..."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' onClick={handleSubmit}>{isBtnClicked == "approve" ? "Approve Comp Off" : "Reject Comp Off"}</Button>
                </DialogActions>
            </Dialog>
            {!fieldLoading.loading && <Tooltip title="Approve">
                <IconButton onClick={() => {
                    onApprove()
                }}>
                    <CheckCircle color="success" />
                </IconButton>

            </Tooltip>}
            {!fieldLoading.loading && <Tooltip title="Reject">
                <IconButton onClick={() => {
                    buttonClick('reject')
                }}>
                    <CancelRounded color="error" />
                </IconButton>
            </Tooltip>}
            {
                fieldLoading.loading && <CircularProgress size={30} />
            }
        </>
    )
}
const CoffDetailsUi = ({ state, setState, fieldLoading, onApprove, onReject, loading, isFromModal, onDelete }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()




    return <>
        {!loading && state &&
            <Box mt={isFromModal ? 0 : 3} mb={3}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", padding: 3 }}>
                    {!isFromModal && <Typography variant="h2">Coff Application</Typography>}
                    <Box mt={3} >
                        <Grid container spacing={2} rowSpacing={3}>

                            <Grid xs={6} item>
                                <Typography variant="h5">
                                    Applied By :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        state.applied_by && (state.applied_by.first_name + " " + state.applied_by.last_name)
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h5">
                                    Application   Date :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        moment(state.createdAt ?? state.date).format("DD-MM-YYYY")
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h5">
                                    Type
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    Comp-Off Raise
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h5">
                                    Status
                                </Typography>
                                {/* <Typography variant="h6" color="grey.main">
                                    {
                                        <Chip color={leaveStatusColored(state.status)} label={findObjectKeyByValue(state.status,LEAVE_STATUS)} />

                                    }
                                </Typography> */}

                                {state.status == LEAVE_STATUS.PENDING && user.data._id != state.applied_by._id ? (
                                    <>
                                        {/* {!fieldLoading.loading && <Tooltip title="Approve">
                                            <IconButton onClick={onApprove}>
                                                <CheckCircle color="success" />
                                            </IconButton>

                                        </Tooltip>}
                                        {!fieldLoading.loading && <Tooltip title="Reject">
                                            <IconButton onClick={onReject}>
                                                <CancelRounded color="error" />
                                            </IconButton>
                                        </Tooltip>}
                                        {
                                            fieldLoading.loading && <CircularProgress size={30} />
                                        } */}

                                        <LeaveActionComponent
                                            state={state}
                                            setState={setState}
                                            fieldLoading={fieldLoading}
                                            onApprove={onApprove}
                                            onReject={onReject}
                                            loading={loading}
                                            isFromModal={isFromModal}

                                        />
                                    </>
                                ) : (<Chip
                                    size='small'
                                    color={leaveStatusColored(state.status)}
                                    label={findObjectKeyByValue(state.status, LEAVE_STATUS)}
                                />)}
                            </Grid>


                            <Grid xs={12} item>
                                <Typography variant="h5">
                                    Reason
                                </Typography>
                                <Typography variant="h6" color="grey.main">

                                    {state.remarks}


                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h5">
                                    Date
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        moment(state.date).format("DD-MM-YYYY")

                                    }
                                    &nbsp;({findObjectKeyByValue(state.time_duration, HALF_TYPE_OBJ)})
                                </Typography>
                            </Grid>
                            {state.status != LEAVE_STATUS.PENDING && <>
                                <Grid xs={6} item>
                                    <Typography variant="h5">
                                        Actioned By :
                                    </Typography>
                                    <Typography variant="h6" color="grey.main">
                                        {
                                            state.actioned_by && (state.actioned_by.first_name + " " + state.actioned_by.last_name)
                                        }
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <Typography variant="h5">
                                        Actioned Remarks :
                                    </Typography>
                                    <Typography variant="h6" color="grey.main">
                                        {
                                            state.actioned_remarks
                                        }
                                    </Typography>
                                </Grid>

                            </>}






                        </Grid>

                        {state.status == LEAVE_RESPONSE.pending && user.data._id != state.userId._id && <Grid container mt={4} columnSpacing={4}>
                            <Grid item xs={6}>
                                <SubmitButton loading={fieldLoading.fieldName == "reject"} onClick={onReject} disabled={fieldLoading.loading} title={"Reject"} color="error" />
                            </Grid>
                            <Grid item xs={6}>
                                <SubmitButton loading={fieldLoading.fieldName == "accept"} onClick={onApprove} disabled={fieldLoading.loading} title={"Approve"} color="success" />
                            </Grid>
                        </Grid>}

                        {(state.status == LEAVE_STATUS.PENDING || user.data._id != state.applied_by._id) && (moment().valueOf() < moment(state.to).add('1', 'day').valueOf() || user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.hr) && <SubmitButton
                            sx={{ mt: 3, mb: 0 }}
                            loading={fieldLoading.fieldName == 'delete'}
                            onClick={onDelete}
                            disabled={fieldLoading.loading}
                            title={'Delete Comp Off'}
                            color="error"
                        />}

                    </Box>
                </Box>
            </Box>
        }
        {
            !state && !loading && <CenteredBox sx={{ minHeight: "300px", flexDirection: "column" }}>

                <Typography variant="h3">No Data Found</Typography>
                <Typography variant="h6">Data may be deleted from the server</Typography>
            </CenteredBox>
        }
        {
            loading && <CenteredBox sx={{ minHeight: "300px" }}><CircularProgress /></CenteredBox>
        }

    </>
}
export default CoffDetailsUi