import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdApi, updateUserField } from "../../../apis/user.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { findObjectKeyByValue, loggedInUser } from "../../../utils/helper";
import LeaveEncashmentUi from "./LeaveEncashmentUI";
import { getLeaveEncashApi, getLeavesApi } from "../../../apis/leave.api";
import moment from "moment";
import { ENCASH_STATUS, actions } from "../../../utils/constants";
import { LEAVE_TYPE } from "../../../utils/leave.constants";
import { Chip } from "@mui/material";

const LeaveEncashmentController = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    sort: "from",
    sortDirection:-1,
    searchable: ["applied_by.first_name"],
    enabled:null,
    date:moment()
  });


  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          params.applied_by.first_name + " " + params.applied_by.last_name,
      },
    
      {
        id: 2,
        fieldName: "from",
        label: "Applied Date",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => moment(params.from).format("DD/MM/YYYY"),
      },

      {
        id: 4,
        fieldName: "leave_count",
        label: "Total Leave Count",
        align: "left",
        // sort: true,
        minWidth: "150px",
      },
      {
        id: 3,
        fieldName: "type",
        label: "Leave Type",
        align: "left",
        minWidth: "150px",
        renderValue: (params) => findObjectKeyByValue(params.type, LEAVE_TYPE),
      },
      {
        id: 5,
        fieldName: "salary",
        label: "Amount",
        align: "left",
        // sort: true,
        minWidth: "150px",
        renderValue: (params) => "₹ " + params?.salary?.toFixed(2) ?? 0,
      },
      {
        id: 6,
        fieldName: "encashment_status",
        label: "Status",
        align: "left",
        // sort: true,
        minWidth: "150px",
        renderValue: (params) => (
          <Chip
            label={findObjectKeyByValue(
              params.encashment_status,
              ENCASH_STATUS
            )}
            color="success"
            size="small"
          />
        ),
      },
    ],
    [dispatch]
  );

  const fetchLeaveApi = getLeaveEncashApi;

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchLeaveApi({...fields,date:fields.date.valueOf()  }),
        (response) => {
          setState(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

 
  useEffect(() => {
    fetchList();
  }, [fields]);

  return (
    <LeaveEncashmentUi
      setFields={setFields}
      fields={fields}
      setState={setState}
      loading={loading}
      state={state}
      columns={columns}
    />
  );
};
export default LeaveEncashmentController;
