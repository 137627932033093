import React from "react";
import {
  Cancel,
  CheckBox,
  CheckCircle,
  Visibility,
  Add,
} from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";
import { openModal } from "../../store/actions/modalAction";

import { useCallback } from "react";
import UploadSheetController from "./UploadSheetController";

import From16ListController from "./Form16ListController";

const GenerateForm16Ui = ({callBack}) => {
  const [date, setDate] = useState(moment());
  const dispatch = useDispatch();
  const uploadSheet = useCallback(() => {
    dispatch(
      openModal({
        title: "Upload Form16",
        component: <UploadSheetController callBack={callBack}/>,
        size: "sm",
      })
    );
  }, []);

  return (
    <>
      <Box m={3}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "#0D0D0D",
          }}
        >
          Upload Form-16
        </Typography>
        <Box mt={3} mb={3}>
          <CustomMonthPicker date={date} setDate={setDate} />
        </Box>

        <Button
          onClick={uploadSheet}
          sx={{
            display: "flex",
            height: "100%",
            zIndex: 0,
            textTransform: "capitalize",
            boxShadow: "none",
            border: "1px solid #393939",
            width: "100%",
            height: "56px",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          <Add color="primary" /> &nbsp;Upload PDF
        </Button>
      </Box>

      <From16ListController date={date.toISOString()} />
    </>
  );
};

export default GenerateForm16Ui;
