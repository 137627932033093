import { Delete, Edit } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"

import UpdateEmployeeTypeController from "./UpdateEmployeeTypeController"



const UpdateEmployeeTypeButton = ({ params, setParams }) => {
    const dispatch = useDispatch()



    const onClick = () => {
        dispatch(openModal({
            title: "Update Employee Type",
            component: <UpdateEmployeeTypeController
                callBack={(res) => { setParams({ ...params, ...res }) }}
                id={params._id}
                initialName={params.name}            

            />
        }))
    }

    return <IconButton onClick={onClick}>
        <Edit color="info" />
    </IconButton>
}
export default UpdateEmployeeTypeButton