import { CalendarMonth } from "@mui/icons-material";
import { List, ListSubheader, Paper, Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { CenteredBox } from "../../components/layouts/common/boxes";

const DashBox = ({ showTodayDate, showDate, title, ...props }) => {
  const theme = useTheme()
  return (
    <Paper
      elevation={0}
      component={Box}
      height={"100%"}
      sx={(theme) => ({ border: 1, borderColor: "divider", overflow: "hidden" })}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            marginTop={4}
            sx={{ borderBottom: "1.5px solid lightgrey", display: "flex", justifyContent: "space-between", alignItems: "center" }}

          >
            <Typography variant="h4" sx={{ color: "text.primary" }}>
              {" "}
              {title}
            </Typography>
            {showDate && <Paper component={CenteredBox} p={1} sx={{ backgroundColor: theme.palette.grey.lightgray }} pl={2} pr={2} variant="outlined" >
              <Typography variant="body1" lineHeight="100%" sx={{ color: "text.primary", alignSelf: "flex-end" }}>
                <CalendarMonth fontSize="inherit" lineHeight="100%" />
              </Typography>
            
              <Typography variant="body2" lineHeight="120%" sx={{ color: "text.primary", alignSelf: "flex-end" }}>
                {/* <CalendarMonth fontSize="inherit" lineHeight="100%" /> &nbsp; */}
                &nbsp; {showTodayDate ? moment().format("DD MMM YYYY") : moment().format("MMM YYYY")}
                {" "}
              </Typography>
            </Paper>}
          </ListSubheader>
        }
      >


      </List>
      <Box sx={{}} >
        {props.children}
      </Box>
    </Paper>
  );
};
export default DashBox;
