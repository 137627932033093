import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'


export const createPms = async (data) => {
  
  const callResponse = await axios({
    url: endpoints.pmsCreate,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getAllPms = async (params) => {
  const callResponse = await axios({
    url: endpoints.pmsFetchAll,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
export const getPmsById = async (params) => {
  const callResponse = await axios({
    url: endpoints.pmsFetchById,
    method: 'get',
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getPmsDelete = async (data) => {
  const callResponse = await axios({
    url: endpoints.pmsDelete,
    method: 'delete',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getPmsUpdate = async (data) => {
  const callResponse = await axios({
    url: endpoints.pmsUpdate,
    method: 'patch',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const getPmsPdf = async (params) => {
  const { id, date } = params;
  const callResponse = await axios({
    url: `${endpoints.pmsPdf}?id=${id}&date=${date}`, // Include date as a query parameter
    method: 'get',
    headers: getHeaders(),
    responseType: 'blob' // Ensure the response type is blob for binary data
  })
    .then((response) => ({ status: 1, data: response.data }))
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const getPmsReportingPerson = async (params) => {
  const callResponse = await axios({
    url: endpoints.pmsReportingPerson,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
