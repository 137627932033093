import { memo, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../../store/actions/commonAction'

import { closeModal, openModal } from '../../../../store/actions/modalAction'


import Calendar from '../../../../components/layouts/Calendar'
import CalendarBox from './EmployeeCalendarInnerBox'

import {
  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  LEAVE_RESPONSE,
  SNACK_BAR_VARIETNS,

} from '../../../../utils/constants'
import { fetchTasksCalendarApi } from '../../../../apis/task.api'
import moment from 'moment'
import { EmployeeTaskUserContext } from '../../EmployeeMain'
import CustomMonthPicker from '../../../../components/layouts/common/CustomMonthPicker'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import TaskDetailsController from '../TaskDetails.controller'
import { callSnackBar } from '../../../../store/actions/snackbarAction'
import { getAllDailyUpdates } from '../../../../apis/dailyupdate.api'
import {  getPmsReportingPerson } from '../../../../apis/pms.api'
import PmsDetailsController from '../PmsDetailsController'
import { LEAVE_NAMES, LEAVE_STATUS } from '../../../../utils/leave.constants'
import { getMonthlyLeavesApi } from '../../../../apis/leave.api'
import DailyUpdateReportController from '../../DailyUpdate/DailyUpdateReportController'
import SubmitButton from '../../../../components/button/SubmitButton'
import { stripHtml } from 'string-strip-html'

const TaskCalendarController = ({ Component, isFromPerfomance }) => {
  const { holiday, user } = useSelector((state) => state);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [calendarTimeObj, setCalendarTimeObj] = useState(moment());

  // Determine the weekly off based on the selected user or logged-in user
  const weeklyOf = selectedUser ? selectedUser.weekly_of : user.data.weekly_of;

  // If a user is selected, use their ID; otherwise, don't use a userId to fetch all data
  const userId = selectedUser ? selectedUser._id : null;

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const holidayList = holiday.data;
  const [taskList, setTaskList] = useState(null);
  const [leaveList, setLeaveList] = useState(null);
  // For PMS and daily updates
  const [pmsTaskList, setPmsTaskList] = useState(null);
  const [updatesList, setUpdateList] = useState(null);

  const dispatch = useDispatch();

  // Set filters without userId if no user is selected
  const [filters, setFilters] = useState({
     userId: selectedUser ? selectedUser._id : undefined,
  });

  const fetchList = () => {
    setListLoading(true);

    const paramsToPass = {
    
      not_status:LEAVE_STATUS.REJECTED,
      forEmployee: true,
      //userId: selectedUser ? selectedUser._id : undefined,
    };

    dispatch(
      callApiAction(
        async () =>
          await getMonthlyLeavesApi({
            ...paramsToPass,
          
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setLeaveList(response);
        },
        (err) => {
          dispatch(
            callSnackBar("Something went wrong!", SNACK_BAR_VARIETNS.error)
          );
        }
      )
    );

    // Fetch PMS Tasks
    dispatch(
      callApiAction(
        async () => await getPmsReportingPerson({ ...filters, date: calendarTimeObj.toISOString() }),
        (response) => {
          setPmsTaskList(Array.isArray(response) ? response : []);
        },
        (err) => {
          setPmsTaskList([]);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch other tasks
    dispatch(
      callApiAction(
        async () =>
          await fetchTasksCalendarApi({
            ...paramsToPass,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          setTaskList(response);
        },
        (err) => {
          setTaskList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );

    // Fetch daily updates
    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
          userId: selectedUser?._id,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setUpdateList(response);
        },
        (err) => {
          setUpdateList(null);
          dispatch(callSnackBar('Server Error', SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [calendarTimeObj.valueOf(), selectedUser]);

  useEffect(() => {
    if (taskList && updatesList && pmsTaskList && leaveList) {
        let temp = [];

        // Process PMS tasks
        pmsTaskList.forEach((pmsItem) => {
          
            if (!selectedUser || pmsItem.user_id._id === selectedUser._id) {
                const taskDescription = pmsItem.monthly_task || '';
                const taskWords = taskDescription.split(' ').slice(0, 2).join(' ');
                const title = `${pmsItem.user_id.first_name} - ${taskWords}`;

                temp.push({
                    title,
                    start: new Date(pmsItem.completion_date),
                    end: new Date(pmsItem.completion_date),
                    relatedId: pmsItem._id,
                    type: CALENDAR_ITEM_TYPES.pms_task,
                    onClick: () => {
                        dispatch(
                            openModal({
                                title: 'PMS Task Detail',
                                component: (
                                    <PmsDetailsController
                                        callBack={() => {}}
                                        selectedUser={selectedUser}
                                        id={pmsItem.user_id}
                                        task={pmsItem}
                                    />
                                ),
                                size: 'md',
                            })
                        );
                    },
                });
            }
        });

        // Process other tasks
        taskList.forEach((item) => {
            temp.push({
                title: item.name,
                completed_date: item.completed_date ? new Date(item.completed_date) : null,
                start: new Date(item.date),
                end: new Date(item.date),
                relatedId: item._id,
                type: CALENDAR_ITEM_TYPES.task,
                status: item.status,
                priority: item.priority,
                onClick: () => {
                    dispatch(
                        openModal({
                            title: 'Task Detail',
                            component: <TaskDetailsController callBack={() => {}} selectedUser={selectedUser} id={item._id} />,
                            size: 'md',
                        })
                    );
                },
            });
        });

        // Process daily updates
        if (selectedUser) {
          const dailyUpdateMap = {};
          updatesList.forEach((item) => {
              const userIdKey = item.user_id; 
              const description = item.items[0]?.description || ''; 
              const plainText = stripHtml(description).result; 
              const updateTitle = plainText.split(' ').slice(0, 3).join(' '); 
              const dateKey = new Date(item.date).toDateString(); 
              
              // Create a unique key combining user ID and date
              const uniqueKey = `${userIdKey}-${dateKey}`;
              if (!dailyUpdateMap[uniqueKey]) {
                  dailyUpdateMap[uniqueKey] = {
                      title: updateTitle,
                      dailyUpdate: true,
                      start: new Date(item.date),
                      end: new Date(item.date),
                      type: CALENDAR_ITEM_TYPES.daily_updates,
                  };
              }
          });
    
  
        // Convert the map back to an array and add it to temp
        temp.push(...Object.values(dailyUpdateMap));
        }
  
        
      if (leaveList && Array.isArray(leaveList)) {
        leaveList.forEach((leave) => {
          const obj = {
            title: LEAVE_NAMES[leave.type - 1].label,
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.leave,
            status: leave.status,
            color:
              leave.status == LEAVE_STATUS.APPROVED
                ? LEAVE_NAMES[leave.type - 1]?.color
                : leave.status == LEAVE_STATUS.REJECTED
                ? "white"
                : "white",
            background:
              leave.status == LEAVE_STATUS.APPROVED
                ? LEAVE_NAMES[leave.type - 1]?.background
                : leave.status == LEAVE_STATUS.REJECTED
                ? "red"
                : "gray",
            applied_by: leave?.applied_by,
          
          };
          if (leave?.deducted_leave?.length > 0) {
            leave?.deducted_leave.forEach((subLeave) => {
              const timeDuration = {
                start: new Date(subLeave.from),
                end: new Date(subLeave.from),
                fromDuration: subLeave.from_time_duration,
                toTimeDuration: subLeave.from_time_duration,
              };
              temp.push({ ...obj, ...timeDuration });
            });
          } else {
            const timeDuration = {
              start: new Date(leave.from),
              end: new Date(leave.to),
              fromDuration: leave.from_time_duration,
              toTimeDuration: leave.to_time_duration,
            };
            temp.push({ ...obj, ...timeDuration });
          }
        });
      }

        // Process holidays
        if (holidayList && Array.isArray(holidayList)) {
            holidayList.forEach((leave) => {
                temp.push({
                    title: leave.name,
                    start: new Date(leave.date),
                    end: new Date(leave.date),
                    relatedId: leave._id,
                    type: CALENDAR_ITEM_TYPES.holiday,
                    color: LEAVE_COLORS.HOLIDAY,
                    onClick: () => {},
                });
            });
        }

        setList(temp);
        setListLoading(false);
    }
}, [taskList, updatesList, pmsTaskList,leaveList, selectedUser]);
const openMonthlyReport = () => {
  setListLoading(true);
  dispatch(
    openModal({
      component: <DailyUpdateReportController  callBack={() => {}} userId={selectedUser._id} />,
      size: "lg",
      title: "Monthly Daily Updates",
      confirmText: "Close",
      onCancle: () => dispatch(closeModal()),
    })
  )
  setListLoading(false);
};
const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} mb={4} >
        <Box sx={{ flex: 1, display: 'flex'}}>
          <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
        </Box>
        {selectedUser && (  <Box sx={{ height: '100%', display: 'flex', mt: isMobile ? 2 : 0 }} ml={isMobile ? 0 : 3}>
         <SubmitButton 
            loading={listLoading}
        title="Daily Update Summary"
        onClick={() => openMonthlyReport(userId)} />
      
                </Box>)}
            </Box>
      <Calendar
        loading={listLoading}
        data={list}
        dayComponent={(props) =>
          Component ? (
            <Component weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          ) : (
            <CalendarBox weeklyOf={weeklyOf ?? [0]} {...props} calendarTimeObj={calendarTimeObj} />
          )
        }
        calendarTimeObj={calendarTimeObj.toDate()}
      />
    </>
  );
};

export default memo(TaskCalendarController);