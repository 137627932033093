import { redirect } from "react-router-dom"

import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"
import { getBirthdaysApi } from "../../apis/birthday.api"
export const fetchBirthdayAction = (filters,callBack=()=>{}) => {
    return async (dispatch, getState) => {
    dispatch(callApiAction(
            async () => await getBirthdaysApi({...filters}),
            (response) => {
                
                
                dispatch({ type: actions.SET_BIRTHDAY_DATA,  data: response })
                callBack()

            },
            (err) => {
                callBack()
            }
        ))
    }
}
