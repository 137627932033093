import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Avatar, Badge, Box, ButtonBase, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField } from '@mui/material';

// import CircularProgress} from '@mui/material/CircularProgress';
// import List from '@mui/material/List';
// import ListSubheader from '@mui/material/ListSubheader';
import { FolderShared, PictureAsPdf } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import { getForm16ByIdApi } from '../../apis/form16.api';
import { CenteredBox } from '../../components/layouts/common/boxes';
import FileDownloadComponent from '../../components/FileDownloadComponent';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import SubmitButton from '../../components/button/SubmitButton';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import { useState } from 'react';
import { useEffect } from 'react';

// import { ListItemButton } from '@mui/material';

const Form16UI = () => {
  const { user } = useSelector(state => state)
  const currentDate = new Date()

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const updatedDate = moment(currentDate).set("date", 1).set("month", 3);
  const [date, setDate] = useState(updatedDate);
  // const [item, setItem] = React.useState();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const fetchList = () => {
    setLoading(true);
    dispatch(callApiAction(
      async () => await getForm16ByIdApi({ date: date.toISOString() }),
      (response) => {
        if (Array.isArray(response)) {
          setState(response);
        } else {
          console.error('API response is not an array:', response);
          setState([]);
        }
        setLoading(false);
      },
      (err) => {
        console.error('API call failed:', err);
        setLoading(false);
        setState([]);
      }
    ));
  };

  useEffect(() => {
    if (state.length === 0) {
      fetchList();
    }
  }, []);

  const errorfor = () => {
    dispatch(
      callSnackBar("No Form-16 Available", SNACK_BAR_VARIETNS.error)
    );
  }

  return (
    <>
      {/* <Tooltip title="Form-16">
                <IconButton aria-describedby={id} onClick={handleClick}>
                    <FolderShared />
                </IconButton>
            </Tooltip> */}

      <Box mt={3}>

        <DatePicker
          minDate={moment(updatedDate)}
          // maxDate={moment().add(-1,'month').endOf("month")}
          views={['year']}
          inputFormat='YYYY'
          onChange={(newValue) => setDate(newValue)}
          value={date}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              error={false}
            />
          )}
        />

        {(state != 0 && state.length > 0 ? state.map((item) => (
          // console.log("ITEME", item),
          <FileDownloadComponent
            key={user.data.first_name}
            downloadName={`${user.data.first_name}.pdf`}
            src={item.url}
            Component={({ loading, ...props }) => (
              <Box mt={8} sx={{ float: "right", marginTop: "15px" }}>
                {loading ? <CircularProgress size={20} /> : <SubmitButton  {...props} disabled={loading} title={"Download Form-16"} />}
              </Box>
            )}
          />

        )) : <Box mt={8} sx={{ float: "right", marginTop: "15px" }}><SubmitButton title={"Download Form-16"} onClick={errorfor} /></Box>)}
      </Box>

    </>
  );
}

export default Form16UI