import { useDispatch } from "react-redux";
import { useState } from "react";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import {
  REIMBURSEMENT_ADVANCE_PAYMENT_STATUS,
  SNACK_BAR_VARIETNS,
} from "../../utils/constants";
import {
  downloadAdvancePaymentPdf,
  getReimberesementPdfApi,
} from "../../apis/reimbersement.api";
import { ActionButton } from "../../components/button/SubmitButton";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Download, DownloadForOffline } from "@mui/icons-material";
import fileDownload from "js-file-download";
import {
  appresialLetterPdfApi,
  IndividualappresialLetterPdfApi,
} from "../../apis/appresial.api";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

const DownloadApprasialLetterPdfButton = ({ date, emp_id }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onClick = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await IndividualappresialLetterPdfApi({
            id: emp_id,
            date: date.toISOString(),
          }),
        (response) => {
          setLoading(false);
          console.log(response, "response in pdf ");
          if (
            response.status === 0 ||
            response.code === 400 ||
            response.message === "no appraisal letter found"
          ) {
            dispatch(
              callSnackBar(
                "No appraisal letter found",
                SNACK_BAR_VARIETNS.error
              )
            );
          } else {
            fileDownload(response, emp_id + "-appraisalletter.pdf");
            dispatch(
              callSnackBar(
                "Pdf Downloaded Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal());
          }
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error));
        },
        true
      )
    );
  };
  return (
    <Tooltip title="Download PDF">
      <IconButton sx={{ color: "#1E90FF" }} onClick={onClick}>
        {loading ? <CircularProgress size={24} /> : <DownloadForOffline />}
      </IconButton>
    </Tooltip>
  );
};
export default DownloadApprasialLetterPdfButton;
