import { Box, Button, ButtonGroup, Tabs,Tab } from "@mui/material"
import { memo, useContext, useState } from "react"

import TaskTable from "./TaskTable"
import { TASK_PRIORITY, TASK_STATUS } from "../../../utils/task.constants"
import UserSearchBar, { UserSearchBarNormal } from "../../../components/inputs/SearchBar"




const TaskListUi = ({
    loading,
    filters,
    list,
    general,
    fetchList,
    onTabChange,
    tab,
    setFilters,
    onAddButtonClick,
    onPriorityChange,
    onStatusChange,
    columns
}) => {

    return (
        <>
            <Box >


                <Box sx={{ display: "flex", flex: 1 }}>

                    <Tabs value={tab} onChange={onTabChange} sx={{ width: "100%", display: "flex", borderBottom: 1, borderBottomColor: "divider" }}>
                        <Tab sx={{ display: "flex" }} label="Task List" />
                        <Tab sx={{ display: "flex" }} label="Due Today" />
                        <Tab sx={{ display: "flex" }} label="This Week" />
                        <Tab sx={{ display: "flex" }} label="Mark As Completed" />
                        <Tab sx={{ display: "flex" }} label="Approved" />
                    </Tabs>
                </Box>
                {/* <Box><ActionButton onClick={onAddButtonClick} title="Add Task" icon={<Add />} ></ActionButton></Box> */}

                {general &&
                    <Box mt={3} sx={{ display: "flex",alignItems:"center", flex: 1, flexWrap: "wrap", justifyContent: "space-between" }}>
                        <ButtonGroup disableElevation>
                            <Button
                                onClick={() => {
                                    onPriorityChange(null)
                                }}
                                variant={filters.priority == null ? "contained" : "outlined"}
                            >All</Button>
                            <Button
                                onClick={() => {
                                    onPriorityChange(TASK_PRIORITY.HIGH)
                                }}
                                variant={filters.priority == TASK_PRIORITY.HIGH ? "contained" : "outlined"}

                            >High</Button>
                            <Button

                                onClick={() => {
                                    onPriorityChange(TASK_PRIORITY.MEDIUM)
                                }}
                                variant={filters.priority == TASK_PRIORITY.MEDIUM ? "contained" : "outlined"}
                            >Medium</Button>
                            <Button

                                onClick={() => {
                                    onPriorityChange(TASK_PRIORITY.LOW)
                                }}
                                variant={filters.priority == TASK_PRIORITY.LOW ? "contained" : "outlined"}
                            >Low</Button>
                        </ButtonGroup>

                        {/* {filters.status != TASK_STATUS.MARKED_AS_COMPLETED && filters.status != TASK_STATUS.COMPLETED && <ButtonGroup>
                            <Button
                                onClick={() => {
                                    onStatusChange(null)
                                }}
                                variant={filters.status == null ? "contained" : "outlined"}
                            >All</Button>
                            <Button

                                onClick={() => {
                                    onStatusChange(TASK_STATUS.PENDING)
                                }}
                                variant={filters.status == TASK_STATUS.PENDING ? "contained" : "outlined"}
                            >Pending</Button>
                        </ButtonGroup>} */}



                        {/* <Box sx={{minWidth:"300px"}}>
                            <UserSearchBarNormal   onUserChange={(changedUser)=>{
                                setFilters({...filters,assigned_to:changedUser?._id})
                            }}
                            inputProps={{
                                size:"small",
                                placeholder:"Search Employee",
                                margin:"none"
                            }}
                            
                            />
                            </Box> */}
                    </Box>
                }

            </Box>

            <TaskTable
                columns={columns}
                loading={loading}
                filters={filters}
                list={list}
                general={general}
                fetchList={fetchList}
                onTabChange={onTabChange}

                setFilters={setFilters}

            />
        </>
    )
}
export default memo(TaskListUi)