import AddHolidayDataUI from './AddHolidayDataUI'
import React, { useState } from 'react'
import { createHoliday } from '../../apis/holiday.api'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { closeModal } from '../../store/actions/modalAction'
import { fetchHolidayDataAction } from '../../store/actions/settingsAction'

const AddHolidayDataController = ({callBack}) => {
  
  const dispatch = useDispatch()
  const createHolidayApi = createHoliday
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState('')
  const [name, setName] = useState('')
  const [err, setErr] = useState('')
  const {settings} = useSelector((state) => state)

  const addHoliday = (e) => {
    e.preventDefault()
    
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await createHolidayApi({ date, name }),
        (response) => {
          callBack()
          dispatch(closeModal())
          dispatch(fetchHolidayDataAction(settings.holiday_filters))
          setLoading(false)
        },
        (err) => {
          setLoading(false)
          setErr(err)
        },
      ),
    )
  }
  return (
    <AddHolidayDataUI
      loading={loading}
      addHoliday={addHoliday}
      date={date}
      setDate={setDate}
      setName={setName}
      name={name}
      err={err}
      setErr={setErr}
    />
  )
}

export default AddHolidayDataController
