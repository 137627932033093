import {
  Add,
  CampaignOutlined,
  EventAvailableOutlined,
} from "@mui/icons-material";
import { Box, Button, Grid, Paper, styled } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
// import CoffRaiseController from './leaveRaise/CoffRaiseController'
// import LeaveRaiseController from './leaveRaise/LeaveRaiseController'
import SummaryMainDialog from "../leaves/leaveSummary/SummaryMainDialog";
import { center } from "../../assets/css/theme/common";
import { Link } from "react-router-dom";
import { ActionButton } from "../../components/button/SubmitButton";
import WFHRaiseButton from "../wfh/WFHRaiseButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DailyUpdateEditor from "../tasks/DailyUpdate/DailyUpdateEditor";
import moment from "moment";
import LeaveRaiseController from "../leaves/leaveRaise/LeaveRaiseController";
import ReimberesementCreateController from "../reimberesement/ReimberesementCreateController";
const LeaveButton = styled(Button)(({ theme }) => ({
  display: "flex",
  width: "33.33%",
  // height: '50px',
  // zIndex: 0,
  // textTransform: 'capitalize',
  // boxShadow: 'none',
  // border: '1px solid #393939',
  // ':hover': {
  //   boxShadow: 'none',
  // },
}));

const LeaveBtnBox = {
  width: "100%",

  display: "flex",
  gap: "14px",

  // height: "100%"
};

function DashboardButtons({ userId }) {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const onRaiseLeaveClick = useCallback(() => {
    dispatch(
      openModal({
        title: "Apply Leave",
        component: (
          <LeaveRaiseController
            modal
            userId={user.data._id}
            weeklyOf={user.data.weekly_of}
          />
        ),
        size: "lg",
      })
    );
  }, [userId]);
  const onLeaveClick = useCallback(() => {
    dispatch(
      openModal({
        size: "md",
        title: "Add Update",
        component: <DailyUpdateEditor date={moment().valueOf()} />,
      })
    );
  }, [userId]);

  const addReimbursementClick = useCallback(() => {
    dispatch(
      openModal({
        component: <ReimberesementCreateController modal userId={userId} />,
        size: "lg",
        title: "Apply Reimburesement",
      })
    );
  }, [userId]);

  return (
    <>
      <Box sx={LeaveBtnBox}>
        {
          <>
            <ActionButton
              variant="contained"
              sx={{ borderRadius: "4px" }}
              // LinkComponent={Link}
              icon={<CampaignOutlined color="inherit" />}
              title={"Apply Leave"}
              // to="../calendar/rais-leave"
              onClick={onRaiseLeaveClick}
            ></ActionButton>
            {/* <WFHRaiseButton /> */}
            <ActionButton
              variant="contained"
              sx={{ borderRadius: "4px" }}
              onClick={addReimbursementClick}
              icon={<CurrencyRupeeIcon color="inherit" />}
              title={"Reimbursement"}
            ></ActionButton>
                        <ActionButton
              variant="contained"
              sx={{ borderRadius: "4px" }}
              LinkComponent={Link}
              to="../tasks/my-tasks/daily-update"
              icon={<Add color="inherit" />}
              title="Add Update"
              // onClick={onLeaveClick}
            ></ActionButton>
          </>
        }
      </Box>
    </>
  );
}

export default DashboardButtons;
