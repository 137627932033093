import { Box, Card, CardContent, CardHeader, CircularProgress, Collapse, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import ImageComponent from '../../components/inputs/ImageComponent';
import { findObjectKeyByValue, toTitleCase } from '../../utils/helper';
import { AccountBox, AccountTree, AdminPanelSettings, Bloodtype, BusinessSharp, Cake, CalendarMonth, KeyboardArrowDown, KeyboardArrowUp, LocalHospitalOutlined, MailOutline, SupervisedUserCircle, Verified, WifiCalling3Outlined } from '@mui/icons-material';
import { center } from "../../assets/css/theme/common";
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../utils/constants';
import moment from 'moment';
import { CenteredBox } from '../../components/layouts/common/boxes';

function TeamMemberProfileUi({
    setState,
    loading,
    state,
}) {
    const [personal, setPersonal] = useState(true);
    const [company, setCompany] = useState(false);
    const collapsContainer = {
        borderBottom: "1px solid #7F7F7F",
    }
    const tableCell = {
        border: "none"
    }
  return <>
  {!loading && state &&
      <Box mt={3} mb={3}>
          <Box sx={{ ...center }}>
              <Box sx={{ marginTop: "48px", alignItems: "center", display: "flex", flexDirection: "column" }}>
                  <ImageComponent src={state.profile_url} isAvatar avtarTitle={state.first_name && state.first_name[0]&& state.first_name[0].toUpperCase()} />
                  <Typography sx={{ marginTop: "24px", ...center }} variant="h3" >{toTitleCase(state.first_name) +  " " + toTitleCase(state.last_name)}</Typography>
                  <Typography variant="h5" sx={{ ...center, ml: "0" }} color={"text.200"} ml={2}>({state.employee_id})</Typography>
                  <Typography variant="h6" sx={{ ...center, ml: "0" }} color={"text.200"} ml={2}>{state.designation}</Typography>
              </Box>

          </Box>
          <Card sx={{ minWidth: 300, border: "1px solid #7F7F7F", marginTop: "48px", boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)" }}>
              <CardHeader
                  title="Personal details"
                  onClick={() => setPersonal(!personal)}
                  action={
                      <IconButton
                          aria-label="expand"
                          size="small"
                      >
                          {personal ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                  }
                  sx={collapsContainer}
              >
              </CardHeader>
              <Box>
                  <Collapse in={personal} timeout="auto">
                      <CardContent>
                          <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                              <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableBody>
                                          <TableRow
                                          >
                                              <TableCell>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <Verified />&nbsp;&nbsp;Employee Id
                                                  </Typography>
                                              </TableCell>
                                              <TableCell>{state.employee_id ? state.employee_id : "NA"}</TableCell>
                                              <TableCell>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <Cake />&nbsp;&nbsp;DOB 
                                                  </Typography>
                                              </TableCell>
                                              <TableCell>{state.dob && moment(state.dob).format("DD/MM/YYYY")}
                                              </TableCell>
                                          </TableRow>
                                          <TableRow
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                              <TableCell>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <MailOutline />&nbsp;&nbsp;Email
                                                  </Typography>
                                              </TableCell>
                                              <TableCell><Link href={`mailto:${state.email}`}>{state.email}</Link></TableCell>
                                              <TableCell >
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <Bloodtype />&nbsp;&nbsp;Blood Group
                                                  </Typography>
                                              </TableCell>

                                              <TableCell >{state.blood_group && toTitleCase(state.blood_group)}
                                              </TableCell>
                                          </TableRow>
                                          <TableRow

                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                              <TableCell >
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <LocalHospitalOutlined />&nbsp;&nbsp;Medical Remarks
                                                  </Typography>
                                              </TableCell>

                                              <TableCell >{state.medical_remarks}
                                              </TableCell>
                                          </TableRow>
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                          </Container>
                      </CardContent>
                  </Collapse>
              </Box>
          </Card>
          <Card sx={{ minWidth: 300, border: "1px solid #7F7F7F", marginTop: "12px", boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)" }}>
              <CardHeader
                  title="Company Details"
                  onClick={() => setCompany(!company)}
                  action={
                      <IconButton
                          aria-label="expand"
                          size="small"
                      >
                          {company ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                  }
                  sx={collapsContainer}
              >
              </CardHeader>
              <Box>
                  <Collapse in={company} timeout="auto">
                      <CardContent>
                          <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                              <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableBody>
                                          <TableRow
                                          >
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <AccountTree />&nbsp;&nbsp;Branch
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={tableCell}>{state.branch && state.branch.name ? state.branch.name : "NA"}</TableCell>
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <AccountBox />&nbsp;&nbsp;Employee Type
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={tableCell}>{state.employee_type && state.employee_type.name ? toTitleCase(state.employee_type.name) : "NA"}
                                              </TableCell>
                                          </TableRow>
                                          <TableRow
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <AdminPanelSettings />&nbsp;&nbsp;Role
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={{ textTransform: "uppercase", ...tableCell }}>{state.role && findObjectKeyByValue(state.role, USER_ROLES)}</TableCell>
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <BusinessSharp />&nbsp;&nbsp;Department
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={tableCell}>{state.department && state.department.name ? state.department.name : "NA"}
                                              </TableCell>
                                          </TableRow>
                                          <TableRow
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <SupervisedUserCircle />&nbsp;&nbsp;Reporting Person
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={tableCell}>
                                                  {state.parent_id && Array.isArray(state.parent_id) && Array.isArray(state.parent_id) && state.parent_id[0] ? (state.parent_id[0].first_name + " " +state.parent_id[0].last_name) : "NA"}
                                              </TableCell>
                                              <TableCell sx={tableCell}>
                                                  <Typography sx={{ alignItems: "center", display: "flex", lineHeight: "100%" }} variant="h6" >
                                                      <CalendarMonth />&nbsp;&nbsp;Joining Date
                                                  </Typography>
                                              </TableCell>
                                              <TableCell sx={tableCell}>{state.join_date ? moment(state.join_date).format("DD-MM-YYYY") : "-"}
                                              </TableCell>
                                          </TableRow>
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                          </Container>
                      </CardContent>
                  </Collapse>
              </Box>
          </Card>
      </Box>
  }
  {
      !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
  }
  {
      loading && <CenteredBox><CircularProgress /></CenteredBox>
  }

</>
}

export default TeamMemberProfileUi