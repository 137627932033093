
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItemButton,
  ListSubheader,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import React, { useState, useEffect } from "react"; 
import { CenteredBox } from "../../components/layouts/common/boxes";
import moment from "moment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { center } from "../../assets/css/theme/common";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnnouncementAction,
  markAnnouncementAsReadAction,
  readAnnouncementAction,
} from "../../store/actions/announcementAction";
import Announcementmodal from "./AnnouncementModal";
import LeaveBalance from "../leaves/LeaveBalance";
import { closeModal, openModal } from "../../store/actions/modalAction";
import AnnouncementModal from "./AnnouncementModal";
import DashBox from "./Dashbox";
import { red } from '@mui/material/colors';
import { markAnnouncementAsReadApi } from "../../apis/announcement.api";

// function DashboardAnnouncement() {
//   const [Loading, setLoading] = useState(true);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const dispatch = useDispatch();
//   const { announcements } = useSelector((state) => state);
//   const [ids, setIds] = useState([]);
//   const [storedIds, setStoredIds] = useState([]);

//   const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

//   const handleClick = (announcement) => {
//     console.log("connn", announcement._id);

//     const updatedIds = [...storedIds, announcement._id];
//     setIds(updatedIds);

//     const stored = JSON.parse(localStorage.getItem('announcementIds') || '[]');

//     if(!stored.includes(announcement._id))
//     {
//     //   const updatedIds = [...storedIds, announcement._id];
//     // setIds(updatedIds);
//     localStorage.setItem('announcementIds', JSON.stringify(updatedIds));
//     console.log("annou", storedIds); // Will still only show latest value
//     }
//     //  localStorage.setItem('_id',announcement._id);
//     dispatch(
//       openModal({
//         title: "Announcements",
//         component: <AnnouncementModal announcement={announcement} />,

//         size: "md",

//         onCancle: () => dispatch(closeModal()),
//       })
//     );
//   };
//   useEffect(() => {
//     setLoading(false);
//     dispatch(fetchAnnouncementAction({ pageSize: 3 }));
//     const retrievedIds = JSON.parse(localStorage.getItem('announcementIds') || '[]');
//     setStoredIds(retrievedIds);

//     // const storedIds = localStorage.getItem('_id');
    

   
//   }, [ids]);

//   const open = Boolean(anchorEl);
//   // const id_badge = localStorage.getItem('_id');
//   return (
//     <DashBox title={"Latest Announcements"}>
//     {console.log(announcements.data.length)}
//     {/* {console.log("badge",id_badge[0])} */}

//       {announcements.data.length === 0 ? (
//         <Grid container spacing={2} p={4} ><Typography variant="body1" color="text.primary" align="center">
//       No New Announcements Yet.
//     </Typography></Grid>
//       ) : (
//         <>
        


//         {!Loading &&
//         announcements &&
//         announcements.data &&
//         Array.isArray(announcements.data) &&
//         announcements.data.map((announcement) => (
//           <Box
//             sx={{
//               display: "flex",
//               bgcolor: "#F5F5F5",
//               borderRadius: "10px",
//               borderColor: "lightgrey",
//               margin: 3,
//               alignItems: "center",
//             }}
//           >
//             <Box sx={{ width: "100%" }}>
//               <Link
//                 sx={{ textDecoration: "none" }}
//                 onClick={() => handleClick(announcement)}
//                 key={announcement._id}
//               >
//                 <ListItemButton
//                   disableTouchRipple
//                   sx={{}}
//                   key={announcement._id}
//                 >
//                   <Box
//                     p={1}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                     }}
//                   >
//              <Badge
//   color="error"
//   overlap="circular"
//   badgeContent=""
//   variant="dot"
// invisible={(storedIds.includes(announcement._id))?(true):(false)  }
//   anchorOrigin={{
//     vertical: 'top',
//     horizontal: 'right',
    
//   }}

// >
//                     <Box
//                       sx={{
//                         backgroundColor: "#ccc",
//                         width: 45, 
//                         height: 45,
//                         borderRadius: "50%",
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         p: 1.5,
//                         // mr: 2,
//                       }}
//                     >
//                       <Typography variant="h6" sx={{}}>
//                         {" "}
                        
//                         {moment(announcement.announcement_date).format("DD")}
//                       </Typography>

//                       <Typography variant="caption" lineHeight={"100%"}>
//                         {" "}
                        
//                         {moment(announcement.announcement_date).format("MMM")}
//                       </Typography>
//                     </Box>
//                       </Badge>
//                   </Box>

//                   <Box
//                     sx={{
                     
//                       width: "70%",
//                       ml: 2,

//                       flexDirection: "row",
//                       alignItems: "flex-start",
//                     }}
//                   >
//                     <Typography
//                       variant="caption"
//                       color="text.200"
//                       sx={{ flexGrow: 1, width: "100%" }}
//                     >
//                       {announcement.announcement_title.toUpperCase()}
//                     </Typography>
//                     <Typography
//                       variant="subtitle2"
//                       color="text.200"
//                       sx={{ justifySelf: "flex-end" }}
//                     >
//                       {"" +
//                         moment(announcement.announcement_date).fromNow(true) +
//                         " Ago"}
//                     </Typography>
                    
//                   </Box>
//                   <Box
//   variant="p"
//   sx={{ justifySelf: "flex-end" }}
// >
//   {/* <Box
//     color="red" 
//     sx={{
//       borderRadius:"50%",
//       width: "10px",
//       height:"10px",
//       bgcolor: "#ff1744",
//       color: "#ff1744"

//     }}
//   >

//   </Box> */}
// </Box>
//                 </ListItemButton>
//               </Link>
//             </Box>
//           </Box>
            
//           ))}
//         </>
//       )}
//     </DashBox>
//   );
// }

// export default DashboardAnnouncement;




function DashboardAnnouncement() {
  const [Loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.announcements.data);
  const [localAnnouncements, setLocalAnnouncements] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAnnouncementAction()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setLocalAnnouncements(announcements);
  }, [announcements]);

  const handleClick = (announcement) => {
    dispatch(markAnnouncementAsReadAction(announcement._id));

    // Update local state immediately
    setLocalAnnouncements(prevState =>
      prevState.map(item =>
        item._id === announcement._id ? { ...item, isRead: true } : item
      )
    );

    dispatch(
      openModal({
        title: "Announcements",
        component: <AnnouncementModal announcement={announcement} />,
        size: "md",
        onCancle: () => dispatch(closeModal()),
      })
    );
  };

  if (!localAnnouncements || localAnnouncements.length === 0) {
    return (
      <DashBox title={"Latest Announcements"}>
        <Grid container spacing={2} p={4}>
          <Typography variant="body1" color="text.primary" align="center">
            No New Announcements Yet.
          </Typography>
        </Grid>
      </DashBox>
    );
  }

  return (
    <DashBox title={"Latest Announcements"}>
      {!Loading &&
        localAnnouncements.map((announcement) => (
          <Box
            key={announcement._id}
            sx={{
              display: "flex",
              bgcolor: "#F5F5F5",
              borderRadius: "10px",
              borderColor: "lightgrey",
              margin: 3,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Link
                sx={{ textDecoration: "none" }}
                onClick={() => handleClick(announcement)}
              >
                <ListItemButton disableTouchRipple>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Badge
                      color="error"
                      overlap="circular"
                      badgeContent=""
                      variant="dot"
                      invisible={announcement.isRead}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#ccc",
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          p: 1.5,
                        }}
                      >
                        <Typography variant="h6">
                          {moment(announcement.announcement_date).format("DD")}
                        </Typography>
                        <Typography variant="caption" lineHeight={"100%"}>
                          {moment(announcement.announcement_date).format("MMM")}
                        </Typography>
                      </Box>
                    </Badge>
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      ml: 2,
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="text.200"
                      sx={{ flexGrow: 1, width: "100%" }}
                    >
                      {announcement.announcement_title.toUpperCase()}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.200"
                      sx={{ justifySelf: "flex-start" }}
                    >
                      {`${moment(announcement.announcement_date).fromNow(true)} Ago`}
                    </Typography>
                  </Box>
                </ListItemButton>
              </Link>
            </Box>
          </Box>
        ))}
    </DashBox>
  );
}

export default DashboardAnnouncement;