import React, { useState } from 'react'
import MultipleDepartmentAddUi from './MultipleDepartmentAddUi'
import { useDispatch } from 'react-redux';
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from '../../store/actions/modalAction';
import { UserMultipleDepartmentUpdate } from '../../apis/department';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';


const MultipleDepartmentAddController = () => {
    const [field, Setfield] = useState({
        id: '',
        department: '',
        parent_id: ''
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Validation function
    const validateFields = () => {
        const { id, department, parent_id } = field;
        if (!id || !department || !parent_id) {
            return false;
        }
        return true;
    };

    const MultipleDepartmentAdd = () => {
        if (!validateFields()) {
            dispatch(callSnackBar("Please fill all the required fields", SNACK_BAR_VARIETNS.error));
            return;
        }

        setLoading(true);
        dispatch(callApiAction(
            async () => await UserMultipleDepartmentUpdate({ ...field }),
            (response) => {
                setLoading(false);
                Setfield({ id: '', department: '', parent_id: '' });  // Clear fields on success
                dispatch(closeModal());

                // Show snackbar on success
                dispatch(callSnackBar("Update Successful", SNACK_BAR_VARIETNS.suceess));
            },
            (err) => {
                setLoading(false);
                
                // Show snackbar on error
                dispatch(callSnackBar("Update Failed", SNACK_BAR_VARIETNS.error));
            }
        ));
    };

    return (
        <MultipleDepartmentAddUi
            MultipleDepartmentAdd={MultipleDepartmentAdd}
            field={field}
            Setfield={Setfield}
            loading={loading}
        />
    );
};

export default MultipleDepartmentAddController;