import moment from "moment"
import { memo, useCallback, useEffect, useState } from "react"
import MyLeaveUi from "./MyLeaveUi"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import { useSelector } from "react-redux"
import CalendarController from "../calendar/CalendarController"
import { Box, Button } from "@mui/material"
import SubmitButton from "../../components/button/SubmitButton"
import DownloadLogsCsvButton from "./actionbuttons/DownloadLogsCsvButton"

const GeneralLeaveController = () => {
    const { user } = useSelector(state => state)
    const [date, setDate] = useState(moment())

    return (
        <>
            <Box mt={3}></Box>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1,display:"flex" }}>
                    <CustomMonthPicker date={date} setDate={setDate} />
                </Box>
                <Box sx={{height:"100%",display:"flex"}} ml={3}>
                    <DownloadLogsCsvButton date={date} />
                </Box>
            </Box>
            <Box mt={3}>
                <CalendarController forEmployee weeklyOf={user.data.weekly_of} userId={user.data._id} calendarTimeObj={date && date.toDate()} />
            </Box>
        </>
    )
}
export default memo(GeneralLeaveController)