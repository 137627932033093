import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createDailyUpdate } from "../../../apis/dailyupdate.api";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import SubmitButton from "../../../components/button/SubmitButton";
import moment from "moment";

function DailyUpdateEditor({
  date,
  departmentId,
  secondaryParentId,
  callBack = () => {},
}) {
  const [data, setData] = useState(""); // Store CKEditor data here
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!data.trim()) {
      dispatch(
        callSnackBar("Daily Update description cannot be empty.", "error")
      );
      setLoading(false);
      return;
    }

    // Extract only the date part (YYYY-MM-DD) to ensure no time zone issues
    const formattedDate = moment(date).format("YYYY-MM-DD"); // Format to YYYY-MM-DD

    const response = await createDailyUpdate({
      description: data.trim(),
      date: formattedDate, // Pass only the date in YYYY-MM-DD format
      department: departmentId,
      secondary_parentId: secondaryParentId,
    });

    if (response.status === 1) {
      callBack();
      dispatch(callSnackBar("Daily Update Added", "success"));
      dispatch(closeModal());
    } else {
      dispatch(
        callSnackBar(response.message || "Failed to add Daily Update", "error")
      );
    }

    setLoading(false);
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Box className="App">
      <Box component={"form"} onSubmit={onSubmit}>
        <CKEditor
          disabled={loading}
          editor={ClassicEditor}
          style={{ minHeight: "500px" }}
          config={{
            placeholder: "Add Update...",
            style: { height: "500px", minHeight: "500px" },
            toolbar: ["bulletedList", "numberedList"],
          }}
          data={data} // Initial data is empty
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "200px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const newData = editor.getData();
            setData(newData);
          }}
        />
        <Box mt={2}>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {loading ? <CircularProgress /> : "Add"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DailyUpdateEditor;
