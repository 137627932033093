import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchLeaveTypes = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchLeaveType,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response)  => response.data )
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const createLeaveType = async (data) => {
  const callResponse = await axios({
    url: endpoints.addLeaveType,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));
    
  return callResponse;
};

export const deleteLeaveType = async (data) => {
  const callResponse = await axios({
    url: endpoints.deleteLeaveType,
    method: "delete",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateLeaveType = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateLeaveType,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getLeaveTypeByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchByIdLeaveType,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
