
import { actions } from "../../utils/constants";


// const initialState = {
//     new: 0,
//     data: [

//     ],

// }
// const notificationReducer = (state = initialState, action) => {

//     switch (action.type) {
//         case actions.SET_NOTIFICATION_DATA: return { ...state, new: action.new, data: action.data };
//         case actions.NOTIFICATION_READ: return { ...state, new: 0 };
//         default: return { ...state }
//     }

// }
// export default notificationReducer
const initialState = {
  new: 0,
  data: [],
  lastReadTimestamp: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_NOTIFICATION_DATA:
      return { ...state, new: action.new, data: action.data };
    case actions.NOTIFICATION_READ:
      return { ...state, new: 0, data: state.data.map(notification => ({ ...notification, read: true })) };
    case actions.SET_USER_LAST_READ_TIMESTAMP:
      return { ...state, lastReadTimestamp: action.value };
    default:
      return state;
  }
};

export default notificationReducer;
