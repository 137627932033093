import { Delete, Edit } from "@mui/icons-material";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import UpdateLeaveTypeController from "./UpdateLeaveTypeController";

const UpdateLeaveTypeButton = ({ params, setParams }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState()
  const onClick = () => {
    dispatch(
      openModal({
        title: "Update LeaveType",
        component: (
          <UpdateLeaveTypeController
            callBack={(res) => {
              setParams({ ...params, ...res });
            }}
            id={params._id}
            initialname={params.name}
            initialcolor={params.color}
            initialsystem_default={params.system_default}
            initialidentifier={params.identifier}
            initialpriority={params.priority}
            initialshort_name={params.short_name}
          />
        ),
      })
    );
  };
  
  if (loading) return <CircularProgress />;
  return (
    <IconButton onClick={onClick}>
      <Edit color="info" />
    </IconButton>
  );
};
export default UpdateLeaveTypeButton;
