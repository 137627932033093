import { Announcement } from "@mui/icons-material";
import {  actions } from "../../utils/constants";
import { accessToken, loggedInUser, refreshToken } from "../../utils/helper";

const initialState = {
    data: {},

    isLoggedIn: false,

}
const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case actions.SET_USER: return { ...state, data: action.value, isLoggedIn: true };
        case actions.UPDATE_USER_DATA: return { ...state, data: {...state.data,...action.value} };
        case actions.SIGN_IN: {
            accessToken.set(action.value.access_token)

            refreshToken.set(action.value.refresh_token)
            return { ...state, data: action.value, isLoggedIn: true };
        }
        case actions.SIGN_OUT: return { ...state, data: {}, isLoggedIn: false };
        case actions.SET_USER_LAST_READ_NOTIFICATION: return { ...state, data: { ...state.data, last_read_notification: action.value } };
        case actions.SET_USER_LAST_READ_ANNOUNCEMENT: return { ...state, data: { ...state.data, last_read_announcement: action.value } };
        default: return { ...state }
    }
   
}
export default userReducer