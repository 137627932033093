
const breakPoint = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 870,
            lg: 1200,
            xl: 1800,
        },
    }
}
export default breakPoint