import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from './../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from './../../store/actions/commonAction'



import WFHRaiseUi from './WFHRaiseUi'

import moment from 'moment'

import { useNavigate } from 'react-router-dom'
import { createWFHrequest } from '../../apis/wfh.api'
import { HALF_TYPE, HALF_TYPE_OBJ } from '../../utils/constants'

const WFHRaiseController = ({ userId, weeklyOf }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const { holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [fields, setFields] = useState({
    err: '',
    date: moment().toISOString(),
    userId: userId,
    start_time: moment().toISOString(),
    end_time: moment().toISOString(),
    duration: HALF_TYPE_OBJ.FULL_DAY,
    reason: '',
  })
console.log("Fields data",fields)
  const validationSchema = useMemo(
    () => [
     
      {
        required: true,
        value: fields.date,
        field: 'Date ',
        // custom: () => {
        //   return !disableDates(moment(fields.date))
        // },
      },
      {
        required: true,
        value: fields.start_time,
        field: 'Start Time ',
      },
      {
        required: true,
        value: fields.end_time,
        field: 'End Time ',
      },
      {
        required: true,
        value: fields.reason,
        field: 'Reason',
      },
    ]
    [fields],
  )

  const disableDates = (date) => {
    let isHoliday = false
    if (holiday.data && Array.isArray(holiday.data)) {
      for (let i = 0; i < holiday.data.length; i++) {
        const item = holiday.data[i]
        const incomingDate = moment(item.date)
        isHoliday =
          incomingDate.get('dates') == date.get('dates') &&
          incomingDate.get('months') == date.get('months') &&
          incomingDate.get('years') == date.get('years')
        if (isHoliday) break
      }
    }
    let isWeeklyOf = false
    if (!isHoliday) {

      if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
        isWeeklyOf = true
      } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
        isWeeklyOf = true
      }
    }
    return isWeeklyOf || isHoliday
  }

  const onSubmit = async (e) => {
    if (holiday.loading) {
      return
    }
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const passData = { ...fields }




      setLoading(true)
      dispatch(
        callApiAction(
          async () => await createWFHrequest(passData),
          async (response) => {

            setLoading(false)
            navigate("/calendar/")
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }




  return (
    <WFHRaiseUi
      disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}
export default memo(WFHRaiseController)
