import { Button, ButtonGroup, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Fragment } from "react"
import { useDispatch, useSelector } from 'react-redux'

import SubmitButton, { ResetButton } from "../../components/button/SubmitButton"

import CustomInput from "../../components/inputs/CustomInput"
import { UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { closeModal } from "../../store/actions/modalAction"
import { FOOD_TYPES, FUEL_PRICE_PER_KM, REIMBURSEMENT_ADVANCE_PAYMENT_STATUS, REIMBURSEMENT_FUEL_TYPE, REIMBURSEMENT_STATUS, REIMBURSEMENT_TRAVEL_TYPE, REIMBURSEMENT_TYPE, USER_ROLES } from "../../utils/constants"
import CustomRadioButtons from "../../components/layouts/common/CustomRadioButtons"
import FileInput from "../../components/inputs/FileInput"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getAdvancePaymentsListApi } from "../../apis/reimbersement.api"
import { CenteredBox } from "../../components/layouts/common/boxes"


const TravelTypeFields = ({ fields, setFields, loading, defaultData, shouldDisableDate }) => {
    return <>
        <Grid container mt={3} columnSpacing={3} rowSpacing={3}>
            <Grid item md={4} xs={12} >
                <Typography variant="h3" mb={2}>Place Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.place}
                    onChange={(e) => setFields({ ...fields, err: '', place: e.target.value })}
                    type="text"


                />
            </Grid>
            <Grid item md={8} xs={12}  >
                <Typography variant="h3" mb={2}>Purpose Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"


                />
            </Grid>

            <Grid item md={9} xs={12} >
                <Typography variant="h3" mb={2}>Vehichle Type*</Typography>
                <CustomRadioButtons
                    value={fields.vehichleType}
                    onChange={(e) => setFields({ ...fields, err: '', vehichleType: e.target.value })}
                    options={Object.keys(REIMBURSEMENT_TRAVEL_TYPE).map((key) => ({
                        label: REIMBURSEMENT_TRAVEL_TYPE[key].toUpperCase(),
                        value: REIMBURSEMENT_TRAVEL_TYPE[key]
                    }))} />
            </Grid>
            <Grid item md={3} xs={12}  >
                <Typography variant="h3" mb={2}>Amount*</Typography>
                <OutlinedInput
                    fullWidth
                    disabled={loading}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"
                    min={0}


                />
            </Grid>

        </Grid>

    </>
}

const FuelTypeFields = ({ fields, setFields, loading, defaultData, shouldDisableDate }) => {
    const { user } = useSelector(state => state)
    return <>

        <Grid container mt={3} columnSpacing={3} rowSpacing={3}>
            <Grid item md={4} xs={12} >
                <Typography variant="h3" mb={2}>Place Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.place}
                    onChange={(e) => setFields({ ...fields, err: '', place: e.target.value })}
                    type="text"


                />
            </Grid>

            <Grid item md={8} xs={12} >
                <Typography variant="h3" mb={2}> Purpose Of Visit*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"


                />
            </Grid>
            <Grid item md={4} xs={12}>
                <Typography variant="h3" mb={2}> Vehichle Type*</Typography>
                <CustomRadioButtons
                    value={fields.vehichleType}
                    onChange={(e) => {
                        let noOfKms = 0

                        // for (let fuelType of REIMBURSEMENT_FUEL_TYPE) {
                        //     if (fuelType.label == e.target.value) {
                        //         noOfKms = fuelType.kmPrice
                        //     }
                        // }
                        setFields({ ...fields, vehichleType: e.target.value, amount: parseFloat(user.data[e.target.value]) * parseFloat(fields.noOfKMS) })
                    }}


                    options={REIMBURSEMENT_FUEL_TYPE.map((item) => ({
                        label: item.label,
                        value: item.value
                    }))} />
            </Grid>

            <Grid item md={4} xs={12} >
                <Typography variant="h3" mb={2}> No Of KMS(To & FRO)*</Typography>
                <OutlinedInput
                    min={0}
                    fullWidth
                    disabled={loading || fields.vehichleType == ''}
                    value={fields.noOfKMS}
                    endAdornment={<InputAdornment position="end" >
                        <Box sx={{ borderLeft: "2px solid lightgrey" }} pl={2} >
                            <Typography fontSize="inherit">
                                ₹ {fields.amount && !isNaN(fields.amount) ? fields.amount : 0}
                            </Typography>

                        </Box>
                    </InputAdornment>}
                    onChange={(e) => setFields({
                        ...fields, err: '', noOfKMS: e.target.value, amount: parseFloat(user.data[fields.vehichleType]) * parseFloat(e.target.value)
                    })}
                    type="number"
                />
            </Grid>

        </Grid >


    </>
}

const FoodTypeFields = ({ fields, setFields, loading, defaultData, shouldDisableDate }) => {
    return <>



        <Grid container mt={1} columnSpacing={3} rowSpacing={3}>
            <Grid item md={6} xs={12}  >
                <Typography variant="h3" mb={2}> Food For*</Typography>

                <CustomRadioButtons

                    value={fields.foodType}
                    onChange={(e) => {
                        setFields({ ...fields, foodType: e.target.value })
                    }}
                    options={Object.keys(FOOD_TYPES).map((key) => ({
                        label: FOOD_TYPES[key],
                        value: FOOD_TYPES[key]
                    }))} />
            </Grid>

            <Grid item md={3} xs={12}>
                <Typography variant="h3" > Amount*</Typography>

                <CustomInput
                    disabled={loading || fields.type == REIMBURSEMENT_TYPE.fuel}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"

                />
            </Grid>


            <Grid item md={12} xs={12}>
                <Typography variant="h3" mb={2}> Purpose*</Typography>
                <CustomInput

                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"
                    rows={3}
                    multiline

                />
            </Grid>


        </Grid>


    </>
}


const OtherTypeFields = ({ fields, setFields, loading, defaultData, shouldDisableDate }) => {
    return <>
        <Grid container mt={3} columnSpacing={3} rowSpacing={3}>
            <Grid item md={12} xs={12} >
                <Typography variant="h3" mb={2}> Purpose*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.purpose}
                    onChange={(e) => setFields({ ...fields, err: '', purpose: e.target.value })}
                    type="text"
                    rows={3}
                    multiline

                />
            </Grid>

            <Grid item md={12} xs={12} >
                <Typography variant="h3" mb={2}> Amount*</Typography>
                <CustomInput
                    disabled={loading}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"


                />
            </Grid>


        </Grid>

    </>
}
const ReimberesementCreateUI = ({ modal, fields, setFields, loading, onSubmit, defaultData, shouldDisableDate }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h2">
                {!modal &&'Apply Reimburesement'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent >
                <Box sx={{ width: "100%", maxWidth:modal?"unset": "900px" }}>


                    <Grid container columnSpacing={3} rowSpacing={3} >
                        <Grid item md={4} xs={12}>
                            <Typography variant="h3" mb={2}>Date</Typography>
                            <DesktopDatePicker
                                shouldDisableDate={shouldDisableDate}
                                renderInput={(props) => {
                                    return <TextField fullWidth {...props} />
                                }}

                                inputFormat="DD-MM-yyyy"
                                disabled={loading}
                                value={fields.expenseDate}
                                onChange={(e) => {

                                    setFields({ ...fields, err: '', expenseDate: e._d.toISOString() })

                                }
                                }
                                type="date"


                            />

                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant="h3" mb={2}>Select Expense Type</Typography>
                                <ButtonGroup disableElevation fullWidth sx={{ display: "flex", flex: 1, width: "100%" }} >
                                    {
                                        Object.keys(REIMBURSEMENT_TYPE).map((key) => {

                                            return <Button sx={{ minWidth: "200px", height: "100%" }} variant={REIMBURSEMENT_TYPE[key] == fields.type ? "contained" : "outlined"}
                                                onClick={() => { setFields({ ...fields, err: '', ...defaultData, expenseDate: fields.expenseDate, type: REIMBURSEMENT_TYPE[key] }) }} >
                                                {REIMBURSEMENT_TYPE[key].toUpperCase()}
                                            </Button>

                                        })
                                    }
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ minHeight: "250px" }} >
                        {fields.type && fields.type == REIMBURSEMENT_TYPE.food && <FoodTypeFields fields={fields} loading={loading} setFields={setFields} />}
                        {fields.type && (fields.type == REIMBURSEMENT_TYPE.fuel) && <FuelTypeFields fields={fields} loading={loading} setFields={setFields} />}
                        {fields.type && fields.type == REIMBURSEMENT_TYPE.travel && <TravelTypeFields fields={fields} loading={loading} setFields={setFields} />}
                        {fields.type && fields.type == REIMBURSEMENT_TYPE.other && <OtherTypeFields fields={fields} loading={loading} setFields={setFields} />}
                    </Box>
                    {fields.type && fields.type != '' &&

                        <>


                            <Typography variant="h3" mt={3} mb={2}>

                                Attach Bills If Any
                            </Typography>


                            <FileInput
                                // crop
                                // onlyImage
                                onChange={(files, filesList) => {
                                    setFields({ ...fields, bills: filesList })
                                }}
                                multiple
                                accept=".pdf"
                                inputProps={{ accept: ".pdf" }}
                                title={`Attach Bills ${fields.type == REIMBURSEMENT_TYPE.food || fields.type == REIMBURSEMENT_TYPE.travel ? "*" : ""}`}
                                subTitle="Only pdf files are allowed! less than 2 MB"
                            />
                            <Box mt={4} />
                            {/* <FormControl >
                                <Typography component={Typography} variant="h3">
                                    Is Paid in Advance?
                                </Typography>
                                <RadioGroup
                                    value={fields.used_from_advance}
                                    onChange={(e) => {
                                        setFields({ ...fields, used_from_advance: (e.target.value) ?? null, advance_payment_detail: null, advance_payment_id: null })
                                    }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />

                                </RadioGroup>
                            </FormControl>

                            {fields.used_from_advance == 'true' &&
                                <AsyncDropDown
                                    // InputComponent={(props) => <CustomInput label="Select Employee*" placeholder="Select Employee"  {...props} />}
                                    lazyFun={async (props) => {
                                        return await getAdvancePaymentsListApi({ ...props, status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN, onlyme: true, searchable: ['purpose'] })
                                    }}
                                    label="Select Paid Advance*"
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, err: '', advance_payment_detail: changedVal, advance_payment_id: changedVal?._id })
                                    }}
                                    titleKey={'purpose'}
                                    valueKey={"_id"}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                            <Typography ml={3} variant="h5">{option.purpose}</Typography>
                                        </Box>
                                    }}

                                />
                            }
                            {
                                fields.advance_payment_detail && <>

                                    <Box sx={{ display: "flex" }}>
                                        <CenteredBox component={Paper} flex={1} flexDirection="column" variant="outlined" p={2} >
                                            <Typography variant="h3" fontWeight="600">
                                                {fields.advance_payment_detail?.amount} Rs.
                                            </Typography>
                                            <Typography variant="caption" align="center" >
                                                Total Paid
                                            </Typography>
                                        </CenteredBox>
                                        <CenteredBox p={1} ml={1} mr={1}>
                                            <Typography variant="h3" fontWeight="600" >
                                                -
                                            </Typography>
                                        </CenteredBox>
                                        <CenteredBox component={Paper} flex={1} flexDirection="column" variant="outlined" p={1} >
                                            <Typography variant="h3" fontWeight="600" >
                                                {fields.advance_payment_detail?.used_amount} Rs.
                                            </Typography>
                                            <Typography variant="caption" align="center" >
                                                Total Used
                                            </Typography>
                                        </CenteredBox>
                                        <CenteredBox p={1} ml={1} mr={1}>
                                            <Typography variant="h3" fontWeight="600" >
                                                =
                                            </Typography>
                                        </CenteredBox>
                                        <CenteredBox component={Paper} flex={1} flexDirection="column" variant="outlined" p={1} >
                                            <Typography variant="h3" fontWeight="600" >
                                                {fields.advance_payment_detail?.amount - fields.advance_payment_detail?.used_amount} Rs.
                                            </Typography>
                                            <Typography variant="caption" align="center" >
                                                Available Balance
                                            </Typography>
                                        </CenteredBox>
                                    </Box>
                                </>
                            } */}

                        </>

                    }






                    <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

                        <Box sx={{ display: "flex" }} mr={3}>
                            <ResetButton variant='outlined' onClick={handleClose} title={'Close'}></ResetButton>


                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <SubmitButton loading={loading} disabled={loading ||  fields.used_from_advance == "false" ? (false):((fields.used_from_advance ?
                                (fields.advance_payment_detail ?
                                    (fields.advance_payment_detail.amount - fields.advance_payment_detail.used_amount)<fields.amount
                                    : true)
                                : false))}
                                type="submit" title="Apply" />
                        </Box>
                    </Box>


                </Box>

            </DialogContent>



        </Box>

    </>
}
export default ReimberesementCreateUI