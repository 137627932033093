import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import { loggedInUser } from "../../utils/helper";
import { fetchLeaveTypes, createLeaveType } from "../../apis/leaveType.api";
import LeaveTypeUi from "../leaveType/LeaveTypeUi";
import UpdateLeaveTypeButton from "./UpdateLeaveTypeButton";
import DeleteLeaveTypeButton from "./DeleteLeaveTypeButton";
import { Box } from "@mui/material";

const LeaveTypeController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchLeavesApi = fetchLeaveTypes;

  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1,
  });

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 2,
        fieldName: "color",
        label: "Color",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 3,
        fieldName: "short_name",
        label: "Short Name",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 4,
        fieldName: "system_default",
        label: "System",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          params.system_default === true ? "Yes" : "No",
      },
      {
        id: 5,
        fieldName: "identifier",
        label: "Identifier",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 6,
        fieldName: "priority",
        label: "Priority",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 7,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params, setParams) => (
          <Box sx={{ display: "flex" }}>
            <UpdateLeaveTypeButton params={params} setParams={setParams} />
            <DeleteLeaveTypeButton params={params} setParams={setParams} />
          </Box>
        ),
      },
    ],
    [dispatch]
  );

  const fetchList = () => {
    setLoading(true);

    dispatch(
      callApiAction(
        async () => await fetchLeavesApi(filters),
        (response) => {
          setState(response);
          setLoading(false);
        },

        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <LeaveTypeUi
      columns={columns}
      setState={setState}
      loading={loading}
      state={state}
      callBack={() => {
        fetchList();
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};
export default LeaveTypeController;
