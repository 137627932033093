import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  useThemeProps,
} from '@mui/material'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from '../../../components/button/SubmitButton'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import { HALF_TYPE_OBJ, LEAVE_RESPONSE, USER_ROLES } from '../../../utils/constants'
import { findObjectKeyByValue, leaveStatusColored } from '../../../utils/helper'
import { LEAVE_STATUS, LEAVE_TYPE } from '../../../utils/leave.constants'
import { CancelRounded, CheckCircle } from '@mui/icons-material'
import CustomInput from '../../../components/inputs/CustomInput'
import { LeavePreviewBox } from '../leaveRaise/LeavePreview'

const LeaveActionComponent = ({ state,
  setState,
  fieldLoading,
  onApprove,
  onReject,
  loading,
  isFromModal }) => {

  const [isBtnClicked, setButtonClicked] = useState('')
  const buttonClick = (title) => {
    setButtonClicked(title)
  }
  const handleClose = () => {
    setButtonClicked('')
  }
  const handleSubmit = () => {
    // if (isBtnClicked == 'approve') {
    //   onApprove()
    // }
    if (!state.action_remarks || state.action_remarks == '') {
      setState({ ...state, err: "Remarks is required." })
      return false
    }
    onReject()
  }
  return (

    <>
      <Dialog
        open={isBtnClicked != ''}
        TransitionComponent={Fade}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant='body1'>{"Add remarks for " + (isBtnClicked == "approve" ? "approval" : "rejection")}</Typography>
          <Typography variant='caption' color='error'>{state.err}</Typography>
        </DialogTitle>
        <DialogContent>
          <CustomInput
            multiline
            rows={4}
            disabled={fieldLoading.loading}
            value={state.action_remarks}
            onChange={(e) => setState({ ...state, action_remarks: e.target.value, err: '' })}
            type="text"
            placeholder="Write remarks..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {<Button variant='contained' disabled={fieldLoading.loading} onClick={handleSubmit}> {fieldLoading.loading && <CircularProgress color='light' size={20} />}&nbsp;{isBtnClicked == "approve" ? "Approve Leave" : "Reject Leave"}</Button>}
        </DialogActions>
      </Dialog>
      {!fieldLoading.loading && <Tooltip title="Approve">
        <IconButton onClick={() => {
          onApprove()
        }}>
          <CheckCircle color="success" />
        </IconButton>

      </Tooltip>}
      {!fieldLoading.loading && <Tooltip title="Reject">
        <IconButton onClick={() => {
          buttonClick('reject')
        }}>
          <CancelRounded color="error" />
        </IconButton>
      </Tooltip>}
      {
        fieldLoading.loading && <CircularProgress size={30} />
      }
    </>
  )
}

const LeaveDetailsUi = ({
  state,
  setState,
  fieldLoading,
  onApprove,
  onReject,
  loading,
  isFromModal,

  onDelete
}) => {
  const { user } = useSelector((state) => state)
  const dispatch = useDispatch()
const theme = useTheme()
const desktop = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <>
      {!loading && state && (
        <Box mt={isFromModal ? 0 : 3} >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              
            }}
          >
            {!isFromModal && (
              <Typography mb={3} variant="h2">Leave Application</Typography>
            )}
            <Box  p={desktop?3:0} pt={0}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid xs={6} item>
                  <Typography variant="h5">Applied By :</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.applied_by && (state.applied_by.first_name + " " + state.applied_by.last_name)}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">Application Date :</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">Type</Typography>
                  <Typography variant="h6" color="grey.main">
                    {
                      findObjectKeyByValue(state.type, LEAVE_TYPE)
                    }
                    {/* {state.leave_type ? state.leave_type.short_name :state.lwp?"LWP": "Multi Leaves"} */}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5"> {(state.status == LEAVE_STATUS.PENDING && user.data._id != state.applied_by._id) ? "Action" : "Status"}</Typography>

                  {state.status == LEAVE_STATUS.PENDING && user.data._id != state.applied_by._id ? (
                    <>
                      <LeaveActionComponent
                        state={state}
                        setState={setState}
                        fieldLoading={fieldLoading}
                        onApprove={onApprove}
                        onReject={onReject}
                        loading={loading}
                        isFromModal={isFromModal}

                      />
                    </>
                  ) : (<Chip
                    size='small'
                    color={leaveStatusColored(state.status)}
                    label={findObjectKeyByValue(state.status, LEAVE_STATUS)}
                  />)
                  }

                  {

                  }

                </Grid>



                <Grid xs={12} item>
                  <Typography variant="h5">Reason</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.remarks}
                  </Typography>
                </Grid>

                <Grid xs={6} item>
                  <Typography variant="h5">From</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.from).format('DD-MM-YYYY')}
                    &nbsp;({findObjectKeyByValue(state.from_time_duration, HALF_TYPE_OBJ)})
                  </Typography>
                </Grid>

                {(state.leave_count > 1 || moment(state.from).format('DD-MM-YYYY') != moment(state.to).format('DD-MM-YYYY')) && <Grid xs={6} item>
                  <Typography variant="h5">To</Typography>
                  <Typography variant="h6" color="grey.main">
                    {moment(state.to).format('DD-MM-YYYY')}
                    &nbsp; ({findObjectKeyByValue(state.to_time_duration, HALF_TYPE_OBJ)})

                  </Typography>
                </Grid>}

                <Grid xs={12} item>
                  <Typography variant="h5">Total Leave</Typography>
                  <Typography variant="h6" color="grey.main">
                    {state.leave_count}
                  </Typography>
                </Grid>
                {state.status != LEAVE_STATUS.PENDING && <>

                  <Grid xs={6} item>
                    <Typography variant="h5">Actioned By:</Typography>
                    <Typography variant="h6" color="grey.main">
                      {state.actioned_by && (state.actioned_by.first_name + " " + state.actioned_by.last_name)}
                    </Typography>
                  </Grid>

                  <Grid xs={6} item>
                    <Typography variant="h5">Actioned Remarks:</Typography>
                    <Typography variant="h6" color="grey.main">
                      {(state.action_remarks)}
                    </Typography>
                  </Grid>
                </>
                }
                {
                  state.deducted_leave && state.deducted_leave.length > 0 && <>
                    <Grid xs={12} item>
                      <Box sx={{width:"100%"}} >
                        <Typography variant="h5">Deduction From:</Typography>
                        {/* <Table>
                          <TableHead sx={(theme) => ({ background: theme.palette.grey.light })}>
                            <TableRow>
                              <TableCell>Leave Type</TableCell>
                              <TableCell>Deducted from</TableCell>
                              <TableCell>Deduction </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.deducted_leave.map((item, index) => <TableRow key={index}  >
                              <TableCell> {
                                findObjectKeyByValue(item.type, LEAVE_TYPE)?.toUpperCase()
                              }</TableCell>
                              <TableCell>{moment(item.from).format("DD/MM/YYYY")}</TableCell>
                              <TableCell>{item.leave_count}</TableCell>
                            </TableRow>)}
                          </TableBody>
                        </Table> */}
                        <Grid container spacing={2} >
 {state.deducted_leave.map((item, index) => <LeavePreviewBox key={index} count={item.leave_count} date={item.from} type={item.type} />)}
 </Grid>
                      </Box>
                    </Grid>

                  </>
                }
              </Grid>




              {
              (state.status == LEAVE_STATUS.PENDING || user.data._id != state.applied_by._id) && (moment().valueOf() < moment(state.to).valueOf() || user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.hr) && 
              <SubmitButton
                sx={{ mt: 3, mb: 0 }}
                loading={fieldLoading.fieldName == 'delete'}
                onClick={onDelete}
                disabled={fieldLoading.loading}
                title={'Delete Leave'}
                color="error"
              />}
            </Box>
          </Box>
        </Box>
      )}
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      {loading && (
        <CenteredBox sx={{ minHeight: '300px' }}>
          <CircularProgress />
        </CenteredBox>
      )}
    </>
  )
}
export default LeaveDetailsUi
