import { useCallback, useState } from 'react'
import ReimberesementController from './ReimberesementController'
import { Box, Typography } from '@mui/material'
import { CenteredBox } from '../../components/layouts/common/boxes'
import UserSearchBar from '../../components/inputs/SearchBar'
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent'
import GenralReimberesementController from './GenralReimberesementController'
import { useDispatch, useSelector } from 'react-redux'
import { selectEmployee } from '../../store/actions/selectedEmployeeAction'

const EmployeeReimberesementController = () => {

  const dispatch = useDispatch()
  const { selectedEmployee, user } = useSelector(state => state)
  const onUserChange = useCallback((user) => {
    dispatch(selectEmployee(user))
  }, [])
  return (
    <>
      <Box mt={3} mb={3}>
        <UserSearchBar defaultVal={selectedEmployee.user} onUserChange={onUserChange} />
      </Box>
      {selectedEmployee.user && selectedEmployee.user != null && <BasicProfileDetailsComponent data={selectedEmployee.user} />}
      {selectedEmployee.user && (
        <ReimberesementController
          key={selectedEmployee.user._id}
          selectedUser={selectedEmployee.user}
          userId={selectedEmployee.user._id}
        />
      )}
      {!selectedEmployee.user && <GenralReimberesementController />}
    </>
  )
}
export default EmployeeReimberesementController
