import { useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { downloadUserProfilePdf } from "../../../apis/user.api";
import fileDownload from "js-file-download";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { CircularProgress, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";

const DownloadUserProfilePdfButton = ({ params }) => {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()



    const onClick = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await downloadUserProfilePdf({ userId: params._id }),
                (response) => {
                    setLoading(false);
                    fileDownload(response, params?.employee_id + ".pdf")
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        );
    }
    if (loading)
        return <CircularProgress />
    return <IconButton loading={loading} onClick={onClick} >
        <Download />
    </IconButton>
    // return <ActionButton active variant="contained" icon={<Download />} loading={loading} onClick={onClick} ></ActionButton>
}
export default DownloadUserProfilePdfButton