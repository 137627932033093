import axios from "axios"
import { getHeaders } from "../utils/helper"
import endpoints from "./endpoints"

// export const createAnnouncement = async (data) => {
//     const callResponse = await axios({
//       url: endpoints.createAnnouncement,
//       method: 'post',
//       headers: getHeaders(),
//       data,
//     })
//       .then((response) => response.data)
//       .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
//     return callResponse
//   }


// export const getAnnouncementsApi = async (params) => {
//   const callResponse = await axios({
//       url: endpoints.announcementFetchLatest,
//       method: "get",
//       headers: getHeaders(),
//       params,
//   })
//       .then(response => response.data)
//       .catch(err => ({status:0,response:err.response,code:err.response.status}));

//   return callResponse;
// };



// export const getFirstAnnouncementsApi = async (data) => {
//   const callResponse = await axios({
//       url: endpoints.  firstAnnouncementFetch,
//       method: "get",
//       headers: getHeaders(),
//       data,
//   })
//       .then(response => response.data)
//       .catch(err => ({status:0,response:err.response,code:err.response.status}));

//   return callResponse;
// };

// export const markAnnouncementAsReadApi = async (announcementId) => {
//   const callResponse = await axios({
//     url: endpoints.announcementMarkAsRead,
//     method: 'post',
//     headers: getHeaders(),
//     data: { announcementId },
//   })
//     .then(response => response.data)
//     .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

//   return callResponse;
// };


export const createAnnouncement = async (data) => {
  const callResponse = await axios({
    url: endpoints.createAnnouncement,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const getAnnouncementsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.announcementFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const getFirstAnnouncementsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.firstAnnouncementFetch,
    method: "get",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};


export const fetchLatestAnnouncementsApi = async () => {
  try {
    const response = await axios.get(endpoints.announcementFetchLatest, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (err) {
    console.error('Error fetching latest announcements:', err);
    return { status: 0, response: err.response, code: err.response.status };
  }
};

export const markAnnouncementAsReadApi = async (announcementId) => {
  try {
    const response = await axios.post(endpoints.announcementMarkAsRead, { announcementId }, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (err) {
    console.error('Error marking announcement as read:', err);
    return { status: 0, response: err.response, code: err.response.status };
  }
};