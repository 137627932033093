import React, { memo } from "react";
import DashboardAnnouncement from "./DashboardAnnouncement";
import LeaveBalance from "../leaves/LeaveBalance";
import { Box, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import DashboardLeaves from "./DashboardLeaves";
import DashboardBirthday from "./DashboardBirthdays";

import { BorderBottom, Co2Sharp } from "@mui/icons-material";
import DashboardReimburesement from "./DashboardReimburesement";
import DashboardButtons from "./DashboardButtons";

import LogDetailsController from "../logs/LogDetailsController";
import moment from "moment";

function DashboardUI({ user }) {
  const theme = useTheme()
  const isSmallView = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box>

      {isSmallView && <Box sx={{ width: "100%" }} mb={0} mt={3}>
        <DashboardButtons isMobile />
      </Box>}
      <Grid
        container
        spacing={3}
        marginTop={2}
      >

        <Grid item xs={12} md={8} order={2} >
          <Paper
            elevation={0}
            component={Box}
            sx={{ border: "1px" }}
            variant="outlined"
          >
            <DashboardLeaves direction="column" dashboard={true} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} order={1}>
          <LogDetailsController userId={user._id} date={moment().toISOString()} dashboard={true} />
        </Grid>


      </Grid>

      {!isSmallView && <Box sx={{ width: "100%" }} mt={3}>
        <DashboardButtons />
      </Box>}

      <Box sx={{ width: "100%" }} mt={2}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12} mt={2}>
            <DashboardAnnouncement />
          </Grid>

          <Grid item md={6} xs={12} mt={2}>
            <DashboardBirthday />
          </Grid>
        </Grid>

        <Grid
          container
          marginTop={3}
          direction="column"
          justifyContent="space-between"
        >

          <DashboardReimburesement />

        </Grid>
      </Box>
    </Box>


    // </Paper>
  );
}

export default memo(DashboardUI);
