import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getDailyUpdateByUser,
  getDailyUpdateDashboard,
  updateDailyUpdateStatus,
} from "../../apis/dailyupdate.api";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import EmployeeDailyUpdateUi from "./EmployeeDailyUpdateUi";
import { useDispatch, useSelector } from "react-redux";
import { DAILYUPDATE_STATUS, LEAVE_STATUS } from "../../utils/leave.constants";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { getUserByParentId } from "../../apis/user.api";
import { getMonthlyLeavesApi } from "../../apis/leave.api";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const EmployeeDailyUpdateController = () => {
  const [date, setDate] = useState(moment());
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [groupedEmployeeData, setGroupedEmployeeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchMoreloading, setFetchMoreloading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const dispatch = useDispatch();
  const [userClick, setUserClick] = useState([]);

  const [childUsers, setChildUsers] = useState([]);
  const [isReject, setIsReject] = useState(false);
  const [userCard, setUserCard] = useState({});
  const [selectedParentUser, setSelectedParentUser] = useState(null);
  const { user } = useSelector((state) => state);
  const [leaveOnDate, setLeaveOnDate] = useState([]);
  const abortControllerRef = useRef(null);

  const onApprove = (data) => {
    dispatch(
      callApiAction(
        async () =>
          await updateDailyUpdateStatus({
            ...data,
            status: DAILYUPDATE_STATUS.APPROVED,
            date,
          }),
        (response) => {
          fetchTasks();
          dispatch(callSnackBar("Daily Update Approved", "success"));
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const onReject = (data, userId, userName, index) => {
    console.log("this is onReject", data);
    dispatch(
      callApiAction(
        async () =>
          await updateDailyUpdateStatus({
            ...data,
          }),
        (response) => {
          // fetchTasks();
          // fetchChildUsersOnClick(userId, userName, index);
          setIsReject((prev) => !prev);
          dispatch(callSnackBar("Daily Update Rejected", "success"));
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const fetchDataForSelectedUser = async (userId) => {
    setLoading(true);
    try {
      // Fetch tasks for the selected user using getDailyUpdateByUser API
      const response = await getDailyUpdateByUser({
        user_id: userId,
        date: date.format("YYYY-MM-DD"),
      });

      // Extract leave data for the selected user
      const userLeaveData = response.data.leaveOnDate || [];

      // Group tasks by department
      const tasksByDepartment = response.data.data.reduce((acc, task) => {
        const departmentName = task.department
          ? task.department.name
          : "Unnamed Department";
        const departmentId = task.department?._id || null;

        if (!acc[departmentId]) {
          acc[departmentId] = {
            departmentName,
            departmentId,
            tasks: [],
          };
        }

        acc[departmentId].tasks.push(task);
        return acc;
      }, {});

      // Convert to an array of department-based entries
      const userEntries = Object.values(tasksByDepartment).map((dept) => ({
        _id: userId,
        tasks: dept.tasks,
        departmentName: dept.departmentName,
        departmentId: dept.departmentId,
      }));

      // Update state with the selected user's task data grouped by department
      setAllUsers((prevUsers) => {
        const updatedUsers = [...prevUsers];
        const existingUserIndex = updatedUsers.findIndex(
          (user) => user.parentUserId === userId
        );

        if (existingUserIndex !== -1) {
          // Update existing user's data
          updatedUsers[existingUserIndex] = {
            ...updatedUsers[existingUserIndex],
            childUsers: userEntries,
            leaveOnDate: userLeaveData, // Add leave data for the selected user
          };
        } else {
          // Append new user data
          updatedUsers.push({
            parentUserId: userId,
            parentUserName: selectedUser?.name || "User",
            childUsers: userEntries,
            leaveOnDate: userLeaveData, // Add leave data for the selected user
          });
        }

        return updatedUsers;
      });

      // Update the global leave data state
      setLeaveOnDate(userLeaveData);
    } catch (error) {
      console.error("Error fetching tasks for selected user:", error);
    } finally {
      setLoading(false);
    }
  };

  let usersQueue = [];
  let leaveData = [];

  const fetchChildUsersOnClick = async (userId, userName, index) => {
    setLoading(true);
    setFetchMoreloading(true);
    usersQueue.push({ userId });
    setAllUsers([]);
    const currentController = new AbortController(); // Create new AbortController
    abortControllerRef.current = currentController;
    try {
      while (usersQueue.length > 0) {
        const currentParent = usersQueue.shift();
        const childUsers = await fetchUserByParentId(
          currentParent.userId,
          currentController.signal
        );

        if (childUsers.length > 0) {
          const usersWithTasks = await Promise.all(
            childUsers.map(async (user) => {
              const response = await getDailyUpdateByUser({
                user_id: user._id,
                date: date.format("YYYY-MM-DD"),
                signal: currentController.signal,
              });
              leaveData.push(...response.data.leaveOnDate);

              const tasksByDepartment = response.data.data.reduce(
                (acc, task) => {
                  const departmentName = task.department
                    ? task.department.name
                    : "Unnamed Department";
                  const departmentId = task.department?._id || null;

                  if (!acc[departmentId]) {
                    acc[departmentId] = {
                      departmentName,
                      departmentId,
                      tasks: [],
                    };
                  }

                  acc[departmentId].tasks.push(task);
                  return acc;
                },
                {}
              );

              const userEntries = Object.values(tasksByDepartment).map(
                (dept) => ({
                  ...user,
                  tasks: dept.tasks,
                  departmentName: dept.departmentName,
                  departmentId: dept.departmentId,
                })
              );

              if (userEntries.length === 0) {
                userEntries.push({
                  ...user,
                  tasks: [],
                  departmentName: user.department?.name || "No Department",
                  departmentId: user.department?._id || null,
                });
              }

              usersQueue.push({ userId: user._id, userName: user.name });
              return userEntries;
            })
          );

          const flattenedUsersWithTasks = usersWithTasks.flat();
          setAllUsers((prev) => {
            const updatedUsers = [...prev];
            if (index !== undefined) {
              updatedUsers[index] = {
                parentUserId: currentParent.userId,
                parentUserName: currentParent.userName,
                childUsers: flattenedUsersWithTasks,
                leaveOnDate: leaveData,
              };
            } else {
              const existsIndex = updatedUsers.findIndex(
                (obj) => obj.parentUserId === currentParent.userId
              );

              if (existsIndex === -1) {
                updatedUsers.push({
                  parentUserId: currentParent.userId,
                  parentUserName: currentParent.userName,
                  childUsers: flattenedUsersWithTasks,
                  leaveOnDate: leaveData, // Include leave data
                });
              } else {
                updatedUsers[existsIndex] = {
                  parentUserId: currentParent.userId,
                  parentUserName: currentParent.userName,
                  childUsers: flattenedUsersWithTasks,
                  leaveOnDate: leaveData, // Include leave data
                };
              }
            }
            return updatedUsers;
          });
          setLoading(false);
        }
      }
      setLeaveOnDate(leaveData);
    } catch (error) {
      console.error("Error fetching users and tasks iteratively:", error);
    } finally {
      setLoading(false);
      setFetchMoreloading(false);
    }
  };

  const [debouncedDate, setDebouncedDate] = useState(date);
  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort previous request when date changes
    }

    const handler = setTimeout(() => {
      setDebouncedDate(date);
    }, 200);

    return () => {
      clearTimeout(handler);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Cleanup controller on unmount
      }
    };
  }, [date]);

  useEffect(() => {
    setAllUsers([]);
    if (selectedUser) {
      const currentController = new AbortController(); // Create new controller
      abortControllerRef.current = currentController;
      fetchDataForSelectedUser(selectedUser._id);
    } else if (user?.data?._id) {
      usersQueue = [];
      const currentController = new AbortController(); // Create new controller
      abortControllerRef.current = currentController;
      fetchChildUsersOnClick(user.data._id, user.data.name);
    }
  }, [debouncedDate, user?.data?._id, selectedUser, isReject]);

  const fetchTasks = async () => {
    if (!selectedUser) return;
    setLoading(true);
    try {
      const response = await getDailyUpdateByUser({
        user_id: selectedUser._id,
        date: date.format("YYYY-MM-DD"),
      });

      if (response && response.status !== 0) {
        setEmployeeData(Array.isArray(response.data) ? response.data : []);
      } else {
        setEmployeeData([]);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setEmployeeData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [selectedUser, date]);

  const fetchUserByParentId = async (parentId, signal) => {
    try {
      const response = await getUserByParentId(
        {
          parent_id: parentId,
        },
        signal
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching users by parent ID:", error);
      return [];
    }
  };

  return (
    <EmployeeDailyUpdateUi
      date={date}
      setDate={setDate}
      departments={departments}
      selectedDepartment={null}
      setSelectedDepartment={() => {}}
      groupedEmployeeData={groupedEmployeeData}
      loading={loading}
      onApprove={onApprove}
      onReject={onReject}
      fetchMoreloading={fetchMoreloading}
      //handleUserCardClick={handleUserCardClick}
      fetchChildUsersOnClick={fetchChildUsersOnClick}
      allUsers={allUsers}
      leaveOnDate={leaveOnDate}
      setAllUsers={setAllUsers}
    />
  );
};

export default EmployeeDailyUpdateController;
