
import { useDispatch } from "react-redux";
import { useState } from "react";
import { callApiAction } from "./../../store/actions/commonAction";
import { callSnackBar } from "./../../store/actions/snackbarAction";
import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS, SNACK_BAR_VARIETNS } from "./../../utils/constants";
import { downloadAdvancePaymentPdf, getReimberesementPdfApi } from "./../../apis/reimbersement.api";
import { ActionButton } from "./../../components/button/SubmitButton";
import { closeModal, openModal } from "./../../store/actions/modalAction";
import { Download } from "@mui/icons-material";
import fileDownload from "js-file-download";
import { CircularProgress, IconButton } from "@mui/material";

const DownloadAdvancePaymentPdfButton = ({ params }) => {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()



    const onClick = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await downloadAdvancePaymentPdf({ id: params._id }),
                (response) => {
                    setLoading(false);
                    fileDownload(response, params?.purpose + ".pdf")
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        );
    }
    if (params.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN)
        return <></>
    if (loading)
        return <CircularProgress />
    return <IconButton loading={loading} onClick={onClick} >
        <Download />
    </IconButton>
    // return <ActionButton active variant="contained" icon={<Download />} loading={loading} onClick={onClick} ></ActionButton>
}
export default DownloadAdvancePaymentPdfButton