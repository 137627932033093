import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { getUserApi, updateUserField } from "../../../apis/user.api";
import { useParams } from "react-router-dom";
import { openModal } from "../../../store/actions/modalAction";
import LeaveSummaryUi from "./LeaveSummaryUi";
import { getCoffApi, getLeavesApi } from "../../../apis/leave.api";
import moment from "moment";
import { LEAVE_STATUS } from "../../../utils/leave.constants";
import CoffViewButton from "./CoffViewButton";
import { findObjectKeyByValue } from "../../../utils/helper";
import { Box, Chip } from "@mui/material";
import { HALF_TYPE_OBJ } from "../../../utils/constants";
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker";

const CompOffSummaryController = ({ userId }) => {
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "createdAt",
        label: "Application Date",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          moment(params.createdAt ?? params.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "time_duration",
        label: "Time Duration",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          params.time_duration === HALF_TYPE_OBJ.FULL_DAY ? 1 : 0.5,
      },
      {
        id: 3,
        fieldName: "from",
        label: "Date",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => moment(params.date).format("DD/MM/YYYY"),
      },
      {
        id: 5,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        minWidth: "50px",
        renderValue: (params) => (
          <Chip
            color={
              params.status === LEAVE_STATUS.APPROVED
                ? "success"
                : params.status === LEAVE_STATUS.REJECTED
                ? "error"
                : "warning"
            }
            label={findObjectKeyByValue(params.status, LEAVE_STATUS)}
            size="small"
          />
        ),
      },
      {
        id: 6,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params) => <CoffViewButton id={params._id} />,
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    date: moment(),
    userId: userId,
  });

  const fetchList = () => {
    setListLoading(true);
    const paramsToPass = { ...filters };
    paramsToPass.date = paramsToPass.date.valueOf();

    dispatch(
      callApiAction(
        async () => await getCoffApi(paramsToPass),
        (response) => {
          if (response && response.result) {
            setList(response.result);
            setTotalCount(response.total);
          } else {
            setList([]);
            setTotalCount(0);
          }
          setListLoading(false);
        },
        (err) => {
          console.error("API Error:", err);
          setListLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <LeaveSummaryUi
      columns={columns}
      listLoading={listLoading}
      filters={filters}
      setFilters={setFilters}
      callBack={fetchList}
      list={list}
      totalCount={totalCount} // Pass the total count to the UI component
    />
  );
};

export default CompOffSummaryController;
