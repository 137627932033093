import * as React from "react";
import WishModal from "../../components/animations/WishModal";
import { useSelector } from "react-redux";
import moment from "moment";
import { lastBirthdayWishYear } from "../../utils/helper";

export default function BirthdayWishModal({}) {
  const { user } = useSelector((state) => state);

  const [allowed, setAllowed] = React.useState(false);
  React.useEffect(() => {
    const birthDate = moment(user.data.dob);

    const todaysDate = moment();

    if (
      todaysDate.get("date") == birthDate.get("date") &&
      todaysDate.get("month") == birthDate.get("month") &&
      todaysDate.get("year") != lastBirthdayWishYear.get()
    ) {
      setAllowed(true);
      lastBirthdayWishYear.set(todaysDate.get("year"));
    }
  }, []);
  if (!allowed) {
    return <></>;
  }
  return <WishModal subTitle={user.data.first_name} />;
}
