import {
  CalendarMonthOutlined,
  Circle,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'

import moment from 'moment'
import { useSelector } from 'react-redux'
import UserSearchBar from '../../components/inputs/SearchBar'
import { CenteredBox } from '../../components/layouts/common/boxes'
import CustomDatePicker from '../../components/layouts/common/CustomDatePicker'
import CalendarController from '../calendar/CalendarController'
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent'

import LeaveBalance from './LeaveBalance'
import LeaveButtons from './LeaveButtons'
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'
import GeneralLeaveController from './GeneralLeave.controller'

const EmployeeLeaveUi = ({

  date,
  setDate,
  onUserChange,
  callBack,
  defaultVal,
}) => {
  const { user } = useSelector((state) => state)
  return (
    <>
      <Box mt={3}>
        <UserSearchBar defaultVal={defaultVal} onUserChange={onUserChange} />
      </Box>
      {defaultVal && defaultVal != null && <BasicProfileDetailsComponent data={defaultVal} />}
      {defaultVal && defaultVal != null && (
        <>
          <Box mt={3} mb={3}>
            <Grid container columnSpacing={3} rowSpacing={3}>
              

              <Grid item xs={12}>

              <Box mb={3}>
                  <Grid container spacing={2}>
                    <Grid item md={10}><Box><CustomMonthPicker date={date} setDate={setDate} /></Box> </Grid>
                    <Grid item md={2}><LeaveButtons modal userId={defaultVal._id} /></Grid>


                  </Grid>
                </Box>
                
              </Grid>


              <Grid item xs={12}>
                <Box mb={3}>
              <LeaveBalance userId={defaultVal._id} date={date} />
              </Box>
                <CalendarController
                  userId={defaultVal._id}
                  weeklyOf={defaultVal.weekly_of}
                  calendarTimeObj={date && date.toDate()}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!defaultVal && (
        // <CenteredBox mt={4}>
        //   <Typography variant="h3" color="grey.main">
        //     No Employee Selected
        //   </Typography>
        // </CenteredBox>
        <GeneralLeaveController />
      )}
    </>
  )
}
export default EmployeeLeaveUi
