import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { getPlaceByIdApi, getVisitByIdApi } from "../../../apis/visits.api"
import { callApiAction } from "../../../store/actions/commonAction"
import PlaceDetailsUi from "./PlaceDetailsUi"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"

const PlaceDetailsController = ({ place_id }) => {
    const dispatch = useDispatch()

    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchList = async () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getPlaceByIdApi({ id:place_id }),
                async (response) => {
                    setLoading(false)
                    setList(response)

                },
                (err) => {
                    setLoading(false)
                    setList({})
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )

    }
    useEffect(() => {
        fetchList()
    }, [place_id])
    return <>

        <PlaceDetailsUi

            list={list}
            loading={loading}
        />

    </>
}
export default PlaceDetailsController