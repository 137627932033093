import { AccessAlarm, AccessTime, Business, Circle, East, Fastfood, Flag, FoodBank, FoodBankTwoTone, GppBad, GppMaybe, KeyboardArrowRight, Pause, PersonRemove, PlayArrow, Timer, West } from "@mui/icons-material"
import { ButtonBase, Chip, IconButton, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../assets/css/theme/common"
import { CALENDAR_ITEM_TYPES, CHECK_IN, HALF_TYPE_OBJ, LEAVE_COLORS, REQUESTS_STATUS } from "../../utils/constants"
import { CenteredBox } from "../../components/layouts/common/boxes"
import moment from "moment"
import { LEAVE_STATUS } from "../../utils/leave.constants"
import { calculateWorkingHours } from "../../utils/helper"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import LogDetailsController from "../logs/LogDetailsController"
import WFHDetailsController from "../wfh/WFHDetailsController"
import { memo, useEffect, useState } from "react"
import LateCheckinController from "../leaves/LateCheckinController"





const LeaveViewBox = styled(ButtonBase)(({ theme, color, background }) => ({


    position: "relative",
    zIndex: 1,
    width: "100%",

    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: '100%',
        width: "100%",
        background: background ?? color,
        // opacity: 0.2,
        zIndex: 0,

    },
    "*": {
        color: color + "!important"
    }
}))


const LogsComponent = ({ data, date, weekDay, weeklyOf, forEmployee, userId, ...props }) => {
    const dispatch = useDispatch()
    const { user, selectedEmployee } = useSelector(state => state)
    const { hours,
        minutes,
        lastCheckout,
        firstCheckIn,
        checkInLocation,
        checkoutLocation,
        checkedOut,
        breakHours,
        breakMinutes
    } = calculateWorkingHours(data)
    function checkLogType(data) {
        return data.type == 'checkInLog'
    }

    const checkInLogs = data && Array.isArray(data) && data.filter(checkLogType)
    const checkIn = checkInLogs[0] && checkInLogs[0]?.checkIn
    const loggedUser = checkInLogs[0] && checkInLogs[0]?.userId
    const logId = checkInLogs[0] && checkInLogs[0]?.logId
    const start = checkInLogs[0] && checkInLogs[0]?.start
    const logMonth = checkInLogs[0] && moment(checkInLogs[0]?.logTime).format("MM")
    const currentMonth = moment().format("MM")
    const workedHours = (hours > 0 || minutes > 0) ? `${hours}H ${minutes}M` : null

    const breakTimeHours = (breakHours > 0 || breakMinutes > 0) ? `${breakHours}H ${breakMinutes}M` : null

    const onClick = () => {
        dispatch(openModal({ title: "User Logs", component: <LogDetailsController userId={userId} date={data?.[0].logTime} /> }))
    }

    const openCheckinModal = () => {
        dispatch(openModal({
            title: 'Late Check-in Action',
            component: <LateCheckinController userId={userId} id={logId} start={start} />,
            size: "sm",
        }))
    }

    return <Box sx={{ display: "flex", flexDirection: "column", flex: 1, zIndex: 1 }} p={2} mb={2}>
        {

            data && Array.isArray(data) ? <>
               
                {
                    firstCheckIn &&
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ButtonBase onClick={onClick} mb={1}>
                            <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                <East fontSize="inherit" />
                                &nbsp;{moment(firstCheckIn).format("HH:mm")}
                            </Typography>
                        </ButtonBase>
                        {checkInLocation && <CenteredBox label={checkoutLocation} color="success" size="small" sx={{backgroundColor:"success.main",color:"light.main",fontSize:"8px",letterSpacing:"0.5px",borderRadius:"100px"}} ml={1} px={1}  >{checkInLocation}</CenteredBox>
                            
                        }

                        {forEmployee ? selectedEmployee?.user?.in_office : user?.data?.in_office && <>
                        <>
                            {checkIn && checkIn == CHECK_IN.LATE_CHECKIN && user.data?._id != loggedUser && logMonth == currentMonth &&
                                <ButtonBase onClick={openCheckinModal} mb={1}>
                                    <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                        <Tooltip title="this icon denotes that you have  checked in late" >
                                            <GppMaybe color="warning" lineHeight="inherit" fontSize="inherit" />
                                        </Tooltip>
                                    </Typography>
                                </ButtonBase>
                            }
                            {checkIn && checkIn == CHECK_IN.LATE_CHECKIN && user.data?._id != loggedUser && logMonth != currentMonth &&
                                <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                    <Tooltip title="this icon denotes that late check-in action cannot be performed" >
                                        <GppMaybe color="warning" lineHeight="inherit" fontSize="inherit" />
                                    </Tooltip>
                                </Typography>
                            }
                            {checkIn && checkIn == CHECK_IN.LATE_CHECKIN && user.data?._id == loggedUser &&
                                <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                    <Tooltip title="this icon denotes that you have  checked in late" >
                                        <GppMaybe color="warning" lineHeight="inherit" fontSize="inherit" />
                                    </Tooltip>
                                </Typography>
                            }
                            {checkIn && checkIn == CHECK_IN.LATE_CHECKIN_IGNORED &&
                                <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                    <Tooltip title="this denotes that late check-in is ignored" >
                                        <GppBad lineHeight="inherit" fontSize="inherit" />
                                    </Tooltip>
                                </Typography>
                            }
                            {checkIn && checkIn == CHECK_IN.LEAVE_DEDUCT &&
                                <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                                    <Tooltip title="this denotes that Half Leave is deducted due to late check-in " >
                                        <GppBad color="error" lineHeight="inherit" fontSize="inherit" />
                                    </Tooltip>
                                </Typography>
                            }
                            </>
                        </>}
                    </Box >
                    // <>
                    // <ButtonBase onClick={onClick} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mb: 1 }} mb={1}>
                    //     <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                    //         <East fontSize="inherit" />

                    //         &nbsp;{moment(firstCheckIn).format("HH:mm")}

                    //     </Typography>
                    //     {checkInLocation &&
                    //         <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1" ml={1}>
                    //             <Tooltip title={checkInLocation}><Business ml={1} fontSize="inherit" color="success" /></Tooltip>
                    //         </Typography>
                    //     }
                    // </ButtonBase>
                    // <ButtonBase onClick={openCheckinModal} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mb: 1 }} mb={1}>
                    //     {/* {checkIn && checkIn.checked_in_status == 1 && */}
                    //         <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                    //             <Circle color="error" lineHeight="inherit" fontSize="inherit" />
                    //         </Typography>
                    //     {/* } */}
                    // </ButtonBase>
                    // </>

                }
                

{
    lastCheckout &&
        <ButtonBase onClick={onClick} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mb: 1 }} mb={1}>
            <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
                <West fontSize="inherit" />
                &nbsp;{moment(lastCheckout).format("HH:mm")}
            </Typography>
            {/* {checkoutLocation &&
                <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1" ml={1}>
                    <Tooltip title={checkoutLocation}><Business ml={1} fontSize="inherit" color="success" /></Tooltip>
                </Typography>
            } */}
             {checkoutLocation && <CenteredBox  color="success" size="small" sx={{backgroundColor:"success.main",color:"light.main",fontSize:"8px",letterSpacing:"0.5px",borderRadius:"100px"}} ml={1} px={1}  >{checkoutLocation}</CenteredBox>
                            
                        }
        </ButtonBase>
}
{
    workedHours &&
    <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
            {<AccessAlarm lineHeight="inherit" fontSize="inherit" />}

        </Typography>
        <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
            &nbsp;{workedHours}{
                firstCheckIn && !checkedOut && <Tooltip title="this icon denotes that you have not checked out" ><Flag color="error" lineHeight="inherit" fontSize="inherit" /></Tooltip>

            }

        </Typography>
    </Box>

}
{
    breakTimeHours &&
    <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
            {<Fastfood lineHeight="inherit" fontSize="inherit" />}

        </Typography>
        <Typography lineHeight="100%" sx={{ color: "#393939 !important" }} variant="subtitle1">
            &nbsp;{breakTimeHours}

        </Typography>
    </Box>

}

            </> : <></>
        }
    </Box >
}


const EmployeeLeaveComponent = ({ data, date, weekDay, weeklyOf, ...props }) => {

    const fetchBgColor = (status) => {
        let bgColor = LEAVE_COLORS.HOLIDAY
        let color = 'white'
        if (status == LEAVE_STATUS.PENDING) {
            bgColor = "gray"

        }
        if (status == LEAVE_STATUS.APPROVED) {
            bgColor = "#008000"

        }
        if (status == LEAVE_STATUS.REJECTED) {
            bgColor = "red"

        }
        return {
            bgColor,
            color
        }
    }


    return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", flex: 1 }} pt={5}  >
        {
            data && Array.isArray(data) && data.map((item) => {


                const { bgColor, color } = fetchBgColor(item.status)
                return (item.type == CALENDAR_ITEM_TYPES.leave ? (
                    <LeaveViewBox onClick={item.onClick} key={item.title} color={color} background={bgColor}>

                        <Typography align="center" color="dark.main" sx={{ zIndex: 1 }} variant="caption">{item.applied_by?.first_name?.slice(0, 10) ?? "NA"}{item.applied_by?.first_name?.length > 10 + ".."}</Typography>

                    </LeaveViewBox>
                ) : (<>
                    <LeaveViewBox onClick={item.onClick} key={item.title} color={color} background={bgColor}>

                        <Typography align="center" color="dark.main" sx={{ zIndex: 1 }} variant="caption">{item.title}</Typography>

                    </LeaveViewBox>
                </>))
            })
        }
    </Box>
}

const WFHComponent = memo(({ id, status, date, duration }) => {

    const [statusVal, setStatusVal] = useState(status)
    const dispatch = useDispatch()
    useEffect(() => {

        console.log(date, statusVal)
    }, [statusVal])
    const onClick = (id) => {
        dispatch(openModal({
            title: "WFH Details",
            component: <WFHDetailsController callBack={(statusRes) => {
                setStatusVal(statusRes)
                console.log('statusRes', statusRes)
                
            }} id={id} 
            isFromModal={true}/>,
        
        }))
        
    }



    return <Box sx={{ width: "100%", display: "flex" }}>
        {
            duration == HALF_TYPE_OBJ.SECOND_HALF && <Box sx={{ display: "flex", flex: 1 }} />
        }
        <Box sx={{ display: "flex", flex: 1 }}>
            <LeaveViewBox
                onClick={() => onClick(id)}
                color="white"
                key={statusVal}

                background={statusVal == REQUESTS_STATUS.APPROVED ? "green" : (statusVal == REQUESTS_STATUS.REJECTED ? "red" : "gray")}

            >
                <Typography variant="caption" sx={{ zIndex: 1 }} >
                    WFH
                </Typography>
            </LeaveViewBox>
        </Box>
        {
            duration == HALF_TYPE_OBJ.FIRST_HALF && <Box sx={{ display: "flex", flex: 1 }} />
        }
    </Box>
})
const WFHList = memo(({ data, date }) => {

    return data.map(item => <WFHComponent date={date} key={item._id} id={item._id} duration={item.duration} status={item.status} />) ?? <></>

})

const SmallCalendarButton = styled(ButtonBase)(({ theme }) => ({
    height: "35px",
    width: "35px",
    borderRadius: "100%",
    lineHeight: "100%"

}))
const MobileViewDesktop = ({ data, date, weekDay, weeklyOf, forEmployee, userId, isMobile, onClick, isHoliday, ...props }) => {
    return <CenteredBox sx={{ width: "100%", flexDirection: "column" }} p={1} >
        <SmallCalendarButton sx={{ background: isHoliday ? "#D58C37" : "unset", color: isHoliday ? "#fff" : "unset" }}


            onClick={onClick}>

            {date}
        </SmallCalendarButton>
        <CenteredBox   >
            {
                data.filter(item => item.type === CALENDAR_ITEM_TYPES.leave || item.type === CALENDAR_ITEM_TYPES.coff).map((item) => {
                    return <Box onClick={item.onClick} key={item.title} sx={{ height: "6px", width: '6px', background: item.background, borderRadius: "100%" }} >
                        {/* <Typography align="center" color="light.main" sx={{ zIndex: 1 }} variant="caption">{item.title}</Typography> */}
                    </Box>
                })
            }
        </CenteredBox>
    </CenteredBox>
}





const CalendarBox = ({ data, date, weekDay, weeklyOf, forEmployee, userId, isMobile, ...props }) => {

    const holiday = data.find((item) => item.type == CALENDAR_ITEM_TYPES.holiday)
    const isCompanyHoliday = !!holiday
    const isWeeklyOf = weeklyOf.includes(weekDay)

    if (forEmployee) {
        return <EmployeeLeaveComponent data={data} date={date} weekDay={weekDay} weeklyOf={weeklyOf} {...props} />
    }

    if (isMobile) {
        return <MobileViewDesktop

            data={data}
            date={date}
            weekDay={weekDay}
            weeklyOf={weeklyOf}
            forEmployee={forEmployee}
            userId={userId}
            {...props}
            isHoliday={isWeeklyOf || isCompanyHoliday}

        />
    }
    return (
        <Box sx={{ height: "100%", width: "100%", flexDirection: "column", display: "flex", flex: 1 }}>


            <LogsComponent userId={userId} data={data} date={date} weekDay={weekDay} weeklyOf={weeklyOf} forEmployee={forEmployee}  {...props} />

            <Box>

                {
                    data && Array.isArray(data) && data.map((item) =>
                        ((item.type != CALENDAR_ITEM_TYPES.checkInLog &&
                            item.type != CALENDAR_ITEM_TYPES.checkOUTLog) &&
                            (item.type == CALENDAR_ITEM_TYPES.coff || item.type == CALENDAR_ITEM_TYPES.leave))
                            ?
                            (
                                // (isWeeklyOf || (isCompanyHoliday && isCompanyHoliday == true)) &&
                                //     item.type == CALENDAR_ITEM_TYPES.leave ?
                                //     <></>
                                //     :
                                ((item.isStarting && item.fromDuration != HALF_TYPE_OBJ.FULL_DAY) || (item.isEnding && item.toTimeDuration != HALF_TYPE_OBJ.FULL_DAY)) ? <Box sx={{ display: "flex" }}>
                                    {(item.toTimeDuration == HALF_TYPE_OBJ.SECOND_HALF || item.fromDuration == HALF_TYPE_OBJ.SECOND_HALF) &&
                                        <Box sx={{ display: "flex", flex: 1 }}>

                                        </Box>}
                                    <Box sx={{ display: "flex", flex: 1 }}>
                                        <LeaveViewBox onClick={item.onClick} key={item.title} background={item.background} color={item.color ?? "grey"} >
                                            <Typography align="center" color="light.main" sx={{ zIndex: 1 }} variant="caption">{item.title}</Typography>
                                        </LeaveViewBox>
                                    </Box>
                                    {(item.toTimeDuration == HALF_TYPE_OBJ.FIRST_HALF || item.fromDuration == HALF_TYPE_OBJ.FIRST_HALF) && <Box sx={{ display: "flex", flex: 1 }}>

                                    </Box>}

                                </Box> :
                                    <LeaveViewBox onClick={item.onClick} key={item.title} background={item.background} color={item.color ?? "grey"}>
                                        <Typography align="center" color="light.main" sx={{ zIndex: 1 }} variant="caption">{item.title}</Typography>
                                    </LeaveViewBox>
                            ) : (<></>))
                }
            </Box>


            {
                // for weekly off & holidaY 
            }
            {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 1, background: "#D58C37", width: "100%", ...center }}>
                <Typography color="light.main" align="center" variant="caption"> {isCompanyHoliday ? holiday.title : 'WO'}</Typography>
            </Box>}
            {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 0, top: "0px", width: "100%", position: "absolute", height: "100%", width: "100%", left: "0px", background: '#f1f1f1', ...center }}>

            </Box>}
            <WFHList userId={userId} date={date} data={data?.filter(item => item.type == CALENDAR_ITEM_TYPES.wfh) ?? []} />
        </Box>
    )
}
export default memo(CalendarBox)