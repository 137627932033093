
import { CircularProgress, FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux";
import { useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { updateUserState } from "../../../apis/user.api";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";

const UpdateActiveUserButton = ({ params, setParams }) => {

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(params.enabled)
    const dispatch = useDispatch()


    const onStatusChange = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateUserState({ userId: params._id }),
                (response) => {

                    setLoading(false);
                    setActive(!active)
                    dispatch(callSnackBar("User active status changed.", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("User active status cant be changed.", SNACK_BAR_VARIETNS.error))
                }
            )
        );
    };
    return <>
        {loading ? <CircularProgress /> :
            <FormControlLabel
                sx={{
                    display: "block",
                }}
                control={
                    <Switch
                        checked={active}
                        value
                        name="loading"
                        color="primary"
                        onClick={(e) => {
                            onStatusChange();
                        }}
                    />
                }
            />}
    </>
}
export default UpdateActiveUserButton