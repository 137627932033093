import { Box, Button, ButtonGroup, Paper, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"

import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import CreateAdvancePaymentController from "./CreateAdvancePaymentController";
import DataTable from "../../components/tables/DataTable";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";
import CustomInput from "../../components/inputs/CustomInput";
import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS } from "../../utils/constants";
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";

// const AdvancePaymentsUI = ({filters, setFilters, callBack, loading, state, columns }) => {
//     const dispatch = useDispatch();
//     const addholiday = useCallback(() => {
//         dispatch(openModal({
//             title: "Add Advance Payment",
//             component: <CreateAdvancePaymentController callBack={callBack} />, size: 'sm'
//         }))
//     }, [])
//     return <>

//         <Box component={Paper} p={3} mt={3}>
//             <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//                 <Typography sx={{
//                     fontWeight: 700,
//                     fontSize: "24px",
//                     lineHeight: "40px",
//                     color: "#0D0D0D"
//                 }}>Advance Payments</Typography>
//                 <Box>
//                     <Button size="small" onClick={addholiday} variant="contained">
//                         <Add color="light" /> &nbsp;Add Payment
//                     </Button>
//                 </Box>
//             </Box>
//             <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
//                 <Box>
//                     <ButtonGroup disableElevation >
//                         <Button variant={filters.status == null ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: null })} >All</Button>
//                         <Button variant={filters.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN })} >OPEN</Button>
//                         <Button variant={filters.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE })} >CLOSE</Button>
//                     </ButtonGroup>
//                 </Box>
//                 <Box sx={{ width: "100%", maxWidth: "300px" }} >
//                     <AsyncDropDown
//                         InputComponent={memo((props) => <CustomInput label="Select Employee*" placeholder="Select Employee"  {...props} size="small" />)}
//                         lazyFun={async (props) => {
//                             return await getUserApi({ ...props, searchable: ['first_name', 'last_name'] })
//                         }}
//                         label="Employee*"
//                         onChange={async (changedVal) => {
//                             setFilters({ ...filters, err: '', userId: changedVal._id })
//                         }}
//                         titleKey={'name'}
//                         valueKey={"_id"}
//                         OptionComponent={({ option, ...rest }) => {
//                             return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
//                                 <Typography ml={3} variant="h5">{option.name}</Typography>
//                             </Box>
//                         }}

//                     />
//                 </Box>
//             </Box>
//             <Box sx={{ minHeight: "300px" }} mt={3}>
//                 <DataTable columns={columns} rows={state?.result ?? []} count={state?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
//             </Box>
//         </Box>
//     </>
// }
// export default AdvancePaymentsUI


const AdvancePaymentsUI = ({ filters, setFilters, callBack, loading, state, columns }) => {
    const dispatch = useDispatch();

    const addHoliday = useCallback(() => {
        dispatch(openModal({
            title: "Add Advance Payment",
            component: <CreateAdvancePaymentController callBack={callBack} />, size: 'sm'
        }));
    }, [dispatch, callBack]);

    return (
        <>
            <Box component={Paper} p={3} mt={3}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{
                        fontWeight: 700,
                        fontSize: "24px",
                        lineHeight: "40px",
                        color: "#0D0D0D"
                    }}>Advance Payments</Typography>
                    <Box>
                        <Button size="small" onClick={addHoliday} variant="contained">
                            <Add color="light" /> &nbsp;Add Payment
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                    <CustomMonthPicker 
                        date={filters.date} 
                        setDate={(date) => setFilters({ ...filters, date })} 
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                    <Box>
                        <ButtonGroup disableElevation>
                            <Button variant={filters.status == null ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: null })}>All</Button>
                            <Button variant={filters.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.OPEN })}>OPEN</Button>
                            <Button variant={filters.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE ? "contained" : "outlined"} onClick={() => setFilters({ ...filters, status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE })}>CLOSE</Button>
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ width: "100%", maxWidth: "300px" }}>
                        <AsyncDropDown
                            InputComponent={memo((props) => <CustomInput label="Select Employee*" placeholder="Select Employee" {...props} size="small" />)}
                            lazyFun={async (props) => await getUserApi({ ...props, searchable: ['first_name', 'last_name'] })}
                            label="Employee*"
                            onChange={async (changedVal) => setFilters({ ...filters, userId: changedVal._id })}
                            titleKey={'name'}
                            valueKey={"_id"}
                            OptionComponent={({ option, ...rest }) => (
                                <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                    <Typography ml={3} variant="h5">{option.name}</Typography>
                                </Box>
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={{ minHeight: "300px" }} mt={3}>
                    <DataTable columns={columns} rows={state?.result ?? []} count={state?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
            </Box>
        </>
    );
};

export default AdvancePaymentsUI;