import React, { useEffect, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import AddDepartmentUI from "./AddDepartmentUI";
import { fetchDepartments, createDepartment } from "../../apis/department";
import { closeModal } from "../../store/actions/modalAction";
import { fetchDepartmentDataAction } from "../../store/actions/settingsAction";

const AddDepartmentContorller = ({callBack}) => {
  
  const dispatch = useDispatch();
  const createDepartmentApi = createDepartment;
  const [loading, setLoading] = useState(false);
  const [parentId, setParentId] = useState("");
  const [parentName, setParentName] = useState("");
  const [name, setName] = useState("");
  const [err, setErr] = useState("");
  const [string, setString] = useState("");
  const [departments, setDepartmens] = useState("");
  const fetchDepartmentsApi = fetchDepartments;
  const {settings} = useSelector((state) => state)

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchDepartmentsApi({ string }),
        (response) => {
          setDepartmens(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const addDepartMent = (e) => {
    e.preventDefault()
    setLoading(true);
    const data = { name };
    if (parentId) data.parentId = parentId;
    dispatch(
      callApiAction(
        async () => await createDepartmentApi(data),
        (response) => {
          callBack()
          setLoading(false);
          dispatch(fetchDepartmentDataAction(settings.department_filters))
          dispatch(closeModal())
        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <AddDepartmentUI
      loading={loading}
      addDepartMent={addDepartMent}
      parentName={parentName}
      setParentName={setParentName}
      setName={setName}
      name={name}
      err={err}
      setErr={setErr}
      departments={departments}
      setString={setString}
      setParentId={setParentId}
    />
  );
};

export default AddDepartmentContorller;
