import React, { useState, useEffect, useId } from "react";

import { useDispatch } from "react-redux";
import { updateUserField, updateUserPass } from "../../../apis/user.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { closeModal } from "../../../store/actions/modalAction";
import { SNACK_BAR_VARIETNS, USER_UPDATE_TYPE } from "../../../utils/constants";

import { getUserByIdApi } from "../../../apis/user.api";
import UpdateUserPasswordUI from "./UpdateUserPasswordUI";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { validatePassword } from "../../../utils/helper";

const UpdateUserPasswordController = ({ userId, list }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const userUpdateApi = updateUserField
    const userGet = getUserByIdApi
    const [id, setId] = useState(userId)
    const [fields, setFields] = useState(
        {
            err: '',
            id: userId,
            password: ""
        }
    );



    const onsubmit = () => {


        if (!validatePassword(fields.password)) {
            dispatch(callSnackBar("Password must be atlist 8 character long and must contain atlist one special character,one digit & One capital letter.", SNACK_BAR_VARIETNS.error))
            return
        }
        setLoading(true)

        dispatch(
            callApiAction(
                async () => await updateUserPass({ ...fields }),
                async (response) => {
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                    console.log(err)
                }
            )
        )
    }


    return <UpdateUserPasswordUI id={id} loading={loading} formValues={fields} setFormValues={setFields} onsubmit={onsubmit} />
}

export default UpdateUserPasswordController;