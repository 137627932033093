import { actions } from "../../utils/constants"

const initialState = {
    advance_payment_loading : false,
    advance_payment_data : {},
    advance_payment_filters: {},

    pending_loading : false,
    pending_data : {},
    pending_filters : {},

    count_loading : false,
    count_data : [],
    count_filters: {},

    personal_reimbursement_loading : false,
    personal_reimbursement_data : {},
    personal_reimbursement_filters : {},
}



const reimbursementReducer = (state = initialState, action) => {
    switch(action.type) {

        case actions.START_REIMBURSEMENT_ADVANCE_PAYMENT_LOADING : return {...state, advance_payment_loading : true, advance_payment_data : []};
        case actions.SET_REIMBURSEMENT_ADVANCE_PAYMENT_DATA : return {...state, advance_payment_loading: false, advance_payment_data: action.value.data, advance_payment_filters : action.value.filters};

        case actions.START_REIMBURSEMENT_PENDING_LOADING : return {...state, pending_loading : true, pending_data : []};
        case actions.SET_REIMBURSEMENT_PENDING_DATA : return {...state, pending_loading: false, pending_data: action.value.data, pending_filters : action.value.filters};
        
        case actions.START_REIMBURSEMENT_COUNT_LOADING : return {...state, count_loading : true, count_data : []};
        case actions.SET_REIMBURSEMENT_COUNT_DATA : return {...state, count_loading: false, count_data: action.value.data, count_filters : action.value.filters};

        case actions.START_REIMBURSEMENT_PERSONAL_LOADING : return {...state, personal_reimbursement_loading : true, personal_reimbursement_data : []};
        case actions.SET_REIMBURSEMENT_PERSONAL_DATA : return {...state, personal_reimbursement_loading: false, personal_reimbursement_data: action.value.data, personal_reimbursement_filters : action.value.filters};
        
        default : return {...state};
    }
}
 
export default reimbursementReducer