import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const PmsDetailUi = ({ pmsDetail, loading }) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!pmsDetail || !pmsDetail.monthly_task) {
    return (
      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        <Typography variant="h3">No PMS details available</Typography>
      </Box>
    );
  }
  const employeeName = pmsDetail.user_id?.first_name || 'Employee';
  return (
    <Box>
      
      {/* <Typography mb={2} fontWeight="bold" variant="h5">
        {employeeName} Monthly Task
      </Typography> */}
      <Box p={2} border={1} borderRadius={1} borderColor="grey.300"  backgroundColor={'grey.200'}>
        <Typography variant="body1">{pmsDetail.monthly_task}</Typography>
      </Box>
    </Box>
  );
};

export default PmsDetailUi;
