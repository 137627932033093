// import {  useEffect, useMemo, useState } from "react"
// import ReimberesementPageUI from "./ReimberesementPageUI"
// import { useDispatch } from 'react-redux'
// import { callApiAction } from "../../store/actions/commonAction"
// import { useParams } from "react-router-dom"
// import { openModal } from "../../store/actions/modalAction"
// import { getReimberesementApi, getReimberesementCountApi } from "../../apis/reimbersement.api"
// import moment from "moment"
// import MonthlyReimburesementPdfController from "./MonthlyReimburesementPdfController"
// import { REIMBURSEMENT_STATUS, SNACK_BAR_VARIETNS } from "../../utils/constants"
// import { findObjectKeyByValue, toTitleCase } from "../../utils/helper"
// import { Box, Chip } from "@mui/material"
// import ReimburesmentViewMoreButton from "./actionButtons/ReimburesementViewMoreButton"
// import ReimburesementApprovalButton from "./actionButtons/ReimburesmentApprovalButtons"

// const ReimberesementController = ({ userId, selectedUser = {} }) => {

//     const [list, setList] = useState({})


//     const [loading, setLoading] = useState(false)
//     const [counts, setCounts] = useState({})

//     const [listLoading, setListLoading] = useState(false)

//     const dispatch = useDispatch()
//     const params = useParams()

//     const columns = useMemo(() => [
//         { id: 1, fieldName: 'expenseDate', label: 'Expense Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.expenseDate).format("DD/MM/YYYY") },
//         { id: 2, fieldName: 'type', label: 'Expense Type', align: "left", renderValue: (params) => toTitleCase(params.type) },
//         { id: 3, fieldName: 'amount', label: 'Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.amount },
//         { id: 4, fieldName: 'purpose', label: 'Purpose', align: "left" },
//         {
//             id: 5, fieldName: 'status', label: 'Status', align: "left", renderValue: (params) => <Chip
//                 size="small"
//                 label={findObjectKeyByValue(params.status, REIMBURSEMENT_STATUS)}
//                 color={(params.status == REIMBURSEMENT_STATUS.approved||params.status == REIMBURSEMENT_STATUS.paid) ? "success" : (params.status == REIMBURSEMENT_STATUS.rejected ? "error" : "warning")}
//             />,
//             sort: true
//         },
//         {
//             id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex",alignItems:"center" }}>
//                 <ReimburesmentViewMoreButton id={params._id} />
//                 <ReimburesementApprovalButton params={params} setParams={setParams} />
//             </Box>
//         },
//     ], [dispatch]);

//     const [filters, setFilters] = useState({
//         pageNo: 1,
//         pageSize: 25,
//         search: '',

//         date: moment(),
//         userId: userId ?? '',
//         status: null

//     })

//     const fetchList = () => {

//         setLoading(true)

//         const passFilters = { ...filters }
//         passFilters['date'] = passFilters['date'].toISOString()

//         dispatch(callApiAction(
//             async () => await getReimberesementCountApi({ ...passFilters }),
//             (response) => {
//                 setCounts(response)
//                 setLoading(false)
//             },
//             (err) => {
//                 setLoading(false)
//             }
//         ))
//         dispatch(callApiAction(
//             async () => await getReimberesementApi({ ...passFilters }),
//             (response) => {
//                 setList(response)
//                 setListLoading(false)
//             },
//             (err) => {
//                 setListLoading(false)
//             }
//         ))
//     }



//     // const onCreateBtnClick = () => {
//     //     dispatch(openModal(<ReimberesementCreateController userId={userId} callBack={async () => { fetchList() }} />, "sm"))
//     // }

//     useEffect(() => {
//         fetchList()

//     }, [filters])

   

//     const onMonthlyPdfDownload = () => {

//         dispatch(openModal(
//             {
//                 title: "Download Monthly Data",
//                 component: <MonthlyReimburesementPdfController

//                     userId={userId}
//                     selectedUser={selectedUser}
//                     date={filters.date}
//                 />,
//                 size: "lg"
//             }
//         ))
//     }
//     return (
//         <>
//             <ReimberesementPageUI
//             callBack={fetchList}
//                 columns={columns}
//                 onMonthlyPdfDownload={onMonthlyPdfDownload}
//                 counts={counts}
//                 selectedUser={selectedUser}
//                 listLoading={listLoading}
//                 filters={filters}
//                 setFilters={setFilters}
//                 loading={loading}
//                 list={list}
//                 isForEmployee={userId}

//             />

//         </>
//     )
// }
// export default ReimberesementController
import React from 'react'
import DashboardUI from './DashboardUI'
import { useSelector } from 'react-redux';
function DashboardController() {
  const user = useSelector((state) => state.user)
  return (
    <DashboardUI
    user={user}
    />
  )
}

export default DashboardController;