import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { useState } from "react";
//import XLSX from 'xlsx'

const UploadSheetUI = ({ loading,onSubmit, date, setDate, data, setData, file, setFile, uploadSheet, err, setErr }) => {



    const [formData, setFormData] = useState()



    return (
        <>
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    position: "relative",
                }}
                maxHeight="100%"
            >
                
                    {
                        loading && <CenteredBox><CircularProgress /></CenteredBox>
                    }
                    {
                        err && <Typography variant="h5" color="error">{err}</Typography>
                    }
                    {
                        !loading && <>
                            <Box sx={{ width: "100%" }}>
                                <Box>
                                    <Typography fontWeight={500} variant="h3"> CSV : </Typography>
                                </Box>
                                <Box sx={{ marginTop: "7px" }}>
                                    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            <CustomInput
                                                disabled={loading}
                                                inputProps={{ accept:".csv"}}
                                            
                                                onChange={uploadSheet}
                                                type="file"
                                                placeholder="Upload Sheet"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: "10px", width: "100%" }}>
                                <Box>
                                    <Typography fontWeight={500} variant="h3"> Appraisal Letter Year : </Typography>
                                </Box>
                                <Box sx={{ marginTop: "7px" }}>
                                    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            {/* <DatePicker
                                                inputFormat="YYYY"
                                                onChange={(newValue) => {setDate(new Date(newValue).toISOString());}}
                                                value={date.year}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ width: "100%" }}
                                                        error={false}
                                                    />
                                                )}
                                            /> */}
                                            <DatePicker
                                            inputFormat="YYYY"
                                            onChange={(newValue) => {
                                                console.log()
                                                setDate({ ...date, year: newValue });
                                            }}
                                            value={date.year}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ width: "100%" }}
                                                    error={false}
                                                />
                                            )}
                                        />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                
                
                    <Box sx={{ float: "right", marginTop: "7px" }}>
                        <Button
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            Preview Sheet
                        </Button>
                    </Box>
                
            </Box>
        </>
    );
};

export default UploadSheetUI;