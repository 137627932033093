import React, { useState } from 'react'
import SubmitButton from '../../components/button/SubmitButton'
import { ignoreLateCheckinApi, raiseLeaveApi } from '../../apis/leave.api'
import { callApiAction } from '../../store/actions/commonAction'
import { closeModal } from '../../store/actions/modalAction'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { Box, ButtonBase, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { GppBad, Warning } from '@mui/icons-material'
import { CenteredBox } from '../../components/layouts/common/boxes'

function LateCheckinController({userId, id, start}) {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const leave_count = 0.5
    const remarks = 'Late Check-In'
    const from = moment(start).format("YYYY-MM-DD")
    const to = moment(start).format("YYYY-MM-DD")
    const from_time_duration = 1
    const to_time_duration = 1
    const late_check_in =  true

    const ignoreLateCheckIn = (checked_in_status = 3, deductLeave = false) => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await ignoreLateCheckinApi({ checked_in_status, id }),
            (response) => {
                if(deductLeave == true) {
                    setLoading(true)
                    dispatch(callApiAction(
                        async () => await raiseLeaveApi({leave_count, remarks, from, to, from_time_duration, to_time_duration, userId, late_check_in}),
                        (response) => {
                            dispatch(closeModal())
                            dispatch(callSnackBar("Half Leave Deducted", SNACK_BAR_VARIETNS.warning))
                            window.location.reload(true)
                            setLoading(false)
                        },
                        (err) => {
                            dispatch(callSnackBar("Cannot Deduct Leave ", SNACK_BAR_VARIETNS.error))
                            setLoading(false)
                        }
                    ))
                }
                else {
                    dispatch(closeModal())
                    dispatch(callSnackBar("Late Check In Ignored", SNACK_BAR_VARIETNS.warning))
                    window.location.reload(true)
                    setLoading(false)
                }
               
            },
            (err) => {
                dispatch(callSnackBar("Cannot Ignore Late Check In", SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }

    const deductHalfLeave = () => {
        ignoreLateCheckIn(2, true)

    }

  return (
    <>
    {loading &&
        <CenteredBox>
            <CircularProgress/>
        </CenteredBox>
    }
    {!loading && 
    <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <ButtonBase onClick={() => ignoreLateCheckIn()}>
                <Card style={{  border:'1px solid black' }} >
                    <CardContent style={{ textAlign: 'center' }}>
                        <Box sx={{display:'flex', flexDirection:'column'}}>
                            <Box>
                                <GppBad style={{ fontSize: 60, color: 'grey' }} />
                            </Box>
                            <Typography variant='h4'>
                                Ignore Late Check-In
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" p={1}>
                           You can click here to ignore the late check-in performed by user
                        </Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        </Grid>

        <Grid item xs={12} sm={6}>
            <ButtonBase onClick={deductHalfLeave}>
                <Card style={{  border:'1px solid black' }} >
                    <CardContent style={{ textAlign: 'center' }}>
                        <Box sx={{display:'flex', flexDirection:'column'}}>
                            <Box>
                                <GppBad style={{ fontSize: 60, color: 'red' }} />
                            </Box>
                            <Typography variant='h4'>
                                Deduct Half Leave
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" p={1}>
                            You can click here to deduct half leave of user for the late check-in
                        </Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        </Grid>

      
    </Grid>}
    </>
  )
}

export default LateCheckinController