import { Box, ButtonBase, CircularProgress, Divider, Grid, Paper, Tooltip, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { AccessAlarm, AccessTime, Business, Coffee, East, Fastfood, KeyboardArrowRight, NoFood, Pause, PlayArrow, Timer, West } from "@mui/icons-material"
import MapComponent from "../../components/MapComponents";
import moment from "moment";
import { CALENDAR_ITEM_TYPES } from "../../utils/constants";
import { Fragment } from "react";
const OverViewBox = ({ title, value }) => {
    return <Paper component={Box} p={2} sx={{ border: "1px solid gray" }}>

        <Typography variant="h3" align="center">
            {value}
        </Typography>
        <Typography variant="body2" align="center">
            {title}
        </Typography>
    </Paper>
}

const LogDetailsUi = ({ loading, list, date ,filters,setFilters}) => {

    

    return <Box>
       
        {loading && <CenteredBox mt={4} mb={4}><CircularProgress /></CenteredBox>}
        {
            !loading && list && list['allLogs'] && <Box>
                <Box mb={4} >

                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>

                            <Box sx={{ width: "100%", height: '100%', minHeight: "200px", position: "relative", borderRadius: 1 }}>
                                {list.allLogs && list.allLogs.length > 0 ? <MapComponent
                                    markers={list.allLogs}
                                    center={filters.center}

                                /> : <CenteredBox sx={{ height: "100%", width: "100%", background: "lightgray" }}>No Location</CenteredBox>}
                            </Box>
                        </Grid>

                        <Grid item md={6} xs={12}>

                            <Box>
                                <Typography variant="h6" fontWeight="bold" mb={3}>{moment(date).format("DD MMM YYYY")}</Typography>
                                 <Grid container spacing={2}>

                                    <Grid item sm={4} xs={12}>
                                        <OverViewBox title="Check In" value={moment(list.firstCheckIn).format("HH:mm")}></OverViewBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <OverViewBox title="Check Out" value={list.lastCheckout ? moment(list.lastCheckout).format("HH:mm") : "NA"}></OverViewBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <OverViewBox title="Working Hours" value={list.hours + "H " + list.minutes + "M"}></OverViewBox>
                                    </Grid>
                                </Grid> 
                                <Box mt={3} >

                                    <Typography variant="h6" fontWeight="bold" mb={3}>Activity Logs:</Typography>
                                    <Box sx={{ maxHeight: "300px", flexDirection: "column", display: "flex", overflow: "hidden", flexWrap: "wrap" }} p={2}>

                                        {
                                            list.logsWithOutPeriodic?.map?.((item) => ([CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakInLog, CALENDAR_ITEM_TYPES.breakOUTLog].includes(item.type)) ? (
                                                <ButtonBase onClick={()=>{setFilters({...filters,center:{
                                                    lat:item.lat,
                                                    lng:item.lng
                                                }})}} key={item.logTime} sx={{ display: "flex", alignItems: "center",justifyContent:"flex-start" }} mb={1}>
                                                 
                                                    <Typography lineHeight="100%" sx={{ color: item.message=="system"? "red !important":"#393939 !important" }} variant="subtitle1">
                                                        {item.type == CALENDAR_ITEM_TYPES.checkInLog && <East color="inherit" fontSize="inherit" />}
                                                        {item.type == CALENDAR_ITEM_TYPES.checkOUTLog && <West color="inherit" fontSize="inherit" />}
                                                        {item.type == CALENDAR_ITEM_TYPES.breakInLog && <Fastfood color="inherit" fontSize="inherit" />}
                                                        {item.type == CALENDAR_ITEM_TYPES.breakOUTLog && <NoFood color="inherit" fontSize="inherit" />}
                                                        &nbsp;{moment(item.logTime).format("HH : mm")}
                                                    </Typography>

                                                    {item.location_id &&
                                                      
                                                        <CenteredBox  sx={{backgroundColor:"success.main",color:"light.main",fontSize:"8px",letterSpacing:"0.5px",borderRadius:"100px"}} ml={1} px={1}  >{item.location_id}</CenteredBox>
                            
                                                    
                                                    }
                                                </ButtonBase>
                                            ) : (<Fragment key={item.logTime} />))

                                        }
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>



                </Box>



            </Box>
        }

        {!loading && (!list || Object.keys(list).length == 0) && <CenteredBox mt={4} mb={4}><Typography variant="h3" >No task Found</Typography></CenteredBox>}
    </Box>
    
}
export default LogDetailsUi