// import axios from "axios";
// import { getFileHeaders } from "../utils/helper";
// import { getHeaders } from "../utils/helper";
// import endpoints from "./endpoints";


// export const fetchForm16 =async(params)=>{
//     return await axios({
//         url: endpoints.form16,
//         method: "GET",
//         headers: getHeaders(),
//         params,
//       })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));          
// }

// export const createForm16 =async(data)=>{
//   const callResponse = await axios({
//     url: endpoints.form16,
//     method: 'post',
//     headers: getHeaders(),
//     data,
//   })
//     .then((response) => response.data)
//     .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

//   return callResponse        
// }

// export const DeleteForm16DetailsApi =async(data)=>{
//     return await axios({
//         url: endpoints.form16,
//         method: "delete",
//         headers: getHeaders(),
//         data,
//       })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));          
// }

// export const getForm16ByIdApi = async (params) => {
//   const callResponse = await axios({
//     url: endpoints.form16byId,
//     method: "GET",
//     headers: getHeaders(),
//     params,
//   })
//     .then((response) => response.data)
//     .catch((err) => ({status:0,response:err.response,code:err.response.status}))

//   return callResponse
// }

import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const fetchForm16 =async(params)=>{
    return await axios({
        url: endpoints.form16,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const createForm16 =async(data)=>{
  const callResponse = await axios({
    url: endpoints.form16,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse        
}

export const DeleteForm16DetailsApi =async(data)=>{
    return await axios({
        url: endpoints.form16,
        method: "delete",
        headers: getHeaders(),
        data,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const getForm16ByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.form16byId,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}