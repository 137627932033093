import { DoneAll } from "@mui/icons-material"
import { CircularProgress, IconButton } from "@mui/material"
import { useState } from "react"
import { REIMBURSEMENT_ADVANCE_PAYMENT_STATUS } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"

import SettleAdvancePaymentController from "./SettleAdvancePaymentController"


const SettlePaymentButton = ({ params, setParams }) => {
    const dispatch = useDispatch()


    const onClick = () => {
        dispatch(openModal({
            title: "Settle Payment!",
            component: <SettleAdvancePaymentController callBack={() => {
                setParams({
                    ...params,
                    status: REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE                    
                })
            }} id={params._id} />,
            size: "md"
        }))
    }
    if (params.status == REIMBURSEMENT_ADVANCE_PAYMENT_STATUS.CLOSE)
        return <></>

    return <IconButton onClick={onClick}>
        <DoneAll color="success" />
    </IconButton>
}
export default SettlePaymentButton