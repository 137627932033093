// import {
//   Box,
//   DialogActions,
//   Typography,

//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import CustomInput from "../../components/inputs/CustomInput";

// import SubmitButton from "../../components/button/SubmitButton";

// import FileInput from "../../components/inputs/FileInput";

// const AddDocumentUI = ({
//   loading,
//   onSubmit,
//   fields,
//   setFields,
// }) => {
//   return (
//     <>
//       <Box
//         onSubmit={onSubmit}
//         component="form"
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           overflowY: "auto",
//           position: "relative",
//         }}
//         maxHeight="100%"
//       >
//         {
//           fields.err && <Typography color="error" variant="h6">
//             {fields.err}
//           </Typography>
//         }
//         {!loading && (
//           <>
//             <Box sx={{ display: "flex", width: "100%" }}>
//               <CustomInput
//                 disabled={loading}
//                 value={fields.name}
//                 onChange={(e) => setFields({ ...fields, name: e.target.value })}
//                 type="text"
//                 label="Document Name"
//               />


//             </Box>

//             <Box sx={{ display: "flex", width: "100%" }}>
//               <FileInput
              
//                 sx={{ width: "100%" }}
//                 onChange={(file) => {
//                   setFields({ ...fields, url: file })
//                 }}
//                 defaults={fields.url ? [fields.url] : []}
//                 accept=".pdf"
//                 title="Upload Document*"
//                 subTitle="Only pdf files are allowed! less than 1 MB"
//               />
//             </Box>

//           </>
//         )}

//         <DialogActions>
//           <Box sx={{ float: "right", marginTop: "7px" }}>
//             <SubmitButton
//               loading={loading}
//               type="submit"
//               variant="contained"
//               color="primary"
//               title="Add"
//             >

//             </SubmitButton>
//           </Box>
//         </DialogActions>
//       </Box >
//     </>
//   );
// };

// export default AddDocumentUI;

import {
  Box,
  DialogActions,
  Typography,

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomInput from "../../components/inputs/CustomInput";

import SubmitButton from "../../components/button/SubmitButton";

import FileInput from "../../components/inputs/FileInput";

const AddDocumentUI = ({
  loading,
  onSubmit,
  fields,
  setFields,
}) => {
  return (
    <>
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {
          fields.err && <Typography color="error" variant="h6">
            {fields.err}
          </Typography>
        }
        {!loading && (
          <>
            <Box sx={{ display: "flex", width: "100%" }}>
              <CustomInput
                disabled={loading}
                value={fields.name}
                onChange={(e) => setFields({ ...fields, name: e.target.value })}
                type="text"
                label="Document Name"
              />


            </Box>

            <Box sx={{ display: "flex", width: "100%" }}>
              <FileInput
              
                sx={{ width: "100%" }}
                onChange={(file) => {
                  setFields({ ...fields, url: file })
                }}
                defaults={fields.url ? [fields.url] : []}
                accept=".pdf"
                title="Upload Document*"
                subTitle="Only pdf files are allowed! less than 1 MB"
              />
            </Box>

          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              title="Add"
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box >
    </>
  );
};

export default AddDocumentUI;
