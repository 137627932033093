// import React, { useMemo, useState } from "react";
// import { createHoliday } from "../../apis/holiday.api";
// import { createBranch } from "../../apis/branch.api";
// import { useDispatch, useSelector } from "react-redux"
// import { callApiAction } from "../../store/actions/commonAction";
// import AddDocumentUI from "./AddDocumentUI"
// import { closeModal } from "../../store/actions/modalAction";
// import useValidate from "../../store/hooks/useValidator";
// import { createVersion } from "../../apis/version.api";
// import { createdocument } from "../../apis/document.api";
// import { fetchDocumentDataAction } from "../../store/actions/settingsAction";

// const AddVersionController = ({ callBack }) => {

//     const validate = useValidate()
//     const dispatch = useDispatch()
//     const createBranchApi = createBranch
//     const [loading, setLoading] = useState(false)
//     const {settings} = useSelector((state) => state)


//     const [fields, setFields] = useState({
//         name: "",
//         url: ''
//     })

//     const validationSchema = useMemo(() => ([
//         {
//             required: true,
//             value: fields.name,
//             field: ' Name '
//         },
//         {
//             required: true,
//             value: fields.url,
//             field: 'Upload Document',
//         },
//     ]), [fields])

//     const submitData = (e) => {
//         e.preventDefault()
//         const validationResponse = validate(validationSchema)

//         if (validationResponse === true) {
//             setLoading(true)
//             dispatch(callApiAction(
//                 async () => await createdocument({ ...fields }),
//                 (response) => {
//                     callBack()
//                     setLoading(false)
//                     dispatch(fetchDocumentDataAction(settings.document_filters))
//                     dispatch(closeModal())
//                 },
//                 (err) => {
//                     setLoading(false)
//                     setFields({ ...fields, err })
//                 }
//             ))
//         } else {
//             setFields({ ...fields, 'err': validationResponse })
//         }
//     }
//     return <AddDocumentUI
//         loading={loading}
//         onSubmit={submitData}
//         fields={fields}
//         setFields={setFields}
//     />
// }

// export default AddVersionController;

import React, { useMemo, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { createBranch } from "../../apis/branch.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import AddDocumentUI from "./AddDocumentUI"
import { closeModal } from "../../store/actions/modalAction";
import useValidate from "../../store/hooks/useValidator";
import { createVersion } from "../../apis/version.api";
import { createdocument } from "../../apis/document.api";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";

const AddVersionController = ({ callBack }) => {

    const validate = useValidate()
    const dispatch = useDispatch()
    const createBranchApi = createBranch
    const [loading, setLoading] = useState(false)
    const {settings} = useSelector((state) => state)


    const [fields, setFields] = useState({
        name: "",
        url: ''
    })

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.name,
            field: ' Name '
        },
        {
            required: true,
            value: fields.url,
            field: 'Upload Document',
        },
    ]), [fields])

    const submitData = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await createdocument({ ...fields }),
                (response) => {
                    callBack()
                    setLoading(false)
                    dispatch(fetchDocumentDataAction(settings.document_filters))
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            ))
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    return <AddDocumentUI
        loading={loading}
        onSubmit={submitData}
        fields={fields}
        setFields={setFields}
    />
}

export default AddVersionController;