import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"

import { fetchPendingWFHController } from "../../apis/wfh.api"
import moment from "moment"
import DataTable from "../../components/tables/DataTable"
import WFHStatusComponent from "./WFHStatusComponent"
import WFHDetailShowButton from "./WFHDetailShowButton"
import { Box } from "@mui/material"

const PendingWFHController = ({ userId }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)




    const columns = useMemo(() => [
        { id: 1, fieldName: 'date', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.date).format('DD/MM/YYYY') },
        { id: 4, fieldName: 'start_time', label: 'Duration', align: "left", sort: true, minWidth: '150px', renderValue: (params) => (moment(params.start_time).format('hh:mm A')+" - "+moment(params.end_time).format('hh:mm A')) },
        { id: 5, fieldName: 'user_id?.first_name', label: 'Applied By', align: "left", minWidth: '250px',renderValue:(params)=>(params.user_id?.first_name+" "+params.user_id?.last_name) },
        { id: 2, fieldName: 'reason', label: 'Reason', align: "left", minWidth: '250px' },
        
        {
            id: 4, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
              
                <WFHStatusComponent  editable params={params} setParams={setParams}/>

            </Box>
        },
    ], [dispatch]);
    const [filters, setFilters] = useState({
        pageSize: 10,
        pageNo: 1,
        userId,
        date: moment().toISOString()
    })

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchPendingWFHController(filters),
            (response) => {
                
                setState(response)

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }




    useEffect(() => {
        fetchList()
    }, [filters])

    return <DataTable columns={columns} rows={state?.result??[]} count={state?.total??0} filters={filters} setFilters={setFilters} loading={loading} />
}
export default PendingWFHController