import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import DocumentsUi from "./DocumentsUi";
import { Box, IconButton, Tooltip } from "@mui/material";
import { fetchVersiones } from "../../apis/version.api";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { fetchdocuments } from "../../apis/document.api";
import DeleteDocumentButton from "./DeleteDocumentButton";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReportIcon from "@mui/icons-material/Report";
import { openModal } from "../../store/actions/modalAction";
import DisabilityAndTransgenderComplaintFormUi from "./DisabilityAndTransgenderComplaintFormUi";
import PoshPolicyComplaintFormUi from "./PoshPolicyComplaintFormUi";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MaternityApplicationFormUi from "./MaternityApplicationFormUi";
import PoshComplaintController from "./PoshComplaintController";
import MaternityFormController from "./MaternityFormController";
import DisabilityAndTransgenderComplaintController from "./DisabilityAndTransgenderComplaintController";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { AddCircleOutline, HealthAndSafety } from "@mui/icons-material";

const DocumentssController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);

  const fetchVersionsApi = fetchVersiones;

  const onBtnCLick = () => {
    dispatch(
      openModal({
        title: "Grievance Form For Equal Opportunity Policy",
        component: <DisabilityAndTransgenderComplaintController />,
        size: "lg",
      })
    );
  };

  const onPoshBtnCLick = () => {
    dispatch(
      openModal({
        title: "Grievance Form For POSH Policy",
        component: <PoshComplaintController />,
        size: "lg",
      })
    );
  };

  const onSubmitBtnCLick = () => {
    dispatch(
      openModal({
        title: "Application Form For Maternity Leave",
        component: <MaternityFormController />,
        size: "lg",
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "180px",
      },

      {
        id: 4,
        fieldName: "url",
        label: "Document",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => <FileDownloadComponent src={params.url} />,
      },

      {
        id: 3,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params, setParams) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {user.data.role === USER_ROLES.hr ||
            user.data.role === USER_ROLES.admin ? (
              <DeleteDocumentButton params={params} setParams={setParams} />
            ) : (
              ""
            )}

            {/* {params.name === "Posh Policy" && (
              <Tooltip title={"Raise Complaint"}>
                <IconButton onClick={onPoshBtnCLick}>
                  <AddCircleOutline color="info" />
                </IconButton>
              </Tooltip>
            )}
            {params.name === "Maternity Policy" && (
              <Tooltip title={"Apply Maternity Application"}>
                <IconButton onClick={onSubmitBtnCLick}>
                  <AddCircleOutline color="info" />
                </IconButton>
              </Tooltip>
            )}
            {params.name === "Equal Opportunity Policy" && (
              <Tooltip title={"Raise Complaint"}>
                <IconButton onClick={onBtnCLick}>
                  <AddCircleOutline color="info" />
                </IconButton>
              </Tooltip>
            )} */}
          </Box>
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1,
    sort: "createdAt",
    sortDirection: -1,
  });

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchdocuments(filters),
        (response) => {
          setState(response);

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const getDocumnetList = () => {
    if (
      !settings.document_data ||
      settings.document_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(settings.document_filters)
    ) {
      dispatch(fetchDocumentDataAction(filters));
    }
  };
  useEffect(() => {
    fetchList();
    getDocumnetList();
  }, [filters]);

  return (
    <DocumentsUi
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      setState={setState}
      callBack={getDocumnetList}
      loading={settings.document_loading}
      state={settings.document_data}
    />
  );
};
export default DocumentssController;