import {
  CampaignOutlined,
  EventAvailable,
  EventAvailableOutlined,
  FactCheck,
  History,
  HourglassEmpty,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { Add } from "@mui/icons-material";
import { center } from "../../assets/css/theme/common";
import { Link, useLocation } from "react-router-dom";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { ActionButton } from "../../components/button/SubmitButton";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import MessageDilog from "../../components/MessageDilog";
import moment from "moment";
import { getPmsById } from "../../apis/pms.api";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import { callApiAction } from "../../store/actions/commonAction";

const LeaveBtnBox = {
  width: "100%",

  display: "flex",
};

const EmployeeTaskButtons = ({  }) => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const slug = pathArr[pathArr.length - 1];
  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  //const selectedUser = useContext(EmployeeTaskUserContext);
  const currentDate = moment();
  const currentDay = currentDate.date();
  const currentMonth = currentDate.month() + 1;
  const [list,setList] = useState('')
//  const userId = selectedUser ? selectedUser._id : userId.data._id;
const { user } = useSelector((state) => state)
  

  const fetchList = async () => {
    dispatch(
      callApiAction(
        async () => await getPmsById({ id: user.data._id}),
        async (response) => {
        
          setList(response)

        },
        (err) => {
        
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }
  useEffect(() => {
    fetchList();
    //console.log("userId", user.data._id)
  },[])

  const SubmittedData = () => {
    return list.some((item) => {
      const completionDate = moment(item.completion_date);
      return completionDate.month() + 1 === currentMonth; 
    });
  };

  const handleMonthlyFormClick = (event) => {
    if (currentDay > 5 && !SubmittedData()) {
      event.preventDefault();
      dispatch(
        openModal({
          component: (
            <MessageDilog
              Error
              title={
                <Box
                  display="flex"
                  flexDirection="column" 
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <WarningAmber color="error" sx={{ fontSize: "50px" }} />
                  <Typography variant="h4" mt={2} color="error">
                    You Cannot Submit the Form after 5th date!
                  </Typography>
                </Box>
              }
            />
          ),
          size: "sm",
        })
      );
    } else {
      navigate("Monthly-task-form");
    }
  };
  return (
    <>
      <Box sx={LeaveBtnBox} mt={5} mb={5}>
        
        <ActionButton
          active={slug == "daily-update"}
          sx={{ borderRadius: "4px" }}
          LinkComponent={Link}
          to="daily-update"
          title={"Daily Update"}
          icon={<History />}
        ></ActionButton>
        <Box ml={2} />
        <ActionButton
          active={slug == "Monthly-task-form"}
          sx={{ borderRadius: "4px" }}
          // LinkComponent={Link}
          onClick={handleMonthlyFormClick}
          title={"Monthly Evaluation Form"}
          icon={<FactCheck />}
        ></ActionButton>
        <Box ml={2} />
        <ActionButton
          isFirst={true}
          LinkComponent={Link}
          active={slug == "" || slug == "calendar"}
          to="calendar"
          sx={{ borderRadius: "4px" }}
          title={"My Work Calendar"}
          icon={<EventAvailable />}
        ></ActionButton>
        <Box ml={2} />
      </Box>
    </>
  );
};
export default EmployeeTaskButtons;
