
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Zoom } from "@mui/material"
import { REQUESTS_STATUS, SNACK_BAR_VARIETNS } from "../../utils/constants"
import { findObjectKeyByValue } from "../../utils/helper"
import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { useState } from "react"
import { closeModal } from "../../store/actions/modalAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { callApiAction } from "../../store/actions/commonAction"
import { updateWFHStatusApi } from "../../apis/wfh.api"
import CustomInput from "../../components/inputs/CustomInput"

const WFHStatusComponent = ({ params, setParams, editable, callBack }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const color = params.status == REQUESTS_STATUS.APPROVED ? "success" : (params.status == REQUESTS_STATUS.REJECTED ? "error" : "warning")

    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [action_reason, setReason] = useState('')

    const onSubmit = (status) => {
        setModal(false)
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateWFHStatusApi({ id: params._id, status, action_reason: status == REQUESTS_STATUS.APPROVED ? '' : action_reason }),
                (response) => {

                    setLoading(false);
                    setParams({ ...params, status })
                    callBack(status)
                    
                    dispatch(closeModal())
                    dispatch(callSnackBar("Status Updated", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Can Update Status", SNACK_BAR_VARIETNS.error))
                }
            )
        );

    }

    if (editable && params.approver?.includes?.(user.data._id) && params.status == REQUESTS_STATUS.PENDING) {
        return <Box sx={{ display: "flex" }} >


            <Dialog maxWidth={'xs'} fullWidth open={modal} TransitionComponent={Zoom} onClose={() => setModal(false)} >
                <DialogTitle>
                    Reject WFH Application
                </DialogTitle>
                <DialogContent>
                    <CustomInput
                        multiline
                        rows={3}
                        disabled={loading}
                        value={action_reason}
                        onChange={(e) => setReason(e.target.value)}
                        type="number"
                        label="Remarks*"
                    />

                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="contained" color="primary" onClick={() => { setModal(false) }}  >Cancel</Button>
                    <Button disabled={loading} variant="contained" color="error" onClick={() => { onSubmit(REQUESTS_STATUS.REJECTED) }}  >Reject</Button>
                </DialogActions>
            </Dialog>
            {loading && <CircularProgress size={20} />}
            {!loading && <>
                <Button variant="contained" disableElevation size="small" title="" color="error" onClick={() => setModal(true)} >Reject</Button>
                <Box ml={1} />
                <Button variant="contained" disableElevation size="small" title="" color="success" onClick={() => {
                    onSubmit(REQUESTS_STATUS.APPROVED)
                }} >Approve</Button>
            </>}

        </Box>
    } else if (editable) {
        return "NA"
    }


    return <>
        <Chip color={color} size="small" label={findObjectKeyByValue(params.status, REQUESTS_STATUS)} />
    </>
}
export default WFHStatusComponent