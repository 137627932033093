import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const fetchOneYearWorkFromHomeDataApi = async (params = {}) => {

  const callResponse = await axios({
    url: endpoints.wfhYearly,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}

export const fetchPendingWFHController = async (params = {}) => {

  const callResponse = await axios({
    url: endpoints.wfhPending,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}

export const fetchWFHByIdDataApi = async (params = {}) => {

  const callResponse = await axios({
    url: endpoints.wfhById,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}
export const fetchOneMonthWorkFromHomeDataApi = async (params = {}) => {

  const callResponse = await axios({
    url: endpoints.wfhMonthly,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}



export const createWFHrequest = async (data) => {
  const callResponse = await axios({
    url: endpoints.wfhBase,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}

export const updateWFHStatusApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.wfhBase,
    method: 'put',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}

export const deleteWFHApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.wfhDelete,
    method: 'DELETE',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}