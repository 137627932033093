import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

// export const fetchHolidays = async () =>
// {
    
//     const callResponse = await axios({
//         url: endpoints.holiday,
//         method: "get",
//         headers: getHeaders(),
//     })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));

//     return callResponse;
// };

// export const downloadSalaryCsv = async (params) =>
// {
    
//     const callResponse = await axios({
//         url: endpoints.downloadSalaryCsv,
//         method: "get",
//         headers: getHeaders(),
//         params
//     })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));

//     return callResponse;
// };

export const UploadSheetApi = async (data) =>
{
    const callResponse = await axios({
        url: endpoints.appresialUpload,
        method: "post",
        headers:getFileHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const deleteAppresialDetailsApi = async data =>
{
    const callResponse = await axios({
        url: endpoints.fetchAppresial,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.message)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export const fetchAppresialDetailsApi =async(params)=>{
    return await axios({
        url: endpoints.fetchAppresial,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const deleteSalaryDetailsApi =async(data)=>{
    return await axios({
        url: endpoints.fetchAppresial,
        method: "DELETE",
        headers: getHeaders(),
        data,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const fetchAllUserAppresialApi =async(params)=>{
    return await axios({
        url: endpoints.fetchAllUserAppresial,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const appresialLetterPdfApi = async params => {
    const callResponse = await axios({
        url: endpoints.appresialLetterPdf,
        method: "get",
        headers: getHeaders(),
        params,
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
  
    return callResponse;
  };

  export const IndividualappresialLetterPdfApi = async params => {
    const callResponse = await axios({
        url: endpoints.appresialIndividualLetterPdf,
        method: "get",
        headers: getHeaders(),
        params,
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
  
    return callResponse;
  };