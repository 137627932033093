import {
  CampaignOutlined,
  EventAvailable,
  EventAvailableOutlined,
  FactCheck,
  Grading,
  History,
  HourglassEmpty,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { Add } from "@mui/icons-material";
import { center } from "../../assets/css/theme/common";
import { Link, useLocation } from "react-router-dom";

import { ActionButton } from "../../components/button/SubmitButton";

const LeaveBtnBox = {
  width: "100%",

  display: "flex",
};

const EmployeeTaskReviewButtons = ({ userId }) => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const slug = pathArr[pathArr.length - 1];

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  // if(desktop)
  return (
    <>
      <Box sx={LeaveBtnBox} mt={5} mb={5}>
        <ActionButton
          active={slug == "Employee-daily-update"}
          sx={{ borderRadius: "4px" }}
          LinkComponent={Link}
          to="Employee-daily-update"
          title={"Employee Daily Update"}
          icon={<History />}
        ></ActionButton>
        <Box ml={2} />

        <ActionButton
          active={slug == "Employee-review-form"}
          sx={{ borderRadius: "4px" }}
          LinkComponent={Link}
          to="Employee-review-form"
          title="Employee Review Form"
          icon={<Grading />}
        ></ActionButton>

        <Box ml={2} />
        <ActionButton
          isFirst={true}
          LinkComponent={Link}
          active={slug == "" || slug == "Employee-Task-calendar"}
          to="Employee-Task-calendar"
          sx={{ borderRadius: "4px" }}
          title={"Employee Work Calendar"}
          icon={<EventAvailable />}
        ></ActionButton>
        <Box ml={2} />
      </Box>
    </>
  );
};
export default EmployeeTaskReviewButtons;
