import React from "react";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import { Box } from "@mui/system";
import {
  CircularProgress,
  Grid,
  Paper,
  TablePagination,
  Typography,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { USER_ROLES } from "../../utils/constants";
import MemberCard from "./MemberCard";
import { CenteredBox } from "../../components/layouts/common/boxes";
import styled from "@emotion/styled";
import PaddingBoxInDesktop from "../../components/layouts/common/PaddingBoxInDesktop";

const FiltersBox = styled(Box)(({ theme }) => ({
  width: "450px",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

function TeamDetailsUi({
  filters,
  setFilters,
  loading,
  list,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const user = useSelector((state) => state.user);
  return (
    <Box mt={3} mb={3}>
      <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
        <Box
          mb={4}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Team</Typography>

          <Box
            pl={2}
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <FiltersBox>
          
                <StyledSearchBar
                  placeholder={"Search Name || Email"}
                  value={filters.search}
                  onChange={(e) => {
                    setFilters({ ...filters, search: e.target.value });
                  }}
                />
            </FiltersBox>
          </Box>
        </Box>

        <Box sx={{ minHeight: "300px" }}>
          {loading && (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          )}
          {list?.result?.length != 0 ? (
            !loading && (
              <>
                <Grid container spacing={2}>
                  {list?.result?.map?.((item) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <MemberCard
                        employee_id={item.employee_id}
                        firstName={item.first_name}
                        lastName={item.last_name}
                        designation={item.designation}
                        dob={item.dob}
                        id={item._id}
                        email={item.email}
                        emergency_contact={item.phone}
                        profileUrl={item.profile_url}
                      />
                    </Grid>
                  ))}
                </Grid>
                <TablePagination
                  rowsPerPageOptions={[12, 24]}
                  component="div"
                  count={list.total ?? 0}
                  rowsPerPage={filters.pageSize}
                  page={filters.pageNo - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )
          ) : (
            <>
              <CenteredBox>No Data Found</CenteredBox>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default TeamDetailsUi;
