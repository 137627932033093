import { Grid, Paper, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { center } from '../../assets/css/theme/common'
import { fetchLeaveBalanceCountAction } from '../../store/actions/leaveBalanceAction'
import { fetchUserDetails } from '../../store/actions/userReducerAction'
import LeaveBalanceLogo from '../../assets/images/LeaveBalance.png'
import leaveTheme from '../../assets/css/theme/leaveTheme'
import moment from 'moment'
import { LEAVE_NAMES } from '../../utils/leave.constants'

export const LeavecountBoxOuter = styled(Box)(({ theme, color,background }) => ({
  display: "flex",
  height: "100%",
  width: '100%',
  
  position: "relative",
  backdropFilter: "opacity(0)",
  alignItems: "center",
  color: "black",
  '&::before': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    backgroundColor: color,
    width: "3px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    height: "50%",
    top: "25%"
  },

  '&::after': {
    content: '" "',
    display: 'block',
    position: "absolute",
    left: "0px",
    zIndex: -1,
    top: "0px",
    backgroundColor: background,
    borderRadius: theme.shape.borderRadius,
    opacity: 1,
    width: "100%",
    height: "100%",

  }

}))


const LeaveCountBox = ({ title, subTitle, count, color,background }) => {
  return <LeavecountBoxOuter color={color} background={background} p={2}>
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} p={1} pl={2}>
      <Typography variant='h6'>
        {title}
      </Typography>
      <Typography variant='h6' fontWeight={"bold"}>
        ({subTitle})
      </Typography>
    </Box>
    <Box sx={{ display: "flex" }} p={1} pr={2}>
      <Typography variant='h1'>
        {count}
      </Typography>
    </Box>
  </LeavecountBoxOuter>
}

const LeaveBalance = ({ direction, userId,date }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { leaveBalance } = useSelector((state) => state)

  const getLeavecount = () => {
    const leaveBalanceMonth = moment(leaveBalance.date).month();
    const currentMonth = moment(date).month();
  
    if (!leaveBalance.data || leaveBalance.data.length === 0 || leaveBalanceMonth !== currentMonth || leaveBalance.userId != userId) {
      dispatch(fetchLeaveBalanceCountAction({ userId, date: date?.valueOf() ?? moment().valueOf() }));
    }
  }

  useEffect(() => {
   getLeavecount()
  }, [userId, date]);


  const isSmallView = useMediaQuery(theme.breakpoints.down('md'))

  direction = isSmallView?"column":direction

  return (
    <Box sx={{ display: 'flex'}}>
      {direction != "column" &&
        <Box sx={{ display: "flex", alignItems: 'center', mr: 2 }}>
          <Box mr={3}>
            <img src={LeaveBalanceLogo} height="52px" width="52px" />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant='h3' mb={1}>
              Leave
            </Typography>
            <Typography variant='h3'>
              Balance
            </Typography>
          </Box>
        </Box>}
      <Box sx={{ display: "flex", flex: 1}}>

        {
          leaveBalance.loading && <Grid container spacing={2}>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>
            <Grid item xs={direction == "column" ? 6 : 3}>
              <Skeleton width="100%" height={"65px"} />
            </Grid>

          </Grid>
        }


        {
          !leaveBalance.loading && leaveBalance.data && Array.isArray(leaveBalance.data) && <Grid container spacing={2}>
            {leaveBalance.data?.map((leave,index) => (<Grid item xs={direction == "column" ? 6 : (12 /( (leaveBalance.data?.length)))} >
              <LeaveCountBox
                title={LEAVE_NAMES[index].label}
                subTitle={LEAVE_NAMES[index].fullname}
                count={leave}
                color={LEAVE_NAMES[index].color}
                background={LEAVE_NAMES[index].background}
              />
            </Grid>
            ))}



          </Grid>
        }


      </Box>
    </Box>
  )

}
export default memo(LeaveBalance)
