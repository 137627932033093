import { Box } from "@mui/material"
import { ActionButton } from "../../components/button/SubmitButton"
import { Link, Outlet, useLocation } from "react-router-dom"
import { CalendarMonth, MedicalInformation, Store } from "@mui/icons-material"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import { useDispatch, useSelector } from "react-redux"
import { changeDate } from "../../store/actions/selectedEmployeeAction"

const VisitMainContainer = ({  }) => {
    const location = useLocation()
    const pathArr = location.pathname.split('/')
    const slug = pathArr[pathArr.length - 1]


    const dispatch = useDispatch()
    const { selectedEmployee, user } = useSelector(state => state)
   
    return <>
        <Box sx={{ display: "flex" }} mt={5} mb={4}>


            <ActionButton


                active={slug == '' || !slug}
                sx={{ borderRadius: '4px' }}
                LinkComponent={Link}
                to=" "
                title="Calendar"
                icon={<CalendarMonth />}
            >

            </ActionButton>
            <Box ml={2} />


            <Box ml={2} />
            <ActionButton
                active={slug == 'store'}

                sx={{ borderRadius: '4px' }}
                LinkComponent={Link}
                to="store"
                title={"Stores"}
                icon={<Store />}
            >
            </ActionButton>
            <Box ml={2} />
            <ActionButton

                active={slug == 'doctor'}

                sx={{ borderRadius: '4px' }}
                LinkComponent={Link}
                to="doctor"
                title="Doctors"
                icon={<MedicalInformation />}
            >

            </ActionButton>


        </Box>
        
        
        <Outlet />
    </>
}
export default VisitMainContainer