import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import { CenteredBox } from "../../components/layouts/common/boxes";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const ColoredBox = styled(Box)(({ theme, color, inverted }) => ({
  border: "1px solid " + theme.palette[color ?? "primary"].main,
  display: "flex",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  "::before": {
    content: "' '",
    position: "absolute",
    zIndex: 11,
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    opacity: 0.2,
    backgroundColor: theme.palette[color ?? "primary"].main,
  },
}));

// const AnnouncementModal = ({ announcement }) => {
//   return (
//     <>
//       {announcement && (
//         <Box p={4} sx={{  border:"2px solid",
//                   // boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.1)`,
//                   // bgcolor:"#F5F5F5",
//                   borderColor: "lightgrey",
//                  borderRadius: "10px"}}>
//           <Grid container spacing={2} rowSpacing={3}>
//             <Grid xs={6} item>
//               <Typography variant="h4">
//                 {" "}
//                 {announcement.announcement_title}
//               </Typography>

//               <Typography variant="h6" color="grey.main">
//               <Grid container spacing={1}  alignItems="center">
//   <Grid item mt={1}>
//     <AccessTimeIcon sx={{ fontSize: 18 }} /> {/* Adjust icon size as needed */}
//   </Grid>
//   <Grid item>
//     <Typography variant="caption" color="grey.main">
//       {moment(announcement.announcement_date).format("DD-MMM-YYYY")}
//     </Typography>
//   </Grid>
// </Grid>
//               </Typography>
//             </Grid>
           
//             <Grid xs={12} item>
//             <Box sx={{ bgcolor:"#F5F5F5",   
//                   borderColor: "lightgrey",
//                  borderRadius: "10px"}}>
//               <Typography  p={3} 
//                 variant="body1"
//                 color="grey.main"
                
//                 sx={{overflowWrap: 'break-word'}}
//                 dangerouslySetInnerHTML={{
//                   __html: announcement.announcement_description,
//                 }}
//               />
//                </Box>
//             </Grid>
           
//           </Grid>
//         </Box>
//       )}
//       {/* {
//             !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
//         }
//         {
//             loading && <CenteredBox sx={{ minHeight: "300px" }}><CircularProgress /></CenteredBox>
//         } */}
//     </>
//   );
// };
// export default AnnouncementModal;


const AnnouncementModal = ({ announcement }) => {
  return (
    <>
      {announcement && (
        <Box p={4} sx={{ border: "2px solid", borderColor: "lightgrey", borderRadius: "10px" }}>
          <Grid container spacing={2} rowSpacing={3}>
            <Grid xs={6} item>
              <Typography variant="h4">
                {announcement.announcement_title}
              </Typography>
              <Typography variant="h6" color="grey.main">
                <Grid container spacing={1} alignItems="center">
                  <Grid item mt={1}>
                    <AccessTimeIcon sx={{ fontSize: 18 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" color="grey.main">
                      {moment(announcement.announcement_date).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Box sx={{ bgcolor: "#F5F5F5", borderColor: "lightgrey", borderRadius: "10px" }}>
                <Typography
                  p={3}
                  variant="body1"
                  color="grey.main"
                  sx={{ overflowWrap: 'break-word' }}
                  dangerouslySetInnerHTML={{
                    __html: announcement.announcement_description,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AnnouncementModal;