
import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomInput from '../../components/inputs/CustomInput'
import SubmitButton from '../../components/button/SubmitButton'
import { useDispatch } from 'react-redux'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux'
import moment from 'moment'
import DownloadApprasialLetterPdf from './DownloadApprasialLetterPdf'


function ApprasialLetterUi({
  loading,
}) {
  const currentDate = new Date();

  // const previousMonth = new Date(currentDate);
  // previousMonth.setMonth();
 const updatedDate = moment(currentDate).set("date",1).set("month",3);

  const [date, setDate] = useState(updatedDate);
  const { user } = useSelector(state => state)
console.log("min Date",moment(user.data.join_date).format("DD/MM/YYYY"),user.data)
  return (
    <>
      <Box mt={3}>
        <DatePicker
          minDate={moment(updatedDate)}
          // maxDate={moment().add(-1,'month').endOf("month")}
          views={['year']}
          inputFormat='YYYY'
          onChange={(newValue) => setDate(newValue)}
          value={date}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              error={false}
            />
          )}
        />
        <Box mt={8} sx={{ float: "right", marginTop: "5px" }}>
          <DownloadApprasialLetterPdf
            date={date}
          />
        </Box>

      </Box>
    </>
  )
}

export default ApprasialLetterUi;