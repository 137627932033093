import {
  Box,
  DialogActions,
  Typography,

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomInput from "../../components/inputs/CustomInput";

import SubmitButton from "../../components/button/SubmitButton";

import FileInput from "../../components/inputs/FileInput";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { getUserApi } from "../../apis/user.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";

const CreateAdvancePaymentsUI = ({
  loading,
  onSubmit,
  fields,
  setFields,
}) => {
  return (
    <>
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {
          fields.err && <Typography color="error" variant="h6">
            {fields.err}
          </Typography>
        }
        {!loading && (
          <>

            <CustomInput

              disabled={loading}
              value={fields.amount}
              onChange={(e) => setFields({ ...fields, amount: e.target.value })}
              type="number"
              label="Amount*"
            />
            <DesktopDatePicker

              renderInput={(props) => {
                return <CustomInput {...props} sx={{ height: '100%' }} />
              }}
              inputFormat="DD-MM-yyyy"
              disabled={
                loading
              }
              value={moment(fields.date)}
              onChange={(e) => {
                const changedVal = {}
                changedVal['date'] = moment(e).toISOString()

                setFields({ ...fields, err: '', ...changedVal })
              }}


            />

            <AsyncDropDown
              // InputComponent={(props) => <CustomInput label="Select Employee*" placeholder="Select Employee"  {...props} />}
              lazyFun={async (props) => {
                return await getUserApi({ ...props, searchable: ['first_name', 'last_name'] })
              }}
              label="Employee*"
              onChange={async (changedVal) => {
                setFields({ ...fields, err: '', userId: changedVal._id })
              }}
              titleKey={'name'}
              valueKey={"_id"}
              OptionComponent={({ option, ...rest }) => {
                return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                  <Typography ml={3} variant="h5">{option.name}</Typography>
                </Box>
              }}

            />
            <CustomInput
              multiline
              rows={3}
              disabled={loading}
              value={fields.purpose}
              onChange={(e) => setFields({ ...fields, purpose: e.target.value })}
              type="text"
              label="Purpose*"
            />





          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              title="Add"
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box >
    </>
  );
};

export default CreateAdvancePaymentsUI;
