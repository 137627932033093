import { Info } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import ProfileDialog from "../../profile/ProfileDialog";

const UserViewMoreButton = ({ id , isFromViewMore }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal({
            title: "User Details",
            component: <ProfileDialog userId={id} isFromViewMore={isFromViewMore}/>,
            size: "lg"
        }));
    };
    return <Tooltip title="View More" >
        <IconButton onClick={onClick} >
            <Info color="info" />
        </IconButton>
    </Tooltip>
}
export default UserViewMoreButton