import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  ButtonBase,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import {
  Cancel,
  CheckBox,
  CheckCircle,
  Visibility,
  Add,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { center } from "../../assets/css/theme/common";
import { Skeleton } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AddDepartmentContorller from "./AddDepartmentController";
import LaunchIcon from "@mui/icons-material/Launch";
import UpdateDepartmentController from "./UpdateDepartmentController";
import DataTable from "../../components/tables/DataTable";

const DepartmentUI = ({ setState, callBack, loading, state, DeleteDepartment, columns,filters,setFilters }) => {
  const dispatch = useDispatch();
  const addholiday = useCallback(() => {
    dispatch(openModal({ title: "Add Department", component: <AddDepartmentContorller callBack={callBack} />, size: "sm" }));
  }, []);
  const UpdateDepartment = useCallback((id, initialName, parents) => {
    dispatch(
      openModal(
        {
          title: "Update Department",
          component: <UpdateDepartmentController
            callBack={callBack}
            id={id}
            initialName={initialName}
            parents={parents}
          />,
          size: "sm"
        }
      )
    );
  }, []);

  return (
    <>
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      <Box m={3}>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Department
          </Typography>
          <Button
            onClick={addholiday}
            sx={{
              display: "flex",
              height: "100%",
              zIndex: 0,
              textTransform: "capitalize",
              boxShadow: "none",
              border: "1px solid #393939",
              width: "100%",
              height: "56px",
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Add color="primary" /> &nbsp;Add Department
          </Button>
        </Box>
        <Box sx={{ minHeight: "300px" }} mt={3}>
          <DataTable columns={columns} rows={state} count={state?.length} filters={filters} setFilters={setFilters} loading={loading} />
        </Box>
      </Box>
    </>
  );
};
export default DepartmentUI;
