

  // import { fetchStockCount } from "../../apis/inventory.api"
import { getLeaveCounts } from "../../apis/leave.api"
import { getLastReadTimestampApi, getNotifcationsApi, readNotifcationsApi, setLastReadTimestampApi } from "../../apis/notification.api"
import { actions, NOTIFICATION_TYPE } from "../../utils/constants"
import { lastReadNotification } from "../../utils/helper"
import { callApiAction } from "./commonAction"

// const redirectTo = (type, id) => {
//     switch (type) {
//       case NOTIFICATION_TYPE.general:
//         return null;
//       case NOTIFICATION_TYPE.leave:
//         return `/calendar/leave/id/${id}`;
//       case NOTIFICATION_TYPE.coff:
//         return `/calendar/c-off/id/${id}`;
//       case NOTIFICATION_TYPE.task:
//         return `/tasks/detail/${id}`;
//       case NOTIFICATION_TYPE.reimbursement:
//         return `/reimbursement/get/${id}`;
//       case NOTIFICATION_TYPE.wfh:
//         return `/calendar/Pending/`; 
//         case NOTIFICATION_TYPE.announcements: // Add this case
//       return `/Announcements/id/${id}`;
        
//       default:
//         return null;
//     }
//   };
  

//   export const fetchNotificationAction = () => {
//     return async (dispatch, getState) => {
//       dispatch(callApiAction(
//         async () => await getNotifcationsApi(),
//         (response) => {
//           let newNotification = 0;
//           const lastReadId = getState().user.data.last_read_notification;
  
//           if (response && Array.isArray(response) && response.length > 0) {
//             for (let i = 0; i < response.length; i++) {
//               response[i].redirect = redirectTo(response[i].notificationType, response[i].contentId);
//               if (response[i]._id === lastReadId) {
//                 break;
//               } else {
//                 newNotification++;
//               }
//             }
//           }
//           dispatch({ type: actions.SET_NOTIFICATION_DATA, new: newNotification, data: response });
//         },
//             (err) => {

//             }
//         ))
//     }
// }
// export const readNotificationAction = () => {
//   return async (dispatch, getState) => {
//     const notifications = getState().notifications?.data;

//     if (notifications && notifications.length > 0) {
//       const firstNotificationId = notifications[0]._id;
//       dispatch({ type: actions.SET_USER_LAST_READ_NOTIFICATION, value: firstNotificationId });
//       dispatch(callApiAction(
//         async () => await readNotifcationsApi({ id: firstNotificationId }),
//                 (response) => {

//                 },
//                 (err) => {

//                 }
//             ))
//         }

//         dispatch({ type: actions.NOTIFICATION_READ })

//     }
// }

















// const getLastReadTimestamp = () => {
//   return localStorage.getItem('lastReadTimestamp') || new Date(0).toISOString();
// };

// const setLastReadTimestamp = (timestamp) => {
//   localStorage.setItem('lastReadTimestamp', timestamp);
// };


const redirectTo = (type, id) => {
  switch (type) {
    case NOTIFICATION_TYPE.general:
      return null;
    case NOTIFICATION_TYPE.leave:
      return `/calendar/leave/id/${id}`;
    case NOTIFICATION_TYPE.coff:
      return `/calendar/c-off/id/${id}`;
    case NOTIFICATION_TYPE.task:
      return `/tasks/detail/${id}`;
    case NOTIFICATION_TYPE.reimbursement:
      return `/reimbursement/get/${id}`;
    case NOTIFICATION_TYPE.wfh:
      return `/calendar/Pending/`;
    case NOTIFICATION_TYPE.announcements:
      return `/dashboard/`;
    default:
      return null;
  }
};

export const fetchNotificationAction = () => {
  return async (dispatch, getState) => {
    const userId = getState().user.id; // Assuming user id is stored in the state

    const lastReadTimestamp = await getLastReadTimestampApi();

    dispatch(callApiAction(
      async () => await getNotifcationsApi(),
      (response) => {
        let newNotification = 0;

        if (response && Array.isArray(response) && response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            response[i].redirect = redirectTo(response[i].notificationType, response[i].contentId);
            if (new Date(response[i].createdAt) > new Date(lastReadTimestamp)) {
              newNotification++;
            }
          }
        }
        dispatch({ type: actions.SET_NOTIFICATION_DATA, new: newNotification, data: response });
      },
      (err) => {
        console.error(err);
      }
    ));
  };
};

export const readNotificationAction = () => {
  return async (dispatch, getState) => {
    const notifications = getState().notifications?.data;

    if (notifications && notifications.length > 0) {
      const lastReadTimestamp = notifications[0].createdAt;
      await setLastReadTimestampApi(lastReadTimestamp);
      dispatch(callApiAction(
        async () => await readNotifcationsApi({ ids: notifications.map(notification => notification._id) }),
        (response) => {},
        (err) => {
          console.error(err);
        }
      ));
    }

    dispatch({ type: actions.NOTIFICATION_READ });
  };
};

export const readIndividualNotificationAction = (notificationId) => {
  return async (dispatch, getState) => {
    return dispatch(callApiAction(
      async () => await readNotifcationsApi({ id: notificationId }),
      (response) => {},
      (err) => {
        console.error(err);
      }
    ));
  };
};