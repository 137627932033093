import {
  Box,

  Checkbox,
  Collapse,
  Grid,
  Skeleton,

  Typography,
} from '@mui/material'
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from './../../components/button/SubmitButton'
import CustomInput from './../../components/inputs/CustomInput'
import CustomRadioButtons from './../../components/layouts/common/CustomRadioButtons'
import { HALF_TYPE, HALF_TYPE_OBJ, typeS } from './../../utils/constants'

import { useNavigate } from 'react-router-dom'
import { CenteredBox } from '../../components/layouts/common/boxes'


const WFHRaiseUi = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday } = useSelector((state) => state)


  const navigate = useNavigate()






  return (
    <Box p={4} mt={1} component="form" onSubmit={onSubmit}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Box >
          <Typography variant='h2'>
            WFH Application
          </Typography>
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h3' color="grey.main">
            Application Date
          </Typography >
          <Typography variant='h3' ml={3}>
            {moment().format("DD MMMM YYYY")}
          </Typography>
        </Box>


      </Box>
      <Box sx={{ display: "flex" }} mt={4}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography variant='h3' mt={3}>
            WFH Date:
          </Typography>


          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <DesktopDatePicker

                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  // shouldDisableDate={disableDates}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={moment(fields.date)}
                  onChange={(e) =>
                    setFields({ ...fields,
                       err: '', date: e.toISOString(),
                    start_time:e.set("hours",moment(fields.start_time).get("hours")).set("minutes",moment(fields.start_time).get("minutes")) ,
                    end_time:e.set("hours",moment(fields.end_time).get("hours")).set("minutes",moment(fields.end_time).get("minutes")) })
                  }
                  type="date"
                />

              </Box>
            </Box>
          </Box>
          <Typography variant='h3' mt={3}>
            Duration:
          </Typography>

          <Grid container columnSpacing={2} >
            <Grid item xs={5.75} >
              <TimePicker

                renderInput={(props) => {
                  return <CustomInput {...props} sx={{ height: '100%' }} />
                }}
                // shouldDisableDate={disableDates}

                disabled={
                  loading ||
                  !(holiday.data && Array.isArray(holiday.data)) ||
                  holiday.loading
                }
                value={moment(fields.start_time)}
                onChange={(e) =>
                  setFields({ ...fields, err: '', start_time: e.toISOString(), end_time: e.toISOString() })
                }
                type="Start Time"
              />
            </Grid>
            <Grid item xs={0.5} ><CenteredBox sx={{ height: "100%" }}>
              To
            </CenteredBox></Grid>
            <Grid item xs={5.75} >
              <TimePicker

                renderInput={(props) => {
                  return <CustomInput {...props} sx={{ height: '100%' }} />
                }}
                // shouldDisableDate={disableDates}

                disabled={
                  loading ||
                  !(holiday.data && Array.isArray(holiday.data)) ||
                  holiday.loading
                }
                value={moment(fields.end_time)}
                onChange={(e) =>
                  setFields({ ...fields, err: '', end_time: e.toISOString() })
                }
                type="End Time"
              />
            </Grid>
          </Grid>


          <Typography variant='h3' mt={3}>
            Reason:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.reason}
            onChange={(e) =>
              setFields({ ...fields, err: '', reason: e.target.value })
            }
            type="text"
            multiline
            placeholder="Write reason of leave..."
            rows={2}
          />


          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => { navigate("/calendar/") }}
                // color="primary"
                title=" Cancel"
              >

              </ResetButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton
                disabled={loading}
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title=" Confirm WFH"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>


        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} pl={3} pr={3}>


        </Box>
      </Box>
    </Box>
  )
}
export default WFHRaiseUi
