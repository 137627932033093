import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchEmployeeTypes = async (params) => {
  const callResponse = await axios({
    url: endpoints.employeeType,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const createEmployeeType = async (data) => {
  const callResponse = await axios({
    url: endpoints.employeeType,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const deleteEmployeeType = async (data) => {
  const callResponse = await axios({
    url: endpoints.employeeType,
    method: "delete",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const updateEmployeeType = async (data) => {
  const callResponse = await axios({
    url: endpoints.employeeType,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};
