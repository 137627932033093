import { useCallback, useState } from 'react'
import { Box, Typography } from '@mui/material'
import UserSearchBar from '../../components/inputs/SearchBar'
import { BasicProfileDetailsComponent } from '../profile/BasicProfileDetailsComponent'

import SalaryStructureController from './SalaryStructureController'

const EmployeeSalaryStrcutureController = () => {
  const [selectedUser, setSelectedUser] = useState(null)

  const onUserChange = useCallback((user) => {
    setSelectedUser(user)
  }, [])
  return (
    <>
      <Box mt={3} mb={3}>
        <UserSearchBar onUserChange={onUserChange} />
      </Box>
      {selectedUser && selectedUser != null && <BasicProfileDetailsComponent data={selectedUser} />}
      {selectedUser && (
        <SalaryStructureController
          key={selectedUser._id}

          userId={selectedUser._id}
        />
      )}
      {!selectedUser &&
        <SalaryStructureController
          key={''}

        />
      }
    </>
  )
}
export default EmployeeSalaryStrcutureController
