import { memo, useCallback, useEffect, useState } from "react"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"

import { CenteredBox } from "../../components/layouts/common/boxes"
import { Typography } from "@mui/material"
import UserSearchBar from "../../components/inputs/SearchBar"
import VisitMainContainer from "./VisitMainContainer"
import { selectEmployee } from "../../store/actions/selectedEmployeeAction"
import { BasicProfileDetailsComponent } from "../profile/BasicProfileDetailsComponent"





const EmployeeVisits = ({ }) => {
   
    const dispatch = useDispatch()
    const { selectedEmployee, user } = useSelector(state => state)
    const onUserChange = useCallback((user) => {
      dispatch(selectEmployee(user))
    }, [])
    return (
        <>
            <Box mt={3} mb={3}>
                <UserSearchBar defaultVal={selectedEmployee.user} onUserChange={onUserChange} />
            </Box>
            {selectedEmployee.user && selectedEmployee.user != null && <BasicProfileDetailsComponent data={selectedEmployee.user} />}
            {selectedEmployee.user && <VisitMainContainer />}

            {
                !selectedEmployee.user && <CenteredBox mt={4}>

                    <Typography variant="h4">No Employee Selected</Typography>
                </CenteredBox>
            }
        </>
    )
}
export default memo(EmployeeVisits)