import { fetchTeamListApi } from "../../apis/team.api"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"

export const fetchTeamDetailsAction = (filters, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.START_TEAM_LOADING })
        dispatch(
            callApiAction(
                async () => await fetchTeamListApi({...filters}),
                (response) => {
                    dispatch({ type: actions.SET_TEAM_DATA, value: {data: response, filters: filters} })
                    onSuccess(response)
                },
                (err) => {
                    onError(err)
                }
            )
        )
    }
}