import React from "react";
import { DialogActions, DialogContent, DialogTitle, Box, } from "@mui/material"
import CustomInput from "../../../components/inputs/CustomInput";
import SubmitButton from "../../../components/button/SubmitButton";
import { Form } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modalAction";
import { validatePassword } from "../../../utils/helper";
const UpdateUserPasswordUI = ({ id, loading, formValues, setFormValues, onsubmit }) => {
    const dispatch = useDispatch();

    return (
        <>

            <Box px={3} py={2}>

                <CustomInput
                    disabled={loading}
                    name="updated-password"
                    id="updated-password"
                    ht
                    // id="outlined-helperText"                            
                    value={formValues.password}
                    onChange={(e) =>
                        setFormValues({
                            ...formValues,
                            err: "",
                            password: e.target.value,
                        })
                    }
                    validate={() => {
                        if (!formValues.password || formValues.password == "") {
                            return "Password is required."
                        } else if (!validatePassword(formValues.password)) {
                            return "Password must be atlist 8 character long and must contain atlist one special character,one digit & One capital letter."
                        }
                        return true
                    }}
                    type="password"
                    label={"New Password*"}
                />
            </Box>

            <DialogActions   >
                <SubmitButton title={'Close'} onClick={() => { dispatch(closeModal()) }}></SubmitButton>
                <SubmitButton onClick={onsubmit} loading={loading} title={'Update'}></SubmitButton>
            </DialogActions>

        </>
    )
}

export default UpdateUserPasswordUI;