import { fetchBranches } from "../../apis/branch.api";
import { fetchLeaveTypes } from "../../apis/leaveType.api";
import { fetchDepartments } from "../../apis/department";
import { fetchdocuments } from "../../apis/document.api";
import { fetchEmployeeTypes } from "../../apis/employeeType.api";
import { fetchHolidays } from "../../apis/holiday.api";
import { fetchVersiones } from "../../apis/version.api";
import { actions } from "../../utils/constants";
import { callApiAction } from "./commonAction";
import { fetchevalutionApi } from "../../apis/EmployeeEvaluation.api";

export const fetchHolidayDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_HOLIDAY_LOADING });
    dispatch(
      callApiAction(
        async () =>
          await fetchHolidays({ ...filters, date: filters.year.valueOf() }),
        (response) => {
          dispatch({
            type: actions.SET_HOLIDAY_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setHolidayDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_HOLIDAY_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};

export const fetchDocumentDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_DOCUMENT_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchdocuments(filters),
        (response) => {
          dispatch({
            type: actions.SET_DOCUMENT_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setDocumentDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_DOCUMENT_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};

export const fetchBranchDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_BRANCH_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchBranches(filters),
        (response) => {
          dispatch({
            type: actions.SET_BRANCH_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setBranchDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_BRANCH_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};
export const fetchEvaluationDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_EVALUATION_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchevalutionApi(filters),
        (response) => {
          console.log("response",response);
          dispatch({
            type: actions.SET_EVALUATION_DATA,
            value: { data: response?.result, filters: filters },
            
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};
export const setEvaluationDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_EVALUATION_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};

export const fetchDepartmentDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_DEPARTMENT_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchDepartments(filters),
        (response) => {
          dispatch({
            type: actions.SET_DEPARTMENT_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setDepartmentDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_DEPARTMENT_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};

export const fetchEmployeeTypeDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_EMPLOYEE_TYPE_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchEmployeeTypes(filters),
        (response) => {
          dispatch({
            type: actions.SET_EMPLOYEE_TYPE_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setEmployeeTypeDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_EMPLOYEE_TYPE_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};

export const fetchVersionDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_VERSION_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchVersiones(filters),
        (response) => {
          dispatch({
            type: actions.SET_VERSION_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setVersionDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_VERSION_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};
export const fetchLeaveTypeDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_LEAD_TYPE_LOADING});
    dispatch(
      callApiAction(
        async () => await fetchLeaveTypes(filters),
        (response) => {
          dispatch({
            type: actions.SET_LEAD_TYPE_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setLeaveTypeDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_LEAD_TYPE_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};
