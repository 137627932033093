import { memo, useEffect, useState } from "react"
import BirthdayBox from "./BirthdayBox"
import { getBirthdayApi } from "../../apis/user.api"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import moment from "moment";
import { birthdayFetchDate } from "../../utils/helper";
import { callApiAction } from "../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

const BirthdayContainer = () => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const fetch = () => {

        const todaysDate = moment()
        const listStr = birthdayFetchDate.get()
        const savedData = listStr ? JSON.parse(listStr) : {}

        if (!listStr || savedData.date != todaysDate.get('date')) {
            dispatch(callApiAction(
                async () => await getBirthdayApi({}),
                (response) => {
                    setList(response)

                    birthdayFetchDate.set(JSON.stringify({ date: todaysDate.get('date'), data: response }))

                },
                (err) => {

                }
            ))
        } else {
            setList(savedData.data)
        }
    }
    useEffect(() => {
        fetch()
    }, [])
    if (list && list.length > 0)
        return <>
            <Box sx={{width:"100%"}}>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => { }}
                onSwiper={(swiper) => { }}
            >
                {
                    list.map((item) => <SwiperSlide key={item._id}>

                        <BirthdayBox data={item} />


                    </SwiperSlide>

                    )
                }

            </Swiper>
            </Box>
        </>

    return <></>
}
export default memo(BirthdayContainer)