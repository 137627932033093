import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Divider,
  Grid,
  Icon,
  Link,
  List,
  ListItemButton,
  ListSubheader,
  Paper,
  Skeleton,
  TablePagination,
  Typography,
  styled,
} from "@mui/material";
// import CakeIcon from '@mui/icons-material/Cake';
import React, { useState, useEffect } from "react"; // Import useState and useEffect
import { CenteredBox } from "../../components/layouts/common/boxes";
import moment from "moment";
import { center } from "../../assets/css/theme/common";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchNotificationAction,
  readNotificationAction,
} from "../../store/actions/notificationAction";
import { fetchBirthdayAction } from "../../store/actions/birthdayAction";
import DashBox from "./Dashbox";
import { openModal } from "../../store/actions/modalAction";
import TeamMemberProfileController from "./TeamMemberProfileController";
import { BikeScooter, Cake } from "@mui/icons-material";
import CakeIcon from "../../assets/icons/CakeIcon";

const TitlesBorder = styled(Box)(({ theme, color }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  padding: theme.spacing(2, 3),
  position: "relative",
  background: theme.palette.light.main,
  border: "2px dashed " + theme.palette.grey.main,
  borderRadius: theme.shape.borderRadius,
  alignItems: "center",
  justifyContent: "center",
}));

function DashboardBirthdays() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { birthdays } = useSelector((state) => state);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 8,
    search: "",
  });

  const handleClick = (User_id) => {
    console.log(User_id);
    dispatch(
      openModal({
        title: "User Details",
        size: "lg",
        component: <TeamMemberProfileController id={User_id} />,
      })
    );
  };
  useEffect(() => {
    dispatch(fetchBirthdayAction(filters), setLoading(false));
  }, [filters]);
  const birthdaysData = birthdays.data;

  return (
    <>
      <DashBox title={"Upcoming Birthdays"}>
        {loading && (
          <Grid container spacing={2} p={3}>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={75}
                sx={{ borderRadius: "15px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={75}
                sx={{ borderRadius: "15px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={75}
                sx={{ borderRadius: "15px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={75}
                sx={{ borderRadius: "15px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={75}
                sx={{ borderRadius: "15px" }}
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2}>
          {!loading &&
            birthdays &&
            birthdays.data &&
            Array.isArray(birthdaysData.result) &&
            birthdaysData.result.map((person) => (
              <Grid item xs={6} sm={3} key={person?._id}>
                <ButtonBase
                  sx={{
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                  onClick={() => handleClick(person._id)}
                >
                  <Badge
                    overlap="circular"
                    badgeContent={
                      <CenteredBox
                        sx={{
                          background: "white",
                          height: "30px",
                          width: "30px",
                          borderRadius: "100%",
                        }}
                      >
                        <Typography variant="h5">
                          <CakeIcon />
                        </Typography>
                      </CenteredBox>
                    }
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      src={person.profile_url}
                    ></Avatar>
                  </Badge>
                  <Typography mt={2} variant="body1" fontWeight={500}>
                    {person.first_name}
                  </Typography>
                  <Typography
                    mb={2}
                    variant="body2"
                    fontWeight={600}
                    color="grey.light"
                  >
                    {moment(person.dob).format("DD-MM-YYYY")}
                  </Typography>
                </ButtonBase>
              </Grid>
            ))}
        </Grid>
        {/* {!loading && birthdays &&
          birthdays.data &&
          Array.isArray(birthdaysData.result) &&
          birthdaysData.result.map((person) => (



            (


              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#F5F5F5",
                  borderRadius: "10px",
                  borderColor: "lightgrey",
                  margin: 3,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    onClick={() => handleClick(person._id)}
                    key={person._id}
                  >
                    <ListItemButton
                      disableTouchRipple
                      sx={{}}
                      key={person._id}
                    >
                      <Box
                        p={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >

                        

                      </Box>

                      <Box
                        sx={{
                          // bgcolor:"red",
                          width: "100%",

                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="text.200"
                          sx={{ flexGrow: 1, width: "100%" }}
                        >
                          {person.first_name.toUpperCase()}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.200"
                          sx={{ justifySelf: "flex-end" }}
                        >
                          {"" +
                            moment(person.dob).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </Link>
                </Box>
              </Box>



            )))} */}
      </DashBox>
    </>
  );
}

export default DashboardBirthdays;
