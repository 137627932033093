import { useEffect, useMemo, useState } from "react"
// import ReimberesementPageUI from "./ReimberesementPageUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { openModal } from "../../store/actions/modalAction"
import { getGeneralReimberesementApi, getReimberesementApi, getReimberesementCountApi } from "../../apis/reimbersement.api"
import moment from "moment"
import {  ButtonBase,  Tooltip, Typography } from "@mui/material"
import GeneralReimberesementPageUI from "./GeneralReimberesementPageUI"
import { Attachment } from "@mui/icons-material"
import { toTitleCase } from "../../utils/helper"
import ReimberesementController from "./ReimberesementController"


const EmployeeNameButton = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        // dispatch(openModal({
        //     title: toTitleCase(params.userId?.name) + "'s Reimburesement",
        //     component: <ReimberesementController userId={params.userId?._id} />,
        //     size:"xl"
        // }))
        dispatch(openModal({
            title: toTitleCase(params.userId?.name) + "'s Reimbursement",
            component: <ReimberesementController userId={params.userId?._id} selectedUser={params.userId} />,
            size: "xl"
        }));
        
    }
    return <Typography component={ButtonBase} textTransform="capitalize" onClick={onClick} >
        {params.userId?.name ?? "NA"}&nbsp;{params.totalAttachments > 0 &&
            <Tooltip title={"Total " + params.totalAttachments + " Attachments Attached"}><Attachment color="success" lineHeight="100%" fontSize="inherit" /></Tooltip>}
    </Typography>
}

const GenralReimberesementController = ({ }) => {

    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)


    const dispatch = useDispatch()

    const columns = useMemo(() => [
        { id: 1, fieldName: 'userId.name', label: 'Employee Name', align: "left", sort: true, minWidth: '150px', renderValue: (params) => <EmployeeNameButton params={params} /> },
        { id: 3, fieldName: 'total', label: 'Total Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.total },
        { id: 4, fieldName: 'approved', label: 'Approved Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.approved },
        { id: 5, fieldName: 'paid', label: 'Paid Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.paid },
        { id: 5, fieldName: 'rejected', label: 'Rejected Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.rejected },

    ], [dispatch]);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 25,
        search: '',
        sort: 'total',
        sortDirection: -1,
        date: moment(),

        status: null

    })

    const fetchList = () => {

        setLoading(true)

        const passFilters = { ...filters }
        passFilters['date'] = passFilters['date'].toISOString()

        dispatch(callApiAction(
            async () => await getGeneralReimberesementApi({ ...passFilters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    useEffect(() => {
        fetchList()

    }, [filters])

    return (
        <>
            <GeneralReimberesementPageUI
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}


            />

        </>
    )
}
export default GenralReimberesementController