



import { Box, Typography } from "@mui/material"
import DataTable from "../../components/tables/DataTable";
import CustomInput from "../../components/inputs/CustomInput";

const Form16ListUI = ({ date, filters, setFilters, callBack, loading, state, columns }) => {

    return <>

        <Box m={3}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Form-16</Typography>
                <Box>
                    <CustomInput
                        size="small"
                        value={filters.search}
                        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                        type="text"
                        placeholder="Search Employee Id & Name"
                    />
                </Box>
            </Box>
            <Box sx={{ minHeight: "300px" }} mt={3}>
                <DataTable key={date} columns={columns} rows={state.result} count={state.total} filters={filters} setFilters={setFilters} loading={loading} />
            </Box>
        </Box>
    </>
}
export default Form16ListUI