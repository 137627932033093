import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress, Autocomplete
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";

const UpdateDepartmentUI = ({ loading, parentName, UpdateDepartment, setParentName, setName, name, departments, setString, setParentID, err }) => {
    return (
        <>
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    position: "relative",
                }}
                maxHeight="100%"
            >

                {
                    loading && <CenteredBox><CircularProgress /></CenteredBox>
                }
                {
                    !loading && <>
                        <Typography color={"red"}>{err}</Typography>

                        <Box sx={{ marginTop: "10px", width: "100%" }}>
                            <Box>
                                <Typography fontWeight={500} variant="h4"> Name : </Typography>
                            </Box>
                            <Box sx={{ marginTop: "7px" }}>
                                <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", width: "100%" }}>
                                        <CustomInput
                                            disabled={loading}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            type="text"
                                            placeholder="Write Name..."
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: "10px", width: "100%" }}>
                            <Box>
                                <Typography fontWeight={500} variant="h4"> Parent Department : </Typography>
                            </Box>
                            <Box sx={{ marginTop: "7px" }}>
                                <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", width: "100%" }}>
                                        <Autocomplete
                                            disableClearable
                                            value={parentName}
                                            onChange={(e, newVal) => {
                                                setParentID(newVal._id)
                                                setParentName(newVal.label)
                                            }}
                                            options={Array.isArray(departments) ? (departments).map((key) => ({ label: key.name, _id: key._id })) : []}
                                            label={"Parent Department.*"}
                                            renderInput={(params) => <CustomInput {...params} placeholder="Parent Department" label="Parent Department"
                                                onChange={(e) => {
                                                    setString(e.target.value)
                                                }}
                                                sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                            />}
                                            sx={{ width: "100%" }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }

                <DialogActions>
                    <Box sx={{ float: "right", marginTop: "7px" }}>
                        <SubmitButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={UpdateDepartment}
                            title='Update Department'
                        >

                        </SubmitButton>
                    </Box>
                </DialogActions>
            </Box>
        </>
    );
};

export default UpdateDepartmentUI;