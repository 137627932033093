import {
  Box,
  Button,
  Checkbox,
  List,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
  ListItem,
} from "@mui/material";
import CustomRadioButtons from "../../components/layouts/common/CustomRadioButtons";
import CustomInput from "../../components/inputs/CustomInput";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";

const AddFeedbackTypeUI = ({
  loading,
  addFeedbackType,
  setName,
  name,
  identifier,
  setIdentifier,
  priority,
  setPriority,
  err,
}) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {!loading && (
          <>
            <Typography color={"red"}>{err}</Typography>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Name :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Write Name..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Identifier :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                      type="text"
                      placeholder="Write identifier..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginTop: "10px", width: "100%" }}>
              <Box>
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Priority :{" "}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <CustomInput
                      disabled={loading}
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      type="text"
                      placeholder="Write Priority..."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              onClick={addFeedbackType}
              title="Add LeaveType"
            ></SubmitButton>
          </Box>
        </DialogActions>
      </Box>
    </>
  );
};

export default AddFeedbackTypeUI;
