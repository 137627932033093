import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { IconButton, Typography } from "@mui/material";
import { Delete, DoneAll } from "@mui/icons-material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import moment from "moment";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import SalaryStructurePdfListUI from "./SalaryStructurePdfListUI";
import {
  DeleteSalaryStructurePdfDetailsApi,
  fetchSalaryStructurePdf,
} from "../../apis/SalaryStructurePdf.api";

const ActionButton = ({ params, setParams, date }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  // const onDownload = () => {
  //   return (
  //     <IconButton>
  //       <DoneAll color="success" />
  //     </IconButton>
  //   );
  //   // }
  // };

  const onDelete = () => {
    setLoading(true);
    const year = moment(date).get("year");
    dispatch(
      callApiAction(
        async () =>
          await DeleteSalaryStructurePdfDetailsApi({
            id: params._id,
          }),
        (response) => {
          setLoading(false);
          setParams({});
          dispatch(
            callSnackBar(
              "SalaryStructurePdf Deleted",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar(
              "Can not delete SalaryStructurePdf",
              SNACK_BAR_VARIETNS.error
            )
          );
        }
      )
    );
    dispatch(closeModal());
  };
  const onDltBtnCLick = () => {
    dispatch(
      openModal({
        title: "Alert!",
        component: (
          <Typography>
            {"Are You sure that you want to delete " +
              params.userId.employee_id +
              " Form-16 details?"}
          </Typography>
        ),
        onConfirm: onDelete,
        size: "sm",
        confirmText: "Delete",
        onCancle: () => dispatch(closeModal()),
      })
    );
  };
  return (
    <>
      <IconButton onClick={onDltBtnCLick}>
        <Delete color="error" />
      </IconButton>
    </>
  );
};

const SalaryStructurePdfListController = ({ date }) => {
  const user = useSelector((state) => state.user);
  console.log("USER", user);
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state);

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "userId",
        label: "Employee Id",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.employee_id,
      },
      {
        id: 1,
        fieldName: "employee_name",
        label: "Employee Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.name,
      },
      {
        id: 4,
        fieldName: "url",
        label: "Document",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => (
          <FileDownloadComponent
            src={params.url}
            downloadName={params.userId.name + ".pdf"}
          />
        ),
      },
      {
        id: 2,
        fieldName: "action",
        label: "action",
        align: "left",
        minWidth: "150px",
        renderValue: (params, setParams) => (
          <>
            <ActionButton
              key={date}
              date={date}
              params={params}
              setParams={setParams}
            />
          </>
        ),
      },
    ],
    [dispatch, date]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["userId.employee_id"],
    sort: "createdAt",
    sortDirection: -1,
  });

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchSalaryStructurePdf({ ...filters, date: date }),
        (response) => {
          setState(response);

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters, date]);

  return (
    <>
      <SalaryStructurePdfListUI
        date={date}
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        setState={setState}
        callBack={fetchList}
        loading={loading}
        state={state}
      />
    </>
  );
};
export default memo(SalaryStructurePdfListController);
