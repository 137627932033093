import { Box, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useSelector } from "react-redux";
import SubmitButton from "../../../components/button/SubmitButton";
import CustomInput from "../../../components/inputs/CustomInput";
import CustomRadioButtons from "../../../components/layouts/common/CustomRadioButtons";

import { LEAVE_TYPE } from "../../../utils/leave.constants";
import { UserSearchBarNormal } from "../../../components/inputs/SearchBar";
import LeaveBalance from "../LeaveBalance";
import moment from "moment";
import { useMemo } from "react";
import UserSearchId from "../UserSearchId";

const AddLeaveEncashmentUi = ({onSubmit, loading, fields, setFields }) => {

  const { holiday, user } = useSelector((state) => state);

  const previousYearDate = useMemo(
    () => moment(fields.date).add(-1, "month"),
    []
  );

  const onDateChange = (e) => {
    const changedVal = {};
    changedVal["date"] = e;
    setFields({ ...fields, err: "", ...changedVal });
  };

  return (
    <Box p={4} mt={1} component="form" onSubmit={onSubmit}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Typography variant="h2">Leave Encashment</Typography>
            <Typography variant="h6" color="error">
              {fields.err}
            </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }} mt={4}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography variant="h3" mt={3}>
            Select Employee:
          </Typography>

          <Box mt={2}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                height: "62px",
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <UserSearchBarNormal
                  placeholder="search"
                  inputProps={{ placeholder: "Search & Select Employee" }}
                  value={fields.userId}
                  onChange={(newVal) => {
                    setFields({ userId: newVal?._id });
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Typography variant="h3" mt={3}>
             Date:
          </Typography>

          <Box mt={2}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                height: "62px",
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <DesktopDatePicker
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: "100%" }} />;
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.date}
                  onChange={onDateChange}
                  type="date"
                />
              </Box>
            </Box>
          </Box>
          {fields.userId && (
            <Box mt={3}>
              <LeaveBalance userId={fields.userId} date={previousYearDate} />
            </Box>
          )}
          {
            <>
              <Typography variant="h3" mt={3}>
                 Type:
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} mt={3}>
                <CustomRadioButtons
                  options={[
                    {
                      value: LEAVE_TYPE.COFF,
                      label: "COFF",
                    },
                    {
                      value: LEAVE_TYPE.PL,
                      label: "PL",
                    },
                  ]}
                  value={fields.type}
                  onChange={(e) => {
                    setFields({ ...fields, type: e.target.value });
                  }}
                />
              </Box>
            </>
          }

          <Typography variant="h3" mt={3}>
            Workable Days:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.workable_days}
            onChange={(e) =>
              setFields({ ...fields, err: "", workable_days: e.target.value })
            }
            type="number"
            placeholder="Write Workable Days..."
          />

          {fields.userId && (
            <>
                <UserSearchId userId={fields.userId} fields={fields} setFields={setFields} salary={fields.salary} count={fields.count} workable_days={fields.workable_days}/>
            </>
          )}
        
          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex" }} mr={3}></Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton
                disabled={loading}
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title="Submit"
              ></SubmitButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AddLeaveEncashmentUi;
