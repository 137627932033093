
import { Box, ButtonBase, Tooltip, Typography, styled } from "@mui/material"
import { VISIT_COLORS, VISIT_STATUS, VISIT_TYPES } from "../../utils/visits.constants"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import VisitDetailsController from "./VisitDetailsController"
import { CALENDAR_ITEM_TYPES } from "../../utils/constants"
import { center } from "../../assets/css/theme/common"
import { CheckCircle, CheckCircleOutline, PendingActions, TaskAlt, Timelapse } from "@mui/icons-material"






const ViewBox = styled(ButtonBase)(({ theme, completed, visitType }) => ({

    background: "white",
    position: "relative",
    zIndex: 1,

    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%",
    marginBottom: "1px",
    borderTop: "0.5px solid " + (visitType == VISIT_TYPES.STORE ? VISIT_COLORS.store : VISIT_COLORS.customer),
    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        opacity: 0.1,
        height: '100%',
        width: "100%",
        background: VISIT_COLORS[visitType]??"#000",
        //completed ?  theme.palette.success.main : theme.palette.grey.main,
        zIndex: -1,

    },
    "*": {
        color:  VISIT_COLORS[visitType]??"#000",
    }
}))





const VisitCalendarInnerBox = ({ data, date, weekDay, weeklyOf, forEmployee, ...props }) => {
    const dispatch = useDispatch()
    const onClick = (id) => {
        dispatch(openModal({
            title: "Visit Details",
            component: <VisitDetailsController id={id} />
        }))
    }
    const holiday = data.find((item) => item.type == CALENDAR_ITEM_TYPES.holiday)
    const isCompanyHoliday = !!holiday
    const isWeeklyOf = weeklyOf.includes(weekDay)

    return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%" }} pt={5}>

        {
            data && Array.isArray(data) && data.length > 0 && data.filter(item => item.type != CALENDAR_ITEM_TYPES.holiday).map((item) => <ViewBox
                visitType={item.type}
                onClick={() => {
                    onClick(item._id)
                }} completed={item.status == VISIT_STATUS.CLOSE} >


                <Typography sx={{ fontSize: "12px" }} dangerouslySetInnerHTML={{
                    __html: item.title,
                }} >

                </Typography>
                {<Typography sx={{ fontSize: "12px", color: "black !important" }} >
                    {item.status == VISIT_STATUS.CLOSE ? <TaskAlt fontSize="inherit" /> : <PendingActions fontSize="inherit" />}
                </Typography>}


            </ViewBox>)
        }
        {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 1, background: "#D58C37", width: "100%", ...center }}>
            <Typography color="light.main" align="center" variant="caption"> {isCompanyHoliday ? holiday.title : 'WO'}</Typography>
        </Box>}
        {(isWeeklyOf || isCompanyHoliday) && <Box sx={{ zIndex: 0, top: "0px", width: "100%", position: "absolute", height: "100%", width: "100%", left: "0px", background: '#f1f1f1', ...center }}>

        </Box>}

    </Box>

}
export default VisitCalendarInnerBox