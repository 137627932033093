import moment from 'moment'
import { resetTokenApi } from '../../apis/auth.api'
import MessageDilog from '../../components/MessageDilog'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'

import { accessToken, accessTokenLastUpdatedAt, refreshToken } from '../../utils/helper'
import { openModal } from './modalAction'
import { callSnackBar } from './snackbarAction'
import { signOutAction } from './userReducerAction'

export const callApiAction = (
  asyncFun,
  onSuccess = () => { },
  onError = () => { },
  isFile = false,
  isSecondTime = false//it is for  identify recursion
) => {
  return async (dispatch, getState) => {
    const callResponse = await asyncFun()

    if (callResponse.status === 1 || isFile) {
      if (isFile) {
        onSuccess(callResponse)
      } else
        await onSuccess(callResponse.data)
    } else {

      if (callResponse.response && callResponse.response.data) {
        const response = callResponse.response.data

        if (response.code === 400) {
          {console.log("object error in commonAction", response.data[0].msg);}
          onError(Array.isArray(response.data) && response.data[0].msg)
        } else if (response.code === 401) {
          const resetFunResponse = await resetTokenApi()

          if (resetFunResponse.status === 1) {
           
            accessToken.set(resetFunResponse.data.access_token)
            refreshToken.set(resetFunResponse.data.refresh_token)

            dispatch(callApiAction(asyncFun, onSuccess, onError))
          } else if (resetFunResponse.code === 401) {

            //this is for the case: if there are two concurrent request and boith are getting 401 at same time 
            if (accessTokenLastUpdatedAt.get() >= moment().add(-1, 'minute').valueOf() && !isSecondTime) {
              
              dispatch(callApiAction(asyncFun, onSuccess, onError, true))

            } else {
              dispatch(callSnackBar("Your Session Expired.", SNACK_BAR_VARIETNS.error))
              onError('Session Expired.')
              dispatch(signOutAction())
            }

          }
        } else if (response.code === 403) {
          dispatch(callSnackBar("Your session has expired due to unautherized access", SNACK_BAR_VARIETNS.error))
          onError('Session Expired.')
          dispatch(signOutAction())
        } else {
          dispatch(callSnackBar(response.message || "OOPS! Something went wrong", SNACK_BAR_VARIETNS.error))
          onError(response.message || "OOPS! Something went wrong")
        }
      } else {

        if (callResponse.response && callResponse.response.status === 503) {
          dispatch(callSnackBar("Server is under maintance.", SNACK_BAR_VARIETNS.error))
          onError("Server is under maintance.")
        } else {
          dispatch(callSnackBar("Something went wrong", SNACK_BAR_VARIETNS.error))
          onError("Something went wrong")
        }
      }

    }
  }
}
