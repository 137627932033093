import { AdfScanner } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Container, Grid, IconButton, Menu, MenuItem, Paper, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { ExpandMore, ExpandLess, KeyboardDoubleArrowLeft, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { DatePicker } from "@mui/x-date-pickers";
import colorTheme from "../../../assets/css/theme/colorTheme";
import CustomDatePicker from "./CustomDatePicker";


const dailyUpdateCustomDayPicker = ({ date, setDate }) => {
    return (
      <Paper
        sx={{
          display: 'flex',
          padding: 2,
          flex: 1,
          backgroundColor: colorTheme.palette.grey.calender,
          height: '100%',
          boxShadow: 'none',
          border: '1px solid ' + colorTheme.palette.grey.calender,
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => setDate(moment(date).subtract(1, 'month'))}
            disabled={date.get('month') === 0}
          >
            <KeyboardDoubleArrowLeft
              color={date.get('month') === 0 ? 'grey' : 'primary'}
            />
          </IconButton>
          <IconButton
            onClick={() => setDate(moment(date).subtract(1, 'day'))}
          >
            <KeyboardArrowLeft color="primary" />
          </IconButton>
        </Box>
  
        <Typography variant="h3" sx={{ textAlign: 'center', flex: 2 }}>
          {date && date.format('DD MMMM, YYYY')}
        </Typography>
  
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => setDate(moment(date).add(1, 'day'))}
          >
            <KeyboardArrowRight color="primary" />
          </IconButton>
          <IconButton
            onClick={() => setDate(moment(date).add(1, 'month'))}
            disabled={date.get('month') === 11}
          >
            <KeyboardDoubleArrowRight
              color={date.get('month') === 11 ? 'grey' : 'primary'}
            />
          </IconButton>
  
          <CustomDatePicker date={date} setDate={setDate} />
      </Box>
    </Paper>
    );
  };
  export default dailyUpdateCustomDayPicker;