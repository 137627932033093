import { redirect } from "react-router-dom"
import { fetchLatestAnnouncementsApi, getAnnouncementsApi, markAnnouncementAsReadApi, readAnnouncementsApi } from "../../apis/announcement.api"
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction"

// export const fetchAnnouncementAction = () => {
//     return async (dispatch, getState) => {
//     dispatch(callApiAction(
//             async () => await getAnnouncementsApi(),
//             (response) => {
                
//                 const lastReadId = getState().user.data.last_read_announcement
//                 const updatedResponse = response.map((item) => (
//                     {
//                         ...item,
//                         announcement_state : false,
//                     }
//                 ))
//                 console.log("updated announcement", updatedResponse)
//                 // console.log(response);
//                 // console.log(lastReadId);
//                 console.log("announcement",response);

//                 if (response && Array.isArray(response) && response.length > 0) {
//                     for (let i = 0; i < response.length; i++) {

                       
                       
//                     }
//                 }
//                 dispatch({ type: actions.SET_ANNOUNCEMENT_DATA, data: updatedResponse })


//             },
//             (err) => {

//             }
//         ))
//     }
// }

export const fetchAnnouncementAction = () => {
  return async (dispatch) => {
    try {
      const response = await fetchLatestAnnouncementsApi();
      if (response.status === 1) {
        dispatch({ type: actions.SET_ANNOUNCEMENT_DATA, data: response.data });
      } else {
        console.error('Failed to fetch announcements', response);
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };
};

export const markAnnouncementAsReadAction = (announcementId) => {
  return async (dispatch, getState) => {
    try {
      const response = await markAnnouncementAsReadApi(announcementId);
      if (response.status === 1) {
        const { data } = getState().announcement;
        const updatedData = data.map(announcement => 
          announcement._id === announcementId ? { ...announcement, isRead: true } : announcement
        );
        dispatch({ type: actions.SET_ANNOUNCEMENT_DATA, data: updatedData });
      } else {
        console.error('Failed to mark announcement as read', response);
      }
    } catch (error) {
      console.error('Error marking announcement as read:', error);
    }
  };
};