import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useValidate from '../../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'

import moment from 'moment'

import { useNavigate, useParams } from 'react-router-dom'


import { createTaskApi, fetchTaskByIdApi, fetchTasksApi, updateTasksPriorityApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'

import { EmployeeTaskUserContext } from '../EmployeeMain'
import TaskDetailsUi from './TaskDetailsUi'

const TaskDetailsController = ({ id, selectedUser, callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()
  const { leaveBalance, holiday, user } = useSelector((state) => state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const params = useParams()
  const [list, setList] = useState({})
  const [tab, setTab] = useState(0)


  const fetchList = async (taskId) => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchTaskByIdApi({ id: taskId }),
        async (response) => {
          setLoading(false)
          setList(response)

        },
        (err) => {
          setLoading(false)
          setList({})
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }



  useEffect(() => {
    if (id)
      fetchList(id)
    else
      fetchList(params.id)
  }, [id, params.id])





  return (
    <TaskDetailsUi
      id={id}
      selectedUser={selectedUser ? selectedUser : user.data}
      loading={loading}

      list={list}
      fetchList={fetchList}
    />
  )
}
export default memo(TaskDetailsController)
