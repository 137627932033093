import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const fetchFeedbackType = async (params) => {
  const callResponse = await axios({
    url: endpoints.feedbacktypeBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

export const createFeedbackType = async (data) => {
  const callResponse = await axios({
    url: endpoints.feedbacktypeBase,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};

// export const deleteFeedbackType = async (data) => {
//   const callResponse = await axios({
//     url: endpoints.employeeType,
//     method: "delete",
//     headers: getHeaders(),
//     data,
//   })
//     .then((response) => response.data)
//     .catch((err) => ({status:0,response:err.response,code:err.response.status}));

//   return callResponse;
// };

export const updateFeedbackType = async (data) => {
  const callResponse = await axios({
    url: endpoints.feedbacktypeBase,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}));

  return callResponse;
};
