import {  Info } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import TaskDetailsController from "../TaskDetails.controller"


const ViewTaskButton = ({ params, setParams,selectedUser }) => {
    const dispatch = useDispatch()
    const onCLick = () => {
        dispatch(openModal({ title: "Task Details",
         component: <TaskDetailsController selectedUser={selectedUser} id={params._id}></TaskDetailsController>, 
         
         size: "md" }))
    }

    
    return <IconButton onClick={onCLick}>
        <Info color="info" />
    </IconButton>
}
export default ViewTaskButton