import { memo, useEffect, useState } from "react"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"
import VisitMainContainer from "./VisitMainContainer"
import { changeDate } from "../../store/actions/selectedEmployeeAction"





const MyVisit = ({ }) => {
    
   
    return (
        <>
            <VisitMainContainer />
        </>
    )
}
export default memo(MyVisit)