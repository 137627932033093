import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { closeModal } from "../../store/actions/modalAction"
import ReimberesementDetailsPageUi from "./ReimberesementDetailsPageUi"

const ReimburesmentDetailsDialog = (props) => {
    const dispatch = useDispatch()

    return (
        <>

            {<ReimberesementDetailsPageUi isFromModal={true} {...props} />}



        </>
    )
}
export default ReimburesmentDetailsDialog