
import { Avatar, Box, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, MenuItem, Paper, Select, Typography, useMediaQuery, useTheme } from "@mui/material"
import moment from "moment"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { REIMBURSEMENT_STATUS, REIMBURSEMENT_TYPE } from "../../utils/constants"
import { createBadge, toTitleCase } from "../../utils/helper"
import parse from 'html-react-parser';
import ImageComponent, { PdfComponent } from "../../components/ImageComponent"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import endpoints from "../../apis/endpoints"
// import ImageComponent from "../../components/inputs/ImageComponent"



const TravelTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Place Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.place
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Vehichle Type
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.vehichleType.toUpperCase()
                }
            </Typography>
        </Grid>

    </>
}
const FuelTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Place Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.place
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose Of Visit:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Vehichle Type
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.vehichleType.toUpperCase()
                }
            </Typography>
        </Grid>

        <Grid xs={6} item>
            <Typography variant="h4">
                Total KMS:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.noOfKMS
                }
            </Typography>
        </Grid>
    </>
}
const OtherTypeDetails = ({ state }) => {
    return <>

        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>
    </>
}
const FoodTypeDetails = ({ state }) => {
    return <>
        <Grid xs={6} item>
            <Typography variant="h4">
                Purpose of Food :
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.purpose
                }
            </Typography>
        </Grid>
        <Grid xs={6} item>
            <Typography variant="h4">
                Type Of Food:
            </Typography>
            <Typography variant="h6" color="grey.main">
                {
                    state.foodType
                }
            </Typography>
        </Grid>


    </>
}



const ReimberesementDetailsPageUi = ({ state, setState, fieldLoading, onUpdateField, loading, isFromModal }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return <>
        {!loading && state &&
            <Box mb={3}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", padding: desktop ? 1 : 0 }}  >
                    {!isFromModal && <Typography variant="h3">Reimburesement Details</Typography>}
                    <Box px={3}  >
                        <Grid container spacing={2} rowSpacing={3}>

                            <Grid xs={12} item >
                                <Box component={Paper} sx={{ width: "100%", border: "1px dashed " + theme.palette.primary.main }} p={2} >
                                    <CenteredBox sx={{ flexDirection: "column" }}>
                                        <Typography variant="h1" color="dark.main">
                                            ₹ {state.amount}
                                        </Typography>
                                        <Typography variant="h4">
                                            Amount
                                        </Typography>
                                    </CenteredBox>
                                </Box>


                            </Grid>
                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Applied By :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        state.userId && (state.userId.first_name + " " + state.userId.last_name)
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Date :
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {
                                        moment(state.expenseDate).format("DD-MM-YYYY")
                                    }
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Type
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {state.type.toUpperCase()}
                                </Typography>
                            </Grid>


                            <Grid xs={6} item>
                                <Typography variant="h4">
                                    Action
                                </Typography>
                                <Typography variant="h6" color="grey.main">
                                    {!(user && state.userId && state.userId._id != user.data._id) && createBadge(state.status)}


                                    {(user && state.userId && state.userId._id != user.data._id) &&



                                        <>
                                            {state.status == REIMBURSEMENT_STATUS.pending && <Box sx={{ display: "flex" }}>
                                                <Button onClick={(e) => { onUpdateField('status', REIMBURSEMENT_STATUS.approved) }} color="success" variant="contained" disableElevation>Approve</Button>
                                                <Button onClick={(e) => { onUpdateField('status', REIMBURSEMENT_STATUS.rejected) }} sx={{ ml: 2 }} color="error" variant="contained" disableElevation>Reject</Button>
                                            </Box>}
                                            {state.status == REIMBURSEMENT_STATUS.approved && <Chip color="success" size="small" label={state.status ? state.status.toUpperCase() : ''} />}
                                            {state.status == REIMBURSEMENT_STATUS.paid && <Chip color="success" size="small" label={state.status ? state.status.toUpperCase() : ''} />}
                                            {state.status == REIMBURSEMENT_STATUS.rejected && <Chip color="error" size="small" label={state.status ? state.status.toUpperCase() : ''} />}
                                        </>


                                    }
                                </Typography>
                            </Grid>


                            {state.actionedBy && <Grid xs={6} item>
                                <Typography variant="h4">
                                    {toTitleCase(state.status)}  By
                                </Typography>
                                <Typography variant="h6" color="grey.main">

                                    {(state.actionedBy.first_name + " " + state.actionedBy.last_name)}
                                </Typography>
                            </Grid>}






                            {
                                state.type === REIMBURSEMENT_TYPE.food && <FoodTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.fuel && <FuelTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.travel && <TravelTypeDetails state={state} />
                            }
                            {
                                state.type === REIMBURSEMENT_TYPE.other && <OtherTypeDetails state={state} />
                            }





                            {state.advance_payment_id && state.advance_payment_id != '' &&
                                <Grid xs={12} item>
                                    <Typography variant="h4">
                                        Advance Paid For:
                                    </Typography>
                                    <Typography variant="h6" color="grey.main">
                                        {state.advance_payment_id?.purpose}
                                    </Typography>
                                </Grid>}

                        </Grid>

                        {state.bills && Array.isArray(state.bills) && state.bills.length > 0 && <>


                            <Typography variant="h4" mt={2}>Bills:</Typography>
                            <Grid container spacing={2} rowSpacing={3}>
                                {state.bills.map((bill, index) =>
                                    <Grid md={3} xs={6} item key={index}>
                                        {/* <PdfComponent sx={{ width: "100%" }} src={parse(bill)} alt="" /> */}
                                        {
                                            bill.search(".pdf") == -1 ? <ImageComponent src={parse(bill)} /> : <FileDownloadComponent src={parse(bill)} />
                                        }


                                    </Grid>
                                )
                                }
                            </Grid>

                        </>}

                    </Box>
                </Box>
            </Box>
        }
        {
            !state && !loading && <CenteredBox><Typography variant="h3">Loading Data...</Typography></CenteredBox>
        }
        {
            loading && <CenteredBox sx={{ minHeight: "300px" }}><CircularProgress /></CenteredBox>
        }

    </>
}
export default ReimberesementDetailsPageUi