import { Add, Download } from "@mui/icons-material"
import { Button, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import SubmitButton, { ActionButton } from "../../components/button/SubmitButton"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"

import ReimburesmentCounts from "./ReimburesmentCounts"
import DataTable from "../../components/tables/DataTable"
import DownloadSalaaryStructureSlipCsv from "../reimberesement/actionButtons/DownloadSalaaryStructureSlipCsv"
import { openModal } from "../../store/actions/modalAction"
import SalaryListCsv from "../reimberesement/SalaryListCsv"

const GeneralReimberesementPageUI = ({  filters, setFilters, columns, list, loading }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const openReimberesementModal = () => {
    dispatch(openModal({
      title : 'Download Csv File',
      size : 'sm',
      component : <SalaryListCsv loading={loading} filters={filters} setFilters={setFilters} />,
    }))
  }
  return (
    <>
      <Box mt={3} mb={3}>
        <CustomMonthPicker date={filters.date} setDate={(date) => { setFilters({ ...filters, date }) }} />
        <Box mt={4} mb={2} sx={{float: "right", marginTop: "5px" }}>
        <SubmitButton
        type="button"
        title={" Download CSV"}  
        onClick={openReimberesementModal}    
        />

        </Box>
        <Box sx={{ minHeight: "300px" }} mt={3}>
          <DataTable columns={columns} rows={list?.result ?? []} count={list?.total} filters={filters} setFilters={setFilters} loading={loading} />
        </Box>
      </Box>
    </>
  )
}


export default GeneralReimberesementPageUI
