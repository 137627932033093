import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { IconButton, Typography } from "@mui/material";
import {
  Delete,
  Info,
  DownloadForOffline,
  Check,
  DoneAll,
} from "@mui/icons-material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import moment from "moment";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { APPRAISAL_STATUS, SNACK_BAR_VARIETNS } from "../../utils/constants";
import Form16ListUI from "./Form16ListUI";
import ApprasialLetterUi from "./Form16Ui";

import { DeleteForm16DetailsApi, fetchForm16, fetchForm16DetailsApi } from "../../apis/form16.api";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";
import GenerateForm16Ui from "./GenerateForm16Ui";
import FileDownloadComponent from "../../components/FileDownloadComponent";
// import DownloadSalarySlipListPdf from "../payrollSummary/DownloadSalarySlipListPdf"

const ActionButton = ({ params, setParams, date }) => {
  console.log("KIKIKI",params);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  // const onClick = () => {
  //   // dispatch(

  //   dispatch(
  //     openModal({
  //       title: "Sheet Preview",
  //       component: (
  //         <PreviewIndividualTable
  //           // fields={fields}
  //           //  data={data} date={date.valueOf()}
  //           // date={moment(date)} emp_id={params.employee_id}
  //           params={params}
  //         ></PreviewIndividualTable>
  //       ),
  //     })
  //   );
  //   // )
  // };

  const onDownload = () => {
    // if(APPRAISAL_STATUS.PENDING){
    // APPRAISAL_STATUS.
    return (
      <IconButton>
        <DoneAll color="success" />
      </IconButton>
    );
    // }
  };

  const onDelete = () => {
    setLoading(true);
    const year = moment(date).get("year");
    dispatch(
      callApiAction(
        async () =>
          await DeleteForm16DetailsApi({
            id: params._id,
            
          }),
        (response) => {
          setLoading(false);
          setParams({});
          dispatch(
            callSnackBar(
              "Appraisal Details Deleted",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar("Can not delete Branch", SNACK_BAR_VARIETNS.error)
          );
        }
      )
    );
    dispatch(closeModal());
  };
  const onDltBtnCLick = () => {
    dispatch(
      openModal({
        title: "Alert!",
        component: (
          <Typography>
            {"Are sure to want to delete " +
              params.userId.employee_id +
              " Form-16 details?"}
          </Typography>
        ),
        onConfirm: onDelete,
        size: "sm",
        confirmText: "Delete",
        onCancle: () => dispatch(closeModal()),
      })
    );
  };
  return (
    <>
      {/* <IconButton onClick={onClick}>
        <Info color="info" />
      </IconButton> */}
      <IconButton onClick={onDltBtnCLick}>
        <Delete color="error" />
      </IconButton>
      {/* <IconButton onClick={onDownload}>
        <Check color="" />
      </IconButton> */}
    </>
  );
};

const Form16ListController = ({ date }) => {
  const user = useSelector((state) => state.user);
  console.log("USER",user)
  const dispatch = useDispatch();
  const {settings} = useSelector((state) => state)

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  // const fetchAppresial = fetchAllUserAppresialApi;

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "employee_id",
        label: "Employee Id",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.employee_id
      },
      {
        id: 1,
        fieldName: "name",
        label: "Employee Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.name
      },
    //   {
    //     id: 3,
    //     fieldName: "file_url",
    //     label: "Brochure",
    //     align: "left",
    //     sort: true,
    //     renderValue: (params, setParams) => (
    //         params.file_url.map((param) => <FileDownloadComponent key={param._id} src={param.url} />)
    //     ),
    // },
      { id: 4, fieldName: 'url', label: 'Document', align: "left", sort: true, minWidth: '150px', renderValue: (params) => <FileDownloadComponent src={params.url} date={date} /> },
      {
        id: 2,
        fieldName: "action",
        label: "action",
        align: "left",
        minWidth: "150px",
        renderValue: (params, setParams) => (
          <>
            {/* <DownloadSalarySlipListPdf date={moment(date)} emp_id={params.employee_id} /> */}
            {/* <DownloadApprasialLetterPdf date={moment(date)} emp_id={params.employee_id} /> */}
            {/* <DownloadForm16PdfButton
              date={moment(date)}
              emp_id={params.userId.employee_id}
              url = {params.url}
            /> */}
            <ActionButton
              key={date}
              date={date}
              params={params}
              setParams={setParams}
            />
          </>
        ),
      },
    ],
    [dispatch, date]
  );

  // const [filters, setFilters] = useState({
  //   pageSize: 10,
  //   pageNo: 1,
  //   sort: "userId",
  //   searchable: ["userId"],
  //   sortDirection: -1,
  //   status: null,
  // });
  
const [filters, setFilters] = useState({
  pageNo: 1,
  pageSize: 10,
  search: "",
  role: null,
  sort: "name",
  sortDirection: 1,
  searchable: ["userId.first_name","userId.last_name"],
  enabled:null,
});
  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchForm16({ ...filters, date: date }),
        (response) => {
          setState(response);

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters,date]);

//   const getDocumnetList = () => {
//     if (!settings.document_data || settings.document_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(settings.document_filters)) {
//         dispatch(fetchDocumentDataAction(filters));
//     }
// }
// useEffect(() => {
//     fetchList()
//     getDocumnetList()
// }, [filters])

 


  return (
    <>
    <Form16ListUI
      date={date}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      setState={setState}
      callBack={fetchList}
      loading={loading}
      state={state}
      />
      </>
  );
};
export default memo(Form16ListController);