import React, { useEffect, useState } from "react";
// import { createHoliday } from "../../apis/holiday.api";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import AddLeaveTypeUI from "./AddLeaveTypeUi";
import { fetchLeaveTypes, createLeaveType } from "../../apis/leaveType.api";
import { closeModal } from "../../store/actions/modalAction";
import { fetchLeaveTypeDataAction } from "../../store/actions/settingsAction";

const AddLeaveTypeController = ({ callBack }) => {
  const dispatch = useDispatch();
  const createLeaveTypeApi = createLeaveType;
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [short_name, setShort_name] = useState("");
  const [system_default, setSystem_default] = useState("");
  const [name, setName] = useState("");
  const [err, setErr] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [priority, setPriority] = useState("");

  const fetchLeaveTypesApi = fetchLeaveTypes;

  const addLeaveType = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {name,color,system_default,short_name,identifier,priority};

    dispatch(
      callApiAction(
        async () => await createLeaveTypeApi(data),
        async (response) => {
          await callBack(response);
          dispatch(closeModal());
          setLoading(false);
          fetchLeaveTypes();

        },
        (err) => {
          setLoading(false);
          setErr(err);
        }
      )
    );
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, []);

  return (
    <AddLeaveTypeUI
      loading={loading}
      addLeaveType={addLeaveType}
      name={name}
      setName={setName}
      color={color}
      setColor={setColor}
      short_name={short_name}
      setShort_name={setShort_name}
      system_default={system_default}
      setSystem_default={setSystem_default}
      identifier={identifier}
      setIdentifier={setIdentifier}
      priority={priority}
      setPriority={setPriority}
      err={err}
      setErr={setErr}
    />
  );
};

export default AddLeaveTypeController;
