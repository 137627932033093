import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  ButtonBase,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { toTitleCase } from "../../utils/helper";
import SubmitButton from "../../components/button/SubmitButton";
import { UploadSheetApi } from "../../apis/appresial.api";
import { closeModal } from "../../store/actions/modalAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const PreviewTableController = ({ params }) => {
  const [loading, setLoading] = useState();
  const key = Object.keys(params);
  const value = Object.values(params);
  console.log("keys", key);
  console.log("popo", params);
  const data = [params];
  console.log("Data", data);
  return (
    <>
      {/* <TableContainer
    component={Box}
    mt={3}
    sx={{
      boxShadow:
        "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
      borderRadius: "4px",
      border: "1px solid #BFBFBF",
    }}
  >
    <Table aria-label="collapsible table" sx={{}}>
      <TableHead sx={{ background: "#F2F2F2" }}>
        <TableRow>
          {columns.map((name) => (
            <TableCell key={name}>{toTitleCase(name)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading &&
          data &&
          data.map((item, index) =>
            item ? (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  background: item.hasError ? "pink" : "inherit",
                }}
              >
                {columns.map((name) => (
                  <TableCell
                    sx={{
                      background:
                        item.data[name] && item.data[name].err != ""
                          ? "red"
                          : "unset",
                    }}
                    key={name}
                  >
                    {item.data[name] && item.data[name].value}
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <></>
            )
          )}
      </TableBody>
    </Table>
  </TableContainer>; */}
      {/* <Box>
    <Typography>Basic Salary</Typography>
    {console.log("popop",params)}
   
    {console.log("keys",Object.keys(params))}
    {console.log("Values",Object.values(params))} */}
      {/* Object.keys(params) */}
      {/* <Typography variant='h4'>{params.basic_salary}</Typography>,
        <Typography variant='h4'>{params.gross_salary}</Typography>,
         <Typography variant='h4'>{params.net_pay}</Typography>
  
  </Box> */}
      {/* <TableContainer> */}
      <Table sx={{}}>
        <TableHead sx={{ background: "#F2F2F2" }}>
          <TableRow>
            <TableCell>Employee Id</TableCell>
            <TableCell>Basic Salary</TableCell>
            <TableCell>House Rent Allowance</TableCell>
            <TableCell>Conveyence Allowance</TableCell>
            <TableCell>Food Allowance</TableCell>
            <TableCell>Other Allowance</TableCell>
            <TableCell>Medical Allowance</TableCell>
            <TableCell>Professional Tax</TableCell>
            <TableCell>Provident Fund</TableCell>
            <TableCell>ESIC</TableCell>
            <TableCell>Gross Salary</TableCell>
            <TableCell>Net Pay</TableCell>
            <TableCell>ESOPS</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {/* {value.map((ele) => console.log("Value", ele))}
          {key.map((ele) => console.log("keys", ele))} */}
          {data.map((item) => (
            <TableRow key={item.employee_id}>
              <TableCell>{item.employee_id}</TableCell>
              {/* <TableCell>{console.log("itit",item)} </TableCell> */}
              <TableCell>{item.basic_salary}</TableCell>
              <TableCell>{item.house_rent_allowance}</TableCell>
              <TableCell>{item.conveyence_allowance}</TableCell>
              <TableCell>{item.food_allowance}</TableCell>
              <TableCell>{item.other_allowance}</TableCell>
              <TableCell>{item.medical_allowance}</TableCell>
              <TableCell>{item.proffesional_tax}</TableCell>
              <TableCell>{item.pf}</TableCell>
              <TableCell>{item.esic}</TableCell>
              <TableCell>{item.gross_salary}</TableCell>
              <TableCell>{item.net_pay}</TableCell>
              <TableCell>{item.esops}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </>
  );
};

export default PreviewTableController;
