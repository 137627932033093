import { memo, useEffect, useMemo, useState } from 'react'
import useValidate from '../../store/hooks/useValidator'

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction";
import moment from 'moment'
import AnnouncementsFormUI from './AnnouncementsFormUI'
import { useNavigate } from 'react-router-dom'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'
import { createAnnouncement } from '../../apis/announcement.api'

const AnnouncementsFormController = ({ callBack = () => { } }) => {
  const validate = useValidate()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [fields, setFields] = useState({
    err: '',
    announcement_title: "",
    annoncement_description: "",

  })
  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.announcement_title,
        field: 'Announcement Title ',
      },
      
      {
        required: true,
        value: fields.announcement_description,
        field: 'Announcement Description',
      },
      
    ],
    [fields],
  )

  const onSubmit = async (e) => {
    e.preventDefault()

    const validationResponse = validate(validationSchema)

    if (validationResponse === true) {
      const taskData = { ...fields }
      console.log('Announcement Title:', fields.announcement_title);
      console.log('Announcement Description:', fields.announcement_description);
      // console.log('Announcement Title:', fields.date_announce);
      

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await createAnnouncement(fields),
          async (response) => {

            setLoading(false)
            navigate("/announcement/")
            dispatch(callSnackBar("Announcement Created Successfully", SNACK_BAR_VARIETNS.suceess))
          },
          (err) => {
            setLoading(false)
            setFields({ ...fields, err })
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
          },
        ),
      )
    } else {
      setFields({ ...fields, err: validationResponse })
    }
  }
  return (
    <AnnouncementsFormUI
      // disableDates={disableDates}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  )
}


export default memo(AnnouncementsFormController)