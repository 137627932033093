import { Badge, Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import vector from '../../assets/images/BackGround.png'
import ImageComponent from '../../components/inputs/ImageComponent'
import { toTitleCase } from '../../utils/helper'
import { ArrowDownward, BadgeOutlined, Cake, Info, MailOutline, PhoneInTalk } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import TeamMemberProfileController from './TeamMemberProfileController'
import { memo } from 'react'

function MemberCard({
    id,
    employee_id,
    firstName,
    lastName,
    designation,
    dob,
    emergency_contact,
    email,
    profileUrl
}) {
    const dispatch = useDispatch()
    const openMemberProfileModal = () => {
        dispatch(openModal({
            title : 'User Details',
            size:'lg',
            component : <TeamMemberProfileController id={id}/>,
        }))
    }
    const paperStyle = {
        width:'100%',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        overflow:'hidden',
    }
    const imageContainerStyle = {
        position:"absolute",
        bottom:"0px",
        left:"50%",
        transform:"translate(-50%,50%)",
        borderRadius:"100%",
        background:"grey",
        height:"100px",
        width:"100px",
        zIndex:1,
    }
  return (
   <>
    <Paper sx={paperStyle} >
            
        <Box sx={{position:"relative",width:"100%",height:"100px",background:'black'}} >
            
            <Box sx={{position:"absolute",width:"100%",height:"100px"   }}>
                {/* <img src={vector} height='100%' width='100%' style={{objectFit:"cover"}} alt='image'/> */}
            </Box>
            <Box sx={imageContainerStyle}>
                <ImageComponent avtarProps={{ sx: { height: "100px", width: "100px", bgcolor: "grey.main",objectFit:"contain !important"} }} src={profileUrl} isAvatar avtarTitle={firstName && firstName[0]&& firstName[0].toUpperCase()}  />
            </Box>
        </Box>
        <Box p={2} sx={{display:"flex",flex:1,justifyContent:"space-between",pt:"55px"}}  >
            <Box sx={{width:"100%"}}>
                <Typography align='center' variant='h6' >{firstName} {lastName}</Typography>
                <Typography align='center' variant='h6' >{designation}</Typography>
            </Box>
        </Box>
        <Box  p={2} sx={{width:"100%"}} display="flex">
            <Box display="flex" alignItems="center" flexDirection="column" flex="1" >
            <Typography variant='h6'> 
                <BadgeOutlined/> 
            </Typography>
            <Typography variant='h6'>
                {employee_id}
            </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" flex="1" borderLeft="1px solid black" >
            <Typography variant='h6'>
            <Cake/>
            </Typography>
            <Typography variant='h6'>
            {moment(dob).format("DD/MM/YYYY")}
            </Typography>
            </Box>
        </Box>

        <Box p={2} sx={{width : "100%"}}>

            <Box sx={{width:'100%',display:"flex",flexDirection:"row",justifyContent:'center'}}>
                <Tooltip title="Email" >
                    <IconButton>
                        <a href={`mailto:${email}`} target='_blank' style={{ color: '#000000', textDecoration: 'none' }}>
                            <MailOutline />
                        </a>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View Details">
                    <IconButton onClick={() => openMemberProfileModal()}>
                        <a href={`#`} style={{ color: '#000000', textDecoration: 'none' }}>
                            <Info color='#000000' />
                        </a>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>

    </Paper>
   </>
  )
}

export default memo(MemberCard);