import { Button, CircularProgress, FormControlLabel, IconButton, Switch, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { getReimberesementPdfApi } from "../../../apis/reimbersement.api";
import SubmitButton, { ActionButton } from "../../../components/button/SubmitButton";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { Download } from "@mui/icons-material";
import fileDownload from "js-file-download";
import moment from "moment";

const DownloadPdfButton = ({ selectedUser,date, ...props }) => {

    const { user } = useSelector(state => state)

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()


    const onClick = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await getReimberesementPdfApi({ userId: selectedUser._id, status: "approved" ,date}),
                (response) => {
                    setLoading(false);
                    fileDownload(response, (selectedUser && selectedUser.name ?selectedUser.name:user.data.first_name) + ".pdf")
                    
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Cant download Pdf", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        );
    }


    return <ActionButton active title={"Download"} variant="contained" icon={<Download />} loading={loading} onClick={onClick} ></ActionButton>
}
export default DownloadPdfButton