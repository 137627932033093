import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



// export const getNotifcationsApi = async params => {
//     const callResponse = await axios({
//         url: endpoints.notificationFetch,
//         method: "get",
//         headers: getHeaders(),
//         params,
//     })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));

//     return callResponse;
// };

// export const readNotifcationsApi = async data => {
//     const callResponse = await axios({
//         url: endpoints.notificationBase,
//         method: "PATCH",
//         headers: getHeaders(),
//         data,
//     })
//         .then(response => response.data)
//         .catch(err => ({status:0,response:err.response,code:err.response.status}));

//     return callResponse;
// };


// export const getLastReadTimeStampApi = async userId => {
//     const callResponse = await axios({
//       url: `${endpoints.notificationLastReadTimeStamp}/${userId}`,
//       method: "get",
//       headers: getHeaders(),
//     }).then(response => response.data)
//       .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
  
//     return callResponse;
//   };
  
//   export const setLastReadTimeStampApi = async (userId, lastReadTimestamp) => {
//     const callResponse = await axios({
//       url: endpoints.notificationSetTimeStamp,
//       method: "post",
//       headers: getHeaders(),
//       data: { userId, lastReadTimestamp },
//     }).then(response => response.data)
//       .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
  
//     return callResponse;
//   };


export const getNotifcationsApi = async () => {
    try {
      const response = await axios.get(endpoints.notificationFetch, { headers: getHeaders() });
      return response.data;
    } catch (err) {
      return { status: 0, response: err.response, code: err.response.status };
    }
  };
  
  export const readNotifcationsApi = async data => {
    try {
      const response = await axios.patch(endpoints.notificationBase, data, { headers: getHeaders() });
      return response.data;
    } catch (err) {
      return { status: 0, response: err.response, code: err.response.status };
    }
  };
  
  export const getLastReadTimestampApi = async () => {
    try {
      const response = await axios.get(endpoints.notificationLastReadTimeStamp, { headers: getHeaders() });
      return response.data.data.lastReadTimestamp;
    } catch (err) {
      return { status: 0, response: err.response, code: err.response.status };
    }
  };
  
  export const setLastReadTimestampApi = async (lastReadTimestamp) => {
    try {
      const response = await axios.post(endpoints.notificationSetTimeStamp, { lastReadTimestamp }, { headers: getHeaders() });
      return response.data;
    } catch (err) {
      return { status: 0, response: err.response, code: err.response.status };
    }
  };